import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { DocumentModel } from '@adj/generators/models/document.model';
import { GetDocumentByKeyRequestModel } from '../models/get-document-by-key-request.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';

@Injectable({
	providedIn: 'root',
})
export class DocumentService extends EndPointService {
	constructor(http: HttpClient, config: EnvConfigService) {
		super(http, config.get('document'));
	}

	public getDocumentUrl(
		model: GetDocumentByKeyRequestModel
	): Observable<CommandResult<DocumentModel>> {
		return this.get('GetTrainingMaterial', model);
	}
}
