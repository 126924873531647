<div>
	<div>
		<a (click)="generatePdf()" *ngIf="showLink">
			<ng-content select="[pdf-gen-control]"></ng-content>
		</a>
	</div>
	<div class="pdf-content">
		<div #pdfComponent>
			<ng-content select="[pdf-content]"></ng-content>
		</div>
	</div>
</div>
