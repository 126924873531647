import { pdfFonts } from '@adj/shared/pdf/pdf-fonts';
import {
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	Output,
	SecurityContext,
	ViewChild,
} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DrawOptions, drawDOM, Group, pdf } from '@progress/kendo-drawing';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-pdf-generator',
	templateUrl: './pdf-generator.component.html',
	styleUrls: ['./pdf-generator.component.scss'],
})
export class PdfGeneratorComponent implements OnInit {
	@Input() showLink: boolean = false;
	@Input() showContent: boolean = false;
	@Input() pageBreakClassSelectors: string = '';
	@Input() keepTogetherClassSelectors: string = '';

	@Input() set genAndOpenInNewTab(value: boolean){
		if (value === true){
			this.generatePdf();
		}
	}

	@Output() afterRender: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('pdfComponent') pdfComponent: ElementRef;

	pdfOptions = {
		avoidLinks: true,
		forcePageBreak: null,
		keepTogether: null,
		margin: '0.5in',
		paperSize: 'Legal',
		scale: 0.8,
		// template: (data) =>
		// 	`<div style="display: flex; justify-content: flex-end;"><span>Page ${data.pageNum} of ${data.totalPages}</span></div>`,
	} as DrawOptions;

	constructor(
		private router: Router,
		private sanitizer: DomSanitizer,
		@Inject('window') private $window: Window,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {}

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
		  currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	openInNewTab(namedRoute) {
		let newRelativeUrl = this.router.parseUrl(namedRoute);
		let baseUrl = window.location.href.replace(this.router.url, '');
		return this.$window.open(baseUrl + newRelativeUrl);
	}

	public generatePdf() {
		var element = this.pdfComponent.nativeElement;

		var pdfOptions = {
			...this.pdfOptions,
			forcePageBreak: this.pageBreakClassSelectors,
			keepTogether: this.keepTogetherClassSelectors,
		};

		drawDOM(element, pdfOptions)
			.then((group: Group) => {
				// fonts are critical!
				pdf.defineFont(pdfFonts);
				pdf.toBlob(group, (data: Blob) => {
					const uri = this.toUri(data);

					var success = this.$window.open(uri, '', 'width=600,height=400');

					var failedWindowOpenCookieExists = this.cookieService.check('failedWindowOpen');
					if (!failedWindowOpenCookieExists) {
						this.sleep(1500); // give time for browser to open window
						if (success == null)
							this.cookieService.set('failedWindowOpen', 'true');
					}
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.afterRender.emit();
			});
	}

	private toUri(blob: Blob): string {
		const uri = this.sanitizer.sanitize(
			SecurityContext.RESOURCE_URL,
			this.sanitizer.bypassSecurityTrustResourceUrl(
				URL.createObjectURL(blob)
			)
		);
		return uri;
	}
	

}
