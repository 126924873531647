


export class IdModel {
    public id: number = 0;

    constructor(init? : Partial<IdModel>) {
        
        Object.assign(this, init);
    }
}
