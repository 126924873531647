import { AddUpdateUserSettingsModel } from '@adj/generators/models/add-update-user-settings.model';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';
import { GetUserSettingsRequestModel } from '@adj/generators/models/get-user-settings-request.model';
import { GetUserSettingsResponseModel } from '@adj/generators/models/get-user-settings-response.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { LogRequestModel } from '@adj/generators/models/log-request.model';
import { LocationsService } from '@adj/generators/services/locations.service';
import { LogsService } from '@adj/generators/services/logs.service';
import { UserService } from '@adj/generators/services/user.service';
import { AuthSessionService } from '@adj/services/auth-session.service';
import { MessageNotificationService } from '@adj/services/message-notification.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SettingsService } from '@adj/generators/services/settings.service';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '@adj/common/models/helpers/ui-constants';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';


@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, AfterViewInit {
	userSettingsForm: FormGroup;
	locations: LocationModel[];
	serviceMessage = ''; //error/success message that comes back from a submission/API request
	hasErrors = false;
	location: LocationModel;
	error: string;

	loggedInUser: AuthenticatedUserModel;
	userSettings$: GetUserSettingsResponseModel;
	location$: LocationModel[];
	//settings: SettingsModel[];
	_USER_FILTERED_LOCATIONS = "_USER_FILTERED_LOCATIONS";

	constructor(
		private _formBuilder: FormBuilder,
		private titleSvc: Title,
		public dialog: MatDialog,
		private userService: UserService,
		private authSvc: AuthSessionService,
		private locationService: LocationsService,
		private loggingSvc: LogsService,
		private notificationSvc: MessageNotificationService,
		private SettingsService: SettingsService,
		private cookieService: CookieService,
	) { }

	ngOnInit() {
		this.titleSvc.setTitle('Adjournment Digital Service - UserSettings');

		let joinRegEx = /^\*[a-zA-Z]{0,8}$/;
		//setup form fields / validation 
		//by matching the form control names with the response models, we can use the .patchValue() to update the form with values from the obervable response
		//this is done in the AfterViewInit method
		var role = this.authSvc.getRole();
		if (role === "Admin") {
			this.userSettingsForm = this._formBuilder.group({
				courtLocationId: [0], // allow no location selected
				joinPin: ["", [Validators.required, Validators.pattern(joinRegEx)]]
			});
		}
		else {
			this.userSettingsForm = this._formBuilder.group({
				courtLocationId: [0, Validators.min(1)],
				joinPin: ["", [Validators.required, Validators.pattern(joinRegEx)]]
			});
		}
	}

	errorObj = {
		user: this.authSvc.currentUser.username,
		page: window.location.href,
		params: {},
		action: "",
		error: ""
	};



	async ngAfterViewInit() {

		var locResults = await this.locationService.getAllLocationsAsync();
		this.locations = locResults.payload;

		let locationSettings:SettingsModel;
		var settingsResults = await this.SettingsService.getSettingByKeyAsync(new GetSettingByKeyRequestModel({key:Constants.Settings.ClerkAvailableLocations})).then(data=>{
			
			if (data.isSuccess)
				locationSettings = data.payload;
			else
				throw new Error('An error occurred while getting location settings.');
		})

		//check to see if we're filtering locations
		//let locationSettings = this.settings.find(x => x.key.toLowerCase() == Constants.Settings.ClerkAvailableLocations)
		if (locationSettings) {
			let values = locationSettings.value.split('|');
			if (values.length > 0) {
				//filter based on values in the settings
				this.location$ = this.locations.filter(setting => values.includes(setting.courtCode.trim()));
			}
		}

		//setup default location in the datasource
		var defaulLocation = new LocationModel({ id: 0, name: "-- Select Location --" });
		this.location$.splice(0, 0, defaulLocation);

		//get user id from Auth user
		let request = new GetUserSettingsRequestModel({});


		try {
			this.errorObj.action = "getSettings";
			this.errorObj.params = request;

			var settingsResult = await this.userService.getSettingsAsync(request);

			if (settingsResult.isSuccess) {
				this.userSettings$ = settingsResult.payload;
				this.userSettingsForm.patchValue(this.userSettings$); //updated form fields
			}
			else {
				var objMessage = Object.values(settingsResult.errors);
				let errMessage = `${objMessage}`;
				this.handleLogging(errMessage);
				this.notificationSvc.showErrorMessage(settingsResult.message, errMessage, true);
			}
		}
		catch (err) {
			this.handleLogging(err.message); //log the error
			this.notificationSvc.showSnackBar(err.message, true);
		};
	}




	async saveSettings() {

		if (this.userSettingsForm.valid) {

			//build the request model
			let model = new AddUpdateUserSettingsModel();
			model.userUid = this.loggedInUser.uid;
			model.courtLocationId = this.userSettingsForm.controls.courtLocationId.value;
			model.joinPin = this.userSettingsForm.controls.joinPin.value.toUpperCase(); //make sure we're passing the JOIN Cass ID as uppercase
			
			try {
				this.errorObj.action = "addUpdateSettings";
				this.errorObj.params = model;


				var result = await this.userService.addUpdateSettingsAsync(model);

				if (result.isSuccess) {
					this.notificationSvc.showSnackBar('User settings saved successfully');

					//clear cookie for filter location preferences used on dashboard
					this.cookieService.delete(this._USER_FILTERED_LOCATIONS);
				}
				else {
					var objMessage = Object.values(result.errors);
					let errMessage = `${objMessage}`;
					this.handleLogging(errMessage);
					this.notificationSvc.showErrorMessage(result.message, errMessage, true);
				}
			}
			catch (err) {
				this.handleLogging(err.message); //log the error
				this.notificationSvc.showErrorMessage("An error occured", err.message, true);				
			};
		}

	}


	handleLogging(errorMessage: string) {

		this.errorObj.error = errorMessage;
		//TODO: Move to a centralized pattern? 

		var log = new LogRequestModel({
			serviceName: "Clerk Application",
			msg: JSON.stringify(this.errorObj),
			user: this.authSvc.currentUser.username,
			timestamp: new Date()
		});
		this.loggingSvc.logErrorAsync(log);
	}
}
