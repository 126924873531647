<div class="quickview-panel">
	<div class="uk-container uk-container-medium" style="margin-bottom: 20px">
		<div style="display: flex">
			<div style="flex-grow: 1">
				<h3>Request Preview</h3>
			</div>
			<div>
				<a (click)="onCloseQuickView()" style="color: #666666">
					<mat-icon>close</mat-icon>
				</a>
			</div>
		</div>
		<div><hr /></div>
		<div class="request-heading">
			<div>
				<h2>Request {{ adjournmentDetails?.uid | shortAdjUid }}</h2>
			</div>
			<div *ngIf="adjournmentDetails?.lockedBy">
				<h4 style="color: #333333">
					Locked by {{ adjournmentDetails?.lockedBy }}
				</h4>
			</div>
		</div>
		<div class="cardSectionTop">
		</div>
		<div class="detailSection">
			<!-- ADJ Details Component-->
			<div class="uk-padding-remove">
				<div class="section-heading">
					<h3>Appearance details</h3>
				</div>
				<div class="two-flex-cols">
					<div class="field-padding left-col">
						<span class="field-label">Accused name</span>
						<p class="field-value">{{ adjournmentDetails?.accusedFullName }}</p>
					</div>

					<div class="right-col">
						<span class="field-label">Date of birth</span>
						<p class="field-value"> {{ adjournmentDetails?.accusedDateOfBirth | adjDateFormat }} </p>
					</div>
				</div>
				<div class="two-flex-cols">
					<div class="left-col" style="padding-right: 15px">
						<div class="field-padding">
							<span class="field-label">Court location</span>
							<p class="field-value">{{ location?.courtHouse }}</p>
						</div>
						<div class="field-padding">
							<span class="field-label">Upcoming appearance date(s)</span>
							<app-view-more [items]="requestedAppearanceDates" [sliceIndex]="3" [isDate]="true"></app-view-more>
						</div>
					</div>
					<div class="field-padding right-spacer">
						<span class="field-label">Docket number(s)</span>
						<app-view-more [items]="docketNumbers" [sliceIndex]="3"></app-view-more>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="uk-container uk-container-medium" style="margin-bottom: 20px">
		<div class="cardSectionTop">
		</div>
		<div class="detailSection">
			<!-- ADJ Details Component-->
			<div class="uk-padding-remove">
				<div class="section-heading">
					<h3>Adjournment request summary</h3>
				</div>

				<div class="two-flex-cols">
					<div class="field-padding left-col">
						<span class="field-label">Reason for adjournment</span>
						<p class="field-value">{{ adjournmentDetails?.reason }}</p>
					</div>

					<div class="right-col">
						<span class="field-label">Adjourn to date</span>
						<p class="field-value">{{ adjournmentDetails?.adjournmentDecisionId == 12003 ? "&#8212;" : 
							((adjournmentDetails?.decisionAdjournToDate ? adjournmentDetails?.decisionAdjournToDate : adjournmentDetails?.adjournToDate) | adjDateFormat) }}
						</p>
					</div>
				</div>

				<div class="field-padding">
					<span class="field-label">Additional explanation for adjournment</span>
					<p class="field-value">
						{{ adjournmentDetails?.reasonExplanation ? adjournmentDetails?.reasonExplanation : "None" }}
					</p>
				</div>

				<div *ngIf="adjournmentDetails?.specialInstructions" class="field-padding">
					<span class="field-label">Special Instruction</span>
					<p class="field-value">{{ adjournmentDetails?.specialInstructions }}</p>
				</div>

				<div><hr /></div>
				<div style="padding-left: 15px">
					<h4>
						<!-- <strong>{{ adjournmentDetails?.agentTypeId != 10002 ? "Requesting counsel" : "Requested by" }}</strong> -->
						<strong>Requested by</strong>
					</h4>
				</div>
				<div class="two-flex-cols">
					<div class="field-padding left-col">
						<!-- <strong>{{ adjournmentDetails?.agentTypeId != 10002 ? "Counsel name" : "Name" }}</strong> -->
						<span class="field-label">Full name</span>
						<p class="field-value">{{ adjournmentDetails?.counselFullName }}</p>
					</div>

					<div class="right-col right-spacer">
						<span class="field-label">{{ adjournmentDetails?.agentTypeId === 10002 ? "Organization" : "Law firm" }}</span> 
						<p class="field-value">{{ adjournmentDetails?.lawFirm }}</p>
					</div>
				</div>
				<div class="field-padding" *ngIf="adjournmentDetails?.agentTypeId != 10002">
					<span class="field-label">Capacity of counsel</span>
					<p class="field-value">{{ determineCounsel() }}</p>
					<div
						*ngIf="adjournmentDetails?.capacityOfCounselId == 2003"
					>
						<mat-icon
							class="text-success"
							style="vertical-align: bottom"
							>check_circle_outline</mat-icon
						>
						Designation of Counsel filed
					</div>
				</div>

				<!-- <div class="field-padding">&nbsp;</div> -->
			</div>
		</div>
	</div>
	<div class="uk-container uk-container-medium">
		<button
			mat-raised-button
			class="button-secondary"
			(click)="onCloseQuickView()"
			tabindex="-1"
		>
			<mat-icon>arrow_back</mat-icon> Back to Dashboard
		</button>
	</div>
</div>
