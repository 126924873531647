import {
	Component,
	OnInit,
	ElementRef,
	Output,
	EventEmitter,
	Input,
} from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
	Credentials,
} from '@adj/common/models';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlerService } from '@adj/services/error-handler.service';
import { AuthSessionService } from '@adj/services/auth-session.service';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
	signInForm: FormGroup;

	serviceMessage = ''; //error/success message that comes back from a submission/API request
	hasErrors = false;

	error: string;

	@Output() readonly submittedCredentials: EventEmitter<Credentials> = new EventEmitter<Credentials>();

	constructor(
		private _formBuilder: FormBuilder,
		private titleSvc: Title,
		private el: ElementRef,
		public dialog: MatDialog,
		private userService: AuthSessionService,
		private errorHandlerService: ErrorHandlerService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.titleSvc.setTitle('Adjournment Digital Service - Login');

		this.signInForm = this._formBuilder.group({			
			username: ['', Validators.required],
			password: ['', Validators.required],
		});

	}

	private ValidateForm(frm: FormGroup) {
		var retVal = true;
		for (const key of Object.keys(frm.controls)) {
			if (frm.controls[key].invalid) {
				const invalidControl = this.el.nativeElement.querySelector(
					'[formcontrolname="' + key + '"]'
				);
				invalidControl.focus();
				retVal = false;
				break;
			}
		}

		return retVal;
	}

	public async signIn() {
		//Adjournment Details
		if (this.ValidateForm(this.signInForm) == true) {
			this.hasErrors = false;
			this.serviceMessage = '';

			var credentials = {
				userId: this.signInForm.controls.username.value,
				password: this.signInForm.controls.password.value,
			} as Credentials;

			try {
				var result = await this.userService.authenticate(credentials);
				this.router.navigate(['adjournment-list']);
			} catch (ex) {
				console.log(ex);
				this.error = 'Invalid username and/or password';
			}
		}
	}

	public forgotPassword() {
		console.log('Forgot Password not implemented yet');
	}
}
