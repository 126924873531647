

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { QueryResult } from '@adj/common/models/core-api/query-result';
import { LocationNameModel } from '@adj/generators/models/location-name.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { IdModel } from '@adj/generators/models/id.model';
import { LocationSettingsModel } from '@adj/generators/models/location-settings.model';
import { LocationWithScheduleModel } from '@adj/generators/models/location-with-schedule.model';
import { GetLocationWithScheduleModel } from '@adj/generators/models/get-location-with-schedule.model';

@Injectable({
    providedIn: 'root'
})
export class LocationsService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('locations'));
    }

    
    public getLocationNames() : Observable<QueryResult<LocationNameModel[]>> {
        return this.get('getLocationNames');
    }

    public async getLocationNamesAsync() : Promise<QueryResult<LocationNameModel[]>> {
        return await this.get<QueryResult<LocationNameModel[]>, null>('getLocationNames').toPromise();
    }

    
    public getLocation(model: IdModel) : Observable<QueryResult<LocationModel>> {
        return this.get('getLocation', model);
    }

    public async getLocationAsync(model: IdModel) : Promise<QueryResult<LocationModel>> {
        return await this.get<QueryResult<LocationModel>, IdModel>('getLocation', model).toPromise();
    }

    
    public getLocationSettings(model: IdModel) : Observable<QueryResult<LocationSettingsModel>> {
        return this.get('getLocationSettings', model);
    }

    public async getLocationSettingsAsync(model: IdModel) : Promise<QueryResult<LocationSettingsModel>> {
        return await this.get<QueryResult<LocationSettingsModel>, IdModel>('getLocationSettings', model).toPromise();
    }

    
    public getAllLocations() : Observable<QueryResult<LocationModel[]>> {
        return this.get('getAllLocations');
    }

    public async getAllLocationsAsync() : Promise<QueryResult<LocationModel[]>> {
        return await this.get<QueryResult<LocationModel[]>, null>('getAllLocations').toPromise();
    }

    
    public getNextAppearanceDates(model: GetLocationWithScheduleModel) : Observable<QueryResult<LocationWithScheduleModel>> {
        return this.post('getNextAppearanceDates', model);
    }

    public async getNextAppearanceDatesAsync(model: GetLocationWithScheduleModel) : Promise<QueryResult<LocationWithScheduleModel>> {
        return await this.post<QueryResult<LocationWithScheduleModel>, GetLocationWithScheduleModel>('getNextAppearanceDates', model).toPromise();
    }

    
}
