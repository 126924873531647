


export class UserModel {
    public uid: string = '';
    public username: string = '';
    public displayName: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public joinpin: string = '';
    public courtLocationId: number;
    public activationDate: Date;
    public activationBy: string = '';
    public deactivationDate: Date;
    public deactivationBy: string = '';
    public createDate: Date = new Date();
    public lastUpdatedDate: Date = new Date();
    public lastLoginDate: Date;
    public keyCloakUserId: string;

    constructor(init? : Partial<UserModel>) {
        
        Object.assign(this, init);
    }
}
