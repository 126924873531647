import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@adj/common/models/helpers/ui-constants';

@Pipe({
	name: 'capacityOfCounsel',
})
export class CapacityOfCounselPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		var counselTypeId = value;
		var capacityId = args[0]

		if (counselTypeId == Constants.CounselTypeId.DutyCounsel 
			&& capacityId == Constants.CapacityOfCounselId.NotRetained){
			return Constants.CapacityOfCounselText.DutyCounselNotRetained;
		}
		if (counselTypeId == Constants.CounselTypeId.DefenseCounsel 
			&& capacityId == Constants.CapacityOfCounselId.NotRetained){
			return Constants.CapacityOfCounselText.DefenceCounselNotRetained;
		}
		if (counselTypeId == Constants.CounselTypeId.DefenseCounsel 
			&& capacityId == Constants.CapacityOfCounselId.Retained){
			return Constants.CapacityOfCounselText.DefenceCounselRetained;
		}
		if (counselTypeId == Constants.CounselTypeId.OtherAgent 
			&& capacityId == Constants.CapacityOfCounselId.NotRetained){
			return Constants.CapacityOfCounselText.AgentNotRetained;
		}
		//TODO: don't think the following is used, can probably remove in the future once further investigation is done
		if (counselTypeId == 10003 && capacityId == 11000){
			return "Legal Agent - lawyer";
		}

		return ""
	}
}
