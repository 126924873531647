import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CompletedAdjournment } from '@adj/common/models';
import { MatTableDataSource } from '@angular/material/table';
import { CompletedAdjournmentModel } from '@adj/generators/models/completed-adjournment.model';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';

@Component({
  selector: 'app-completed-requests-listing',
  templateUrl: './completed-requests-listing.component.html',
  styleUrls: ['./completed-requests-listing.component.scss']
})
export class CompletedRequestsListingComponent implements OnInit {
	@Input() hasNoSecurityRoles:Boolean = true;
	@Input() hasNoSecurityRoleForLocation:Boolean=true;
	@Input() currentUser: AuthenticatedUserProfileKeycloakModel;
	displayedColumns: string[] = [
		'jpDecisionDate',
		'jpDecision',
		'decidingJp',
		'docketNumber',
		'accusedFullName',
		'location',
		'requestId',
		// 'confirmedBy',
		// 'confirmedDate',
		'details',
	];

	private _adjournmentCompleted: CompletedAdjournmentModel[] = [];
	completedDataSource: MatTableDataSource<CompletedAdjournmentModel>;
	
	@Input() set adjournmentCompleted(value: CompletedAdjournmentModel[]) {
		this._adjournmentCompleted = value;
		this.completedDataSource = new MatTableDataSource(value || []);
	}

	@Output() openQuickView: EventEmitter<string> = new EventEmitter<string>();
	
	ngOnInit() {}

	onOpenQuickView(row: CompletedAdjournmentModel){
		this.openQuickView.emit(row.uid);
	}
}
