import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

	constructor(private authService:AuthenticationService,private router: Router) { 
		this.authService.logout(); 
	}

	ngOnInit(): void {
	}

}
