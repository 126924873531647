import { LocationSchedule } from './location-schedule.model';

export class Location {
	id: number;
	name: string;
	address: string;
	phone: string;
	jassId: string;
	locationSchedules: LocationSchedule[]
}
