import { NotificationResult } from './notification-result.model';

export class AdjournmentGranted {
	uid: string;
	accusedFullName: string;
	docketNumber: string;
	adjournToDate: string;

	emailNotificationResult: NotificationResult;
	smsNotificationResult: NotificationResult;
}
