import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpObjectMapper } from './http-object-mapper';
import { tap, publishReplay, refCount } from 'rxjs/operators';
import { Dictionary } from '../../shared/models/dictionary';

export abstract class EndPointService {
	protected constructor(public http: HttpClient, public apiUrl: string) {}

	private localCache$: Map<string, Observable<any>> = new Map<
		string,
		Observable<any>
	>();

	protected getOrAdd<TOutput, TInput>(
		url: string,
		params: TInput = null,
		automap: boolean = true
	): Observable<TOutput> {

		

		let value$ = this.localCache$.get(url);

		if (!value$) {
			value$ = this.get(url, params, {}, automap).pipe(
				publishReplay(1),
				refCount()
			) as Observable<TOutput>;

			this.localCache$.set(url, value$);
		}

		return value$;
	}

	protected get<TOutput, TInput>(
		action: string,
		params: TInput = null,
		headers: Dictionary<string> = {},
		automap: boolean = true
	): Observable<TOutput> {
		let httpParams = new HttpParams();

		//check to see if action has apiUrl defined in config.json
		if (this.apiUrl == undefined) {
			console.log(`Missing entry for api url in config.json for action: [url]/${action}`);
			throw new Error(`Missing entry for api url in config.json for action: [url]/${action}`);
		}
		
		if (params !== null) {
			Object.keys(params).forEach(function (fieldName) {
				const val = params[fieldName];

				if (val instanceof Array) {
					val.forEach((c, i) => {
						const keyIndex = fieldName + '[' + i + ']';
						httpParams = httpParams.append(keyIndex, c);
					});
				} else if (val instanceof Date) {
					
						httpParams = httpParams.append(fieldName, val.toISOString());
					
				} else {
					if (val !== null) {
						httpParams = httpParams.append(fieldName, val);
					}
				}
			});
		}

		let req = {
			params: httpParams,
			headers: new HttpHeaders(),
		};

		Object.entries(headers).map(([key, value]) => {
			req.headers = req.headers.set(key, value);
		});

		return this.http
			.get(this.apiUrl + action, req)
			.pipe(
				tap((responseObj) =>
					automap
						? HttpObjectMapper.mapObjectFromServer(responseObj)
						: responseObj
				)
			) as Observable<TOutput>;
	}

	protected put<TOutput, TInput>(
		action: string,
		params: TInput = null
	): Observable<TOutput> {
		return this.http
			.put(
				this.apiUrl + action,
				HttpObjectMapper.mapObjectToServer(params),
				{
					withCredentials: true,
				}
			)
			.pipe(
				tap((responseObj) =>
					HttpObjectMapper.mapObjectFromServer(responseObj)
				)
			) as Observable<TOutput>;
	}

	protected post<TOutput, TInput>(
		action: string,
		params: TInput
	): Observable<TOutput> {

		//check to see if action has apiUrl defined in config.json
		if (this.apiUrl == undefined) {
			console.log(`Missing entry for api url in config.json for action: [url]/${action}`);
			throw new Error(`Missing entry for api url in config.json for action: [url]/${action}`);
		}
		
		var url = this.apiUrl + action;
		var body = HttpObjectMapper.mapObjectToServer(params);

		return this.http.post(url, body).pipe(
			tap((responseObj) => {
				return HttpObjectMapper.mapObjectFromServer(responseObj);
			})
		) as Observable<TOutput>;
	}

	protected deleteCore<TOutput, TInput>(
		action: string,
		params: TInput
	): Observable<TOutput> {
		let httpParams = new HttpParams();

		if (params !== null) {
			Object.keys(params).forEach(function (key) {
				const val = params[key];

				if (val instanceof Array) {
					val.forEach((c, i) => {
						const keyIndex = key + '[' + i + ']';
						httpParams = httpParams.append(keyIndex, c);
					});
				} else {
					httpParams = httpParams.append(key, val);
				}
			});
		}

		const options = {
			params: httpParams,
			withCredentials: true,
		};

		return this.http
			.delete(this.apiUrl + action, options)
			.pipe(
				tap((responseObj) =>
					HttpObjectMapper.mapObjectFromServer(responseObj)
				)
			) as Observable<TOutput>;
	}
}
