


export class AdjournmentAcknowledgeExpireRequestModel {
    public uid: string = '';

    constructor(init? : Partial<AdjournmentAcknowledgeExpireRequestModel>) {
        
        Object.assign(this, init);
    }
}
