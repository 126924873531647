export class CompletedAdjournment {
	id: number;
	uid: string;
	dateConfirmed: Date;
	confirmedBy: string;
	docketNumber: string;
	accusedFullName: string;
	jpDecisionSubmitted: Date;
	decidingJp: string;
	statusId: number;
	locked: boolean;
}
