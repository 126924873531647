<app-layout-header headerType="admin"></app-layout-header>

<!-- <div class="uk-container uk-container-medium uk-padding-small uk-padding-remove-horizontal">
	<div class="uk-grid-collapse uk-width-1-1" uk-grid>
		<div class="uk-width-1-1">
			<a href="/adjournment-list">
				<button mat-raised-button class="button-secondary uk-margin-remove">
					<mat-icon>arrow_back</mat-icon> Back to Dashboard
				</button></a>
		</div>
	</div>
</div> -->

<section class="uk-section uk-padding-remove">
	<div class="uk-section uk-container uk-container-medium uk-padding-remove">
		<div *ngIf="(hasNoSecurityRoles || hasNoSecurityRoleForLocation)">
			<table class="uk-table uk-table-divider goa-section">
				<tbody>
				<tr >
					<td class="uk-table-shrink header-danger">
						<mat-icon>warning_amber</mat-icon>
					</td>
					<td class="header-danger-background">
					<h3 class="uk-text-bold">No permissions for {{clerkLocation}}</h3>
					<p>
						Your CASS/JOIN user ID currently has permission to process adjournment requests for the location(s): <b>{{clerkSecurityRoles}}</b>. Please confirm the user ID and/or dashboard court location is set correctly. If the issue persists, please ask your manager/supervisor to contact the JSG Service Desk to have a court location assigned to your CASS/JOIN user ID.
					</p>
					</td>
				</tr>									
				</tbody>
			</table>
		</div>
		<h2>
			My Account
		</h2>
		<div *ngIf="currentUser; else loading">
			<table class="uk-table uk-table-divider goa-section" *ngIf="invalidEmail else accessPending">
				<tbody>
				<tr >
					<td class="uk-table-shrink header-danger">
						<mat-icon>info_outline</mat-icon>
					</td>
					<td class="uk-background-muted">
					<h3 class="uk-text-bold">Incorrect email address</h3>
					<p>You have logged into ADS with <strong>{{currentUser.email}}</strong>. 
						To gain access to the Adjournment Digital Service you need to sign in with your <strong>@gov.ab.ca</strong> account (aka your 1GX login). Please sign out and sign back in with the correct email address.
					</p>
					</td>
				</tr>									
				</tbody>
			</table>


			<ng-template #accessPending>
				<table class="uk-table uk-table-divider goa-section" *ngIf="isPending">
					<tbody>									
						<tr>
							<td class="uk-table-shrink header-warning">
								<mat-icon>info_outline</mat-icon>
							</td>
							<td class="header-warning-background">
							<h3 class="uk-text-bold">Your access is pending</h3>
							<p>
								To complete your registration and receive access to the ADS dashboard, please ask your manager to email the ADS Support Team (<a href="mailto:JSG.AdjournmentSupport@gov.ab.ca">JSG.AdjournmentSupport@gov.ab.ca</a>) including your full name and registered email address (<strong>{{currentUser.email}}</strong>).
							</p>
							</td>
						</tr>
					
					</tbody>
				</table>
			</ng-template>

		</div>

		<div *ngIf="currentUser; else loading">

			<div class="goa-section" *ngIf="!invalidEmail">
				<div class="uk-grid-small uk-child-width-1-2@m" uk-grid>
					<div>
						<h3>User Settings</h3>
					</div>
					<div class="alignRight" *ngIf="!isPending">
						<a class="actionLink" (click)="showEditDialog()"><mat-icon class="icon">edit_outlined</mat-icon>Edit</a>
					</div>
				</div>
			
				<div class="grey-block uk-width-1-1@l">
					<div class="uk-grid-small uk-child-width-1-4@m" uk-grid>
					<div>
						<p class="muted">Court Location(s)</p>					
						<p>{{clerkLocation}}</p>						
						
					</div>
					<div>
						<p class="muted">CASS/JOIN User ID</p>
						<p *ngIf="clerk?.joinpin else pinNotSet">{{clerk.joinpin}}</p>
						<ng-template #pinNotSet>
							<p>Not Set</p>						
						</ng-template>
					</div>
					<div>
						<p class="muted">CASS/JOIN assigned locations</p>
						<p *ngIf="clerkSecurityRoles">{{clerkSecurityRoles}}</p>
					</div>
					</div>
				</div>
			</div>




			<div class="goa-section" *ngIf="!invalidEmail">
				<div class="uk-grid-small uk-child-width-1-2@m" uk-grid>
					<div>
						<h3>Personal information</h3>
					</div>			
				</div>
			
				<div class="grey-block uk-width-1-1@l">
					<div class="uk-grid-small uk-child-width-1-4@m" uk-grid>
						<div>
							<p class="muted">Full name</p>
							<p>{{ currentUser.firstName + " " + currentUser.lastName }}</p>
						</div>
						<div>
							<p class="muted">Email address</p>
							<p>{{ currentUser.email }} </p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #loading>
			Loading...
		</ng-template>
	</div>
</section>


<div id="userSettingsModal" uk-modal style="z-index: 1 !important;" class="uk-width-1-1">
	<div class="uk-modal-dialog uk-width-1-2@l uk-width-1-2@m">
	<form [formGroup]="userSettingsForm" (submit)="saveSettings()" class="uk-width-1-1">
			<BR>
			<div uk-grid class="uk-text-left uk-grid-column-collapse uk-grid-row-small component-border uk-child-width-1-1 uk-padding-large">
				<div>
					<h1>User Settings</h1>
				</div>
				<div>
					<table class="uk-table uk-margin-remove uk-table-divider">
						<tbody>
							<tr>
								<td class="uk-table-shrink header-warning">
	
									<mat-icon>error_outlined</mat-icon>
								</td>
								<td class="header-warning-background">								
									<p>
										Your CASS/JOIN User ID starts with a * and contains 8 characters or less (e.g.
										*BROWND). It can be found in the event history of a file endorsed via CASS, and it
										is also known by your manager/supervisor. It is not the same as your JOIN pin.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div>
					<p class="formLabel">CASS/JOIN User ID:</p>
					<div>
						<mat-form-field appearance="outline">
							<input data-cy="joinPin" matInput name="joinPin" formControlName="joinPin" style="text-transform: uppercase;" (input)="onJoinPinChange($event.target.value)"/>
							<mat-error class="goa-errorLabel" *ngIf="userSettingsForm?.controls.joinPin.invalid">
								<span *ngIf="userSettingsForm?.controls.joinPin.errors.required">CASS/JOIN User ID is required.</span>
								<span *ngIf="userSettingsForm?.controls.joinPin.errors.pattern">Invalid CASS/JOIN User ID.</span>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="(hasNoSecurityRoles)">
					<table class="uk-table uk-margin-remove uk-table-divider">
						<tbody>
							<tr>
								<td class="uk-table-shrink header-danger">
									<mat-icon>warning_amber</mat-icon>
								</td>
								<td class="header-danger-background">								
									<p>
										No location(s) are assigned to your CASS/JOIN user ID. Please confirm the user ID is correct. If the issue persists, please ask your manager/supervisor to contact the JSG Service Desk to have a court location assigned to your CASS/JOIN user ID.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div>
					<div class="grey-block uk-width-1-1@l">
						<div class="uk-grid-small uk-child-width-1-1@m" uk-grid>
						
						<div>
							<p class="muted">CASS/JOIN assigned location(s):</p>
							<p>{{clerkSecurityRoles}}</p>
						</div>
						
						</div>
					</div>
				</div>
				<div>
					<p class="formLabel">Dashboard court location</p>
					<div>
						<mat-form-field class="goa-selectField">
							<mat-select data-cy="location" formControlName="courtLocationId" (selectionChange)="onLocationChange($event.value)">
								<!-- Default Value set in .ts -->
								<mat-option [value]="0">All locations</mat-option>
								<mat-option *ngFor="let location of location$" [value]="location.id"
									[attr.data-cy]="'location-' + location.id">{{ location.name }}</mat-option>
							</mat-select>
							<mat-error class="goa-errorLabel" *ngIf="userSettingsForm?.controls.courtLocationId.invalid">
								<span *ngIf="userSettingsForm?.controls.courtLocationId.errors.min">Court location is required.</span>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div *ngIf="(hasNoSecurityRoleForLocation)">
					<table class="uk-table uk-margin-remove uk-table-divider">
						<tbody>
							<tr>
								<td class="uk-table-shrink header-danger">
									<mat-icon>warning_amber</mat-icon>
								</td>
								<td class="header-danger-background">								
									<p>
										You do not have permission to process adjournment requests for the selected dashboard court location as this location is not assigned to your CASS/JOIN user ID. Please ask your manager/supervisor to contact the JSG Service Desk to have a court location assigned to your CASS/JOIN user ID.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="uk-width-1-1 uk-text-right">
					<div class="uk-width-1-1">
						<button mat-raised-button data-cy="btnCancel" class="button-seconary uk-modal-close" type="button">Cancel</button>&nbsp;
						<button mat-raised-button data-cy="btnSubmit" class="button-primary" type="submit">Save </button>
					</div>			
				</div>
			</div>
		
	</form>
	</div>
</div>
