import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'numberSuffix',
})
export class NumberSuffixPipe implements PipeTransform {
	constructor(private _sanitizer: DomSanitizer) {}
	transform(value: number, ...args: unknown[]): unknown {
		if (value) {
			var suffix = this.determineSuffix(value);

			var numberWithSuffix = value + '<sup>' + suffix + '</sup>';

			return this._sanitizer.bypassSecurityTrustHtml(numberWithSuffix);
		}

		return value;
	}

	determineSuffix(value: number) {
		var j = value % 10,
			k = value % 100;
		if (j == 1 && k != 11) {
			return 'st';
		}
		if (j == 2 && k != 12) {
			return 'nd';
		}
		if (j == 3 && k != 13) {
			return 'rd';
		}
		return 'th';
	}
}
