


export class DocketModel {
    public docketNumber: string = '';

    constructor(init? : Partial<DocketModel>) {
        
        Object.assign(this, init);
    }
}
