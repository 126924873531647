


export class UserLastLoginUpdateModel {
    public keycloakUserId: string = '';

    constructor(init? : Partial<UserLastLoginUpdateModel>) {
        
        Object.assign(this, init);
    }
}
