import { CodeType } from '@adj/common/models';
import { NotificationComponent } from '@adj/components/notification/notification.component';
import { MockTestScenarioModel } from '@adj/generators/models/mock-test';
import { MockService } from '@adj/common/services/mock.service';
import { CodesService } from '@adj/services/codes.service';
import { AfterViewInit, ElementRef, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as UIkit from 'uikit';
@Component({
  selector: 'app-scenario-builder',
  templateUrl: './scenario-builder.component.html',
  styleUrls: ['./scenario-builder.component.scss']
})
export class ScenarioBuilderComponent implements OnInit, AfterViewInit {
  el: ElementRef;
  scenarios: Array<string>;
  scenarioValue: string= "";
  scenarioName: string= "";
  
  constructor(private _codesService: CodesService, private mockSvc: MockService, public dialog: MatDialog, public _el: ElementRef, private _snackBar: MatSnackBar) { 
    this.el = _el;
   
    this.scenarios = new Array<string>();
  }
   async ngOnInit() {
  }
  async ngAfterViewInit() {
    //Get the test scenarios from the Mock Data API 
    var tempArray:any = await this._codesService.getCodeTestScenarios() as CodeType[];
    //Add default values to scenarios for dropdown binding
    //TODO: Discuss in tech meeting
    this.scenarios.push("Default"); //default scenario
    var test = tempArray.payload;
    for(var i=0;i<test.length;i++)
    {
      this.scenarios.push(test[i].value);
    }
    var x: number = 99;
  }
  public changeScenario(e){
		//let target = e.source.selected._element.nativeElement;
		this.scenarioName = this.getSelectedText(e);
		this.scenarioValue = e.value;		
  }
  getSelectedText(e) {
		return e.source.selected._element.nativeElement.innerText.trim();
  }
  

  async refreshData(){
    let requestModel = new MockTestScenarioModel();
    requestModel.key = "fb09fe4a-2464-4ce2-b0d1-ad278045f041";
    requestModel.scenarioName = this.scenarioName;
    try{
      var result = await this.mockSvc.refreshAsync(requestModel);
      this._snackBar.openFromComponent(
        NotificationComponent,
        {
          data: {
            message: "You're mock data has been reset successfully using the " +this.scenarioName + " test case.",
          },
          horizontalPosition: 'center',
          verticalPosition: 'top'      
        }
      );        
    }
    catch(err)
    {
      this._snackBar.openFromComponent(
        NotificationComponent,
        {
          data: {
            message: "An error occured refreshing mock data using the " +this.scenarioName + " test case.",
          },
          horizontalPosition: 'center',
          verticalPosition: 'top',
          //panelClass: 'notification-error',
        }
      );      
    }
    UIkit.modal("#modalConfirmReset").hide();
  }
  openDialog(): void {
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '550px',
      data: { name: this.scenarioName }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     // alert('reset mock data!!');
    });
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <div class="uk-padding-small uk-height-small">
      <p>You are going to reset the JP Admin data with scenario: {{data.name}} </p>
      <p><strong>Do you want to continue?</strong></p>
      <button
      mat-raised-button
      class="button-primary uk-width-medium"
      type="button" 
      data-cy="buttonResetMockData"
  >
      Yes
  </button>
  <button
  mat-raised-button
  class="button-secondary uk-width-medium"
  type="button" 
  data-cy="buttonResetMockData"
>
  No
</button>
    </div>`,
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}