import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
	ChangeDetectorRef,
} from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { AdjournmentSummaryModel } from '@adj/generators/models/adjournment-summary.model';
import { CourtsService } from '@adj/generators/services/courts.service';
import { CircuitCourtModel } from '@adj/common/models/locations-api/circuit-locations.model';
import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from '@adj/generators/services/settings.service';
import { Constants } from '@adj/common/models/helpers/ui-constants';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { GetCourtCircuitsRequestModel } from '@adj/generators/models/get-court-circuits-request.model';

@Component({
	selector: 'app-adj-list',
	templateUrl: './adj-list.component.html',
	styleUrls: ['./adj-list.component.scss'],
})
export class AdjListComponent implements OnInit {
	displayedColumns: string[] = [
		'daysUntilAppearance',
		'dateRequested',
		'docketNumber',
		'accusedFullName',
		'location',
		'requestId',
		'details',
	];
	
	public availableLocations: CircuitCourtModel[] = [];
	public filteredLocations: CircuitCourtModel[] = [];
	public adjournmentSummaryModels: AdjournmentSummaryModel[] = [];
	public activeLocations = [];
	_USER_FILTERED_LOCATIONS = Constants.Cookies.UserFilteredLocations;
	public initializedCheckboxes: boolean = false;
	public initializedAvailableLocations: boolean = false;
	
	reviewDataSource: MatTableDataSource<AdjournmentSummaryModel>;
	@Input() set adjournmentReview(value: AdjournmentSummaryModel[]) {
		this.adjournmentSummaryModels = value;
	}
	userLocationId: number;
	@Input() currentUser: AuthenticatedUserProfileKeycloakModel;
	@Input() hasNoSecurityRoles:Boolean = true;
	@Input() hasNoSecurityRoleForLocation:Boolean=true;
	
	@Input() set userLocationIdentifier(value: number) {
		this.userLocationId = value;
	}

	@Output() openQuickView: EventEmitter<string> = new EventEmitter<string>();

	@ViewChildren('locationCheckbox') private locationCheckboxes : QueryList<any>;

	constructor(
		private courtsService: CourtsService,
		private cookieService: CookieService,
		private SettingsService: SettingsService,
		private changeDetectorRefs: ChangeDetectorRef
	) {}


	async ngOnInit() {
		let courtId = this.userLocationId;
		
		//get active locations allowed for sumbissions
		var settingsRequest: GetSettingByKeyRequestModel = new GetSettingByKeyRequestModel({
			key: Constants.Settings.SubmissionAvailableLocations
		});
		
		let settingsResults = await this.SettingsService.getSettingByKeyAsync(settingsRequest);
		
		if(settingsResults.isSuccess){
			let locationSettings = settingsResults.payload;
			if(locationSettings){
				this.activeLocations = locationSettings.value.split('|');
				this.activeLocations = this.activeLocations.map(s => s.trim());
			}
		}
		
		
		//get circuit locations
		this.getCircuitLocations(courtId);
	}


	refresh(source: AdjournmentSummaryModel[]){
		this.adjournmentSummaryModels = source;
		this.updateResultDataSource(this.userLocationId);	
		this.updateCheckBoxesFromCookie();
	}

	getCircuitLocations(courtId: number) {
		if (courtId <= 0)
		{
			this.updateResultDataSource(courtId);
			return;
		}

		// only initialize available locations once based on shared location services api
		if (!this.initializedAvailableLocations) 
    {
      let requestModel = new GetCourtCircuitsRequestModel();
      requestModel.courtId = courtId;
      requestModel.courtType = 'PC';

      this.courtsService.getCourtCircuits(requestModel)
				.subscribe((data: any) => 
				{
					let currentAvailableLocations: CircuitCourtModel[] = [];
					currentAvailableLocations = data.circuitCourts;

					//add base court
					let circuitCourtModel = {divisionId:null, courtId:data.courtId, courthouse:data.courthouse, courtCode:data.courtCode};
					currentAvailableLocations.push(circuitCourtModel);

					//filter out only active locations
					
					this.availableLocations = currentAvailableLocations.filter( a => this.activeLocations.includes(a.courtCode)); 
					this.availableLocations.sort((a,b) => (a.courthouse > b.courthouse) ? 1 : ((b.courthouse > a.courthouse) ? -1 : 0));

					this.initializedAvailableLocations = true; //only call api once 
				

					//must call update datasource here after the above async steps finished
					this.updateResultDataSource(courtId);
				}
		);
		} else{
			this.updateResultDataSource(courtId);
		}
	}

	updateResultDataSource(courtId: number){
		var filteredAdjournmentSummaryModels: AdjournmentSummaryModel[];

		var userFilteredLocationsExists = this.cookieService.check(this._USER_FILTERED_LOCATIONS);
		if (courtId > 0 && userFilteredLocationsExists) {
			this.filteredLocations = JSON.parse(this.cookieService.get(Constants.Cookies.UserFilteredLocations)) //[9, 51];

			let cookieLocationIds = this.filteredLocations.map(a => a.courtId);
			if (cookieLocationIds.length > 0)
			{
				filteredAdjournmentSummaryModels = 
					this.adjournmentSummaryModels.filter(({ locationId }) => cookieLocationIds.includes(locationId));
			}
			else
				filteredAdjournmentSummaryModels = this.adjournmentSummaryModels;
		}
		else
			filteredAdjournmentSummaryModels = this.adjournmentSummaryModels;
		
	
		this.reviewDataSource = new MatTableDataSource(filteredAdjournmentSummaryModels || []);
	}

	ngAfterViewInit() {
		// initialize checkboxes from cookie
		this.locationCheckboxes.changes.subscribe(c => { 
			if (!this.initializedCheckboxes){
				this.updateCheckBoxesFromCookie();
				this.initializedCheckboxes = true;
			}
		});
	}

	updateCheckBoxesFromCookie() {
		var userFilteredLocationsExists = this.cookieService.check(this._USER_FILTERED_LOCATIONS);
		if (userFilteredLocationsExists) {
			this.filteredLocations = JSON.parse(this.cookieService.get(this._USER_FILTERED_LOCATIONS)) //[9, 51];

			let cookieLocationIds = this.filteredLocations.map(a => a.courtId);
			if (cookieLocationIds.length > 0)
			{
				//check the boxes for locations stored in cookie
				this.locationCheckboxes.toArray().forEach((item) => {

					let currentLocationId = parseInt(item.id);
					if (cookieLocationIds.includes(currentLocationId))
						item.checked = true;
				});
			}
		}
	}


	updateResults(){
		var filteredAdjournmentSummaryModels: AdjournmentSummaryModel[];
		let filteredLocationIds = this.filteredLocations.map(a => a.courtId);
		if (filteredLocationIds.length > 0)
			{
				filteredAdjournmentSummaryModels = 
					this.adjournmentSummaryModels.filter(({ locationId }) => filteredLocationIds.includes(locationId));
			}
			else
				filteredAdjournmentSummaryModels = this.adjournmentSummaryModels;
		this.reviewDataSource = new MatTableDataSource(filteredAdjournmentSummaryModels || []);
	}
	onOpenQuickView(row: AdjournmentSummaryModel){
		this.openQuickView.emit(row.uid);
	}

	clearAll(){
		let checkboxes = this.locationCheckboxes.toArray();
		for (let checkbox of checkboxes) {
			checkbox.checked = false;
		  }
		this.filteredLocations = [];	
		this.saveFilteredLocationsToCookie();
		this.updateResults();
	}

	selectLocation(
		checkBoxStatus: boolean,
		event: any,
		rowData: CircuitCourtModel
	) {
		if (checkBoxStatus===true){
			// alert("uncheck:" + rowData.courtId +" "+ rowData.courthouse);
			this.filteredLocations = this.filteredLocations.filter(function(value, index, arr){ 
				return value.courtId != rowData.courtId;
			});
		}
		
		else{
			// alert("check:" + rowData.courtId +" "+ rowData.courthouse);
			if (this.filteredLocations.filter(f => f.courtId === rowData.courtId).length === 0) {
				this.filteredLocations.push(rowData);
				this.filteredLocations.sort((a, b) => (a.courthouse > b.courthouse) ? 1 : -1)
			}
		}
		this.saveFilteredLocationsToCookie();
		this.updateResults();
	}
	removeLocation(rowData: CircuitCourtModel){
		// alert("uncheck:" + rowData.courtId +" "+ rowData.courthouse);
			this.filteredLocations = this.filteredLocations.filter(function(value, index, arr){ 
				return value.courtId != rowData.courtId;
			});
	
			this.locationCheckboxes.toArray().forEach((item) => {
				if (item.id == rowData.courtId)
					item.checked = false;
			});

		this.saveFilteredLocationsToCookie();
		this.updateResults();
	}

	saveFilteredLocationsToCookie(){
		
		if (this.filteredLocations.length > 0)
			this.cookieService.set(this._USER_FILTERED_LOCATIONS, JSON.stringify(this.filteredLocations));
		else
			this.cookieService.delete(this._USER_FILTERED_LOCATIONS);

	}
}
