import { NotificationComponent } from '@adj/components/notification/notification.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
	providedIn: 'root',
})
export class MessageNotificationService {
	constructor(private _snackBar: MatSnackBar) {}

	messageDuration: number;

	showSnackBar(snackcident: string, awaitDismiss: boolean = false){	
		this.messageDuration = 3000; //set the duration default everytime

		if(awaitDismiss === true){
			this.messageDuration = 0; //clear duration so user must dismiss the message
		}
		this._snackBar.openFromComponent(NotificationComponent, {
			duration: this.messageDuration,
			data: {
				message: snackcident,
				duration: this.messageDuration
			},
			horizontalPosition: 'center',
			verticalPosition: 'top'			
		});
	}


	showErrorMessage(title: string, snackcident: string, awaitDismiss: boolean = false){
		if(awaitDismiss){
			this.messageDuration = 0; //wait for the user to dismiss the message
		}

		this._snackBar.openFromComponent(NotificationComponent, {
			duration: this.messageDuration,
			data: {
				title: title,
				message: snackcident,
				duration: this.messageDuration,
				errorType: "application"
			},
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: "errorNotification"			
		});
	}
}
