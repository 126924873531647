import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
	selector: 'app-app-redirect',
	templateUrl: './app-redirect.component.html',
	styleUrls: ['./app-redirect.component.scss']
})
export class AppRedirectComponent implements OnInit {
		
	redirectUrl: string;
	constructor( private Route: Router, private route: ActivatedRoute) { }

	ngOnInit(): void {
		 //Grab query parameters from the URL
		 this.route.queryParams.subscribe(params=>{
			
			this.redirectUrl=params.redirectUrl;
		  })
	}
	
}