

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { CommandResult } from '@adj/common/models/core-api/command-result';
import { JoinResponseModel } from '@adj/generators/models/join-response.model';
import { DocketListModel } from '@adj/generators/models/docket-list.model';
import { AccusedSearchModel } from '@adj/generators/models/accused-search.model';
import { AccusedSearchResultModel } from '@adj/generators/models/accused-search-result.model';
import { AccusedSearchRequestModel } from '@adj/generators/models/accused-search-request.model';

@Injectable({
    providedIn: 'root'
})
export class JoinService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('join'));
    }

    
    public searchByDocketNumber(model: DocketListModel) : Observable<CommandResult<JoinResponseModel[]>> {
        return this.post('searchByDocketNumber', model);
    }

    public async searchByDocketNumberAsync(model: DocketListModel) : Promise<CommandResult<JoinResponseModel[]>> {
        return await this.post<CommandResult<JoinResponseModel[]>, DocketListModel>('searchByDocketNumber', model).toPromise();
    }

    
    public searchByAccusedName(model: AccusedSearchModel) : Observable<CommandResult<JoinResponseModel[]>> {
        return this.get('searchByAccusedName', model);
    }

    public async searchByAccusedNameAsync(model: AccusedSearchModel) : Promise<CommandResult<JoinResponseModel[]>> {
        return await this.get<CommandResult<JoinResponseModel[]>, AccusedSearchModel>('searchByAccusedName', model).toPromise();
    }

    
    public simpleSearchByAccused(model: AccusedSearchModel) : Observable<CommandResult<AccusedSearchResultModel>> {
        return this.get('simpleSearchByAccused', model);
    }

    public async simpleSearchByAccusedAsync(model: AccusedSearchModel) : Promise<CommandResult<AccusedSearchResultModel>> {
        return await this.get<CommandResult<AccusedSearchResultModel>, AccusedSearchModel>('simpleSearchByAccused', model).toPromise();
    }

    
    public detailedByAccusedName(model: AccusedSearchRequestModel) : Observable<CommandResult<JoinResponseModel[]>> {
        return this.post('detailedByAccusedName', model);
    }

    public async detailedByAccusedNameAsync(model: AccusedSearchRequestModel) : Promise<CommandResult<JoinResponseModel[]>> {
        return await this.post<CommandResult<JoinResponseModel[]>, AccusedSearchRequestModel>('detailedByAccusedName', model).toPromise();
    }

    
}
