<div>
	<div *ngFor="let file of adjDetails?.docketDecisions; index as i">
		<!-- stand-alone div.page-break helps other blocks break properly -->
		<div *ngIf="i > 0" class="page-break"></div>
		<div class="container">
			<div>
				<img
					class="insignia"
					src="/assets/images/endorsement-insignia.jpg"
				/>
			</div>
			<div class="title-container">
				<div class="title">ENDORSEMENT NOTICE</div>
				<div class="sub-title">Adjournment Digital Service</div>
			</div>
		</div>
		<div style="margin-top: 50px">
			<div class="container">
				<div class="narrow-label-column"><strong>Date:</strong></div>
				<div class="value-column">
					{{ adjDetails?.decisionDate | date: "mediumDate" }} -
					{{ adjDetails?.decisionDate | date: "shortTime" }}
				</div>
			</div>
			<div class="container">
				<div class="narrow-label-column">
					<strong>Location:</strong>
				</div>
				<div class="value-column">{{ location?.name }}</div>
			</div>
			<div class="container">
				<div class="narrow-label-column">
					<strong>Non-presiding Justice of the Peace:</strong>
				</div>
				<div class="value-column">{{ adjDetails?.decisionJp }}</div>
			</div>
		</div>
		<div class="vertical-space-20">
			<div>
				Please be advised that an adjournment request has been received
				and
				<strong>{{ adjDetails?.decision }} </strong> through the
				Adjournment Digital Service.
			</div>
		</div>

		<div class="borders vertical-space-20 docket-info">
			<div class="bottom-border">
				<div class="sub-title indent">Docket files</div>
			</div>
			<div class="indent">
				<div class="container">
					<div class="label-column">
						<strong>Docket/ ticket #:</strong>
					</div>
					<div class="value-column">
						{{ file?.docketNumber }}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Accused name:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.accusedFullName }}
					</div>
				</div>
				<div class="container">
					<div class="label-column"><strong>Courtroom:</strong></div>
					<div class="value-column">
						{{ docketOriginalCourtRoom(file?.docketNumber) }}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Count & charges:</strong>
					</div>
					<div class="value-column">
						<div *ngFor="let charge of file?.charges">
							{{ charge?.chargeCode }} -
							{{ charge?.crownElectionDisplay }}
						</div>
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Appearance type:</strong>
					</div>
					<div class="value-column">
						{{ getChargesAppearanceTypes(file?.charges) }}
					</div>
				</div>
			</div>
		</div>

		<div class="borders vertical-space-20 request-details">
			<div class="bottom-border">
				<div class="sub-title indent">Request details (Request ID: {{ adjDetails?.uid | shortAdjUid }})</div>
			</div>
			<div class="indent">
				<!-- <div class="container">
					<div class="label-column"><strong>Request ID:</strong></div>
					<div class="value-column">
						{{ adjDetails?.uid | shortAdjUid }}
					</div>
				</div> -->
				<div class="container">
					<div class="label-column">
						<strong>Adjournment reason:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.reason }}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Adjournment explanation:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.reasonExplanation }}
					</div>
				</div>
				<div class="container vertical-space-20" *ngIf="adjDetails?.agentTypeIdentifier == 13001 || adjDetails?.agentTypeIdentifier == 13003">
					<div class="label-column">
						<strong>Requested by:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.counselFullName }} {{ adjDetails?.agentTypeIdentifier == 13003 ? " (agent)" : "" }}
					</div>
				</div>
				<ng-container *ngIf="adjDetails?.agentTypeIdentifier == 13002">
				<div class="container vertical-space-20">
					<div class="label-column">
						<strong>Requested by:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.agentFullName + " (Legal assistant)" }}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>On behalf of:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.counselFullName }}
					</div>
				</div>
				</ng-container>
				<div class="container" *ngIf="adjDetails?.agentTypeId != 10002">
					<div class="label-column">
						<strong>Capacity of support:</strong>
					</div>
					<div class="value-column">
						{{
							adjDetails.agentTypeId
								| capacityOfCounsel
									: adjDetails.capacityOfCounselId
						}}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Organization name:</strong>
					</div>
					<div class="value-column">
						{{
							adjDetails.lawFirm
						}}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Requested on:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.createDate | date: "mediumDate" }} -
						{{ adjDetails?.createDate | date: "shortTime" }}
					</div>
				</div>
				<!-- <div class="container">
					<div class="label-column">
						<strong>Reviewed on:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.decisionDate | date: "mediumDate" }} -
						{{ adjDetails?.decisionDate | date: "shortTime" }}
					</div>
				</div> -->
				<div class="container">
					<div class="label-column"><strong>Decision:</strong></div>
					<div class="value-column">
						{{ adjDetails?.decision }}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Endorsement note:</strong>
					</div>
					<div class="value-column">
						{{ adjDetails?.decisionNotes }}
					</div>
				</div>
			</div>
		</div>

		<div class="borders vertical-space-20 next-appearance">
			<div class="bottom-border">
				<div class="sub-title indent">New court appearance</div>
			</div>
			<div class="indent">
				<div class="container">
					<div class="label-column"><strong>Date & time:</strong></div>
					<div class="value-column">
						{{ adjDetails?.decisionAdjournToDate | date: "mediumDate" }} {{getCourtroomTime()}}
					</div>
				</div>
				<div class="container">
					<div class="label-column">
						<strong>Court location:</strong>
					</div>
					<div class="value-column">
						{{ location?.name }}
					</div>
				</div>
				<div class="container">
					<div class="label-column"><strong>Courtroom:</strong></div>
					<div class="value-column">
						{{ adjDetails?.docketDecisions[i]?.requestedCourtRoomId }}
					</div>
				</div>
				<!-- the following feature is no longer used -->
				<!-- <div class="container">
					<div class="label-column">
						<strong>Special requirements:</strong>
					</div>
					<div class="value-column">
						<div
							*ngIf="
								adjDetails.frenchTrial == false &&
								adjDetails.childFriendlyCourtroom == false &&
								adjDetails.interpreterRequired == false
							"
						>
							None
						</div>
						<div *ngIf="adjDetails.frenchTrial == true">
							French trial
						</div>
						<div *ngIf="adjDetails.childFriendlyCourtroom == true">
							Child friendly courtroom
						</div>
						<div *ngIf="adjDetails.interpreterRequired == true">
							Interpreter required:
							<strong>{{
								adjDetails.interpreterLanguage
							}}</strong>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="disclaimers">
			<div class="vertical-space-20 line-height-14">
				<div class="sub-title2">Designation of Counsel</div>
				<div class="sub-content">
					Counsel has confirmed that, by using this service, they have filed a Designation of Counsel if the matter is hybrid or is proceeding by Indictment, and that they are the designated counsel for this accused (if applicable).
				</div>
			</div>
			<div class="vertical-space-20 line-height-14">
				<div class="sub-title2">Reading of Charge(s) Waived</div>
				<div class="sub-content">
					The requester, on behalf of the accused, has confirmed that they have instructions from their client to waive reading of the charge(s); being put to his/her election as required in s. 536(2) of the Criminal Code and entering an election in relation to mode of trial.
				</div>
			</div>
			<div class="vertical-space-20 line-height-14">
				<div class="sub-title2">Language rights</div>
				<div class="sub-content">
					The requester has confirmed that the accused has been advised of their right under s. 530 of the Criminal Code to apply to have their criminal proceedings conducted in French or English or in both languages and of the time before which such an application must be made.
				</div>
			</div>
			<div *ngIf="adjDetails.skipAccusedContactInfo" class="vertical-space-20 line-height-14">
				<div class="sub-title2">No accused contact information</div>
				<div class="sub-content">
					The adjournment requested by Legal Aid of Alberta Duty Counsel or an Agent mentioned in O.C.334/2003, did not include contact information for the accused. Therefore, the accused did not receive notifications updates regarding the status of the adjournment request. The Counsel/Agent has acknowledged that they are responsible for informing the accused of their next court appearance.
				</div>
			</div>
		</div>
		<!-- <div class="vertical-space-20">
			<div class="container">
				<div class="sub-title">Confirmed by:</div>
				<div
					class="value-column"
					style="border-bottom: 1px solid black"
				>
					&nbsp;
				</div>
			</div>
		</div>
		<div class="vertical-space-20">
			<div class="sub-title">
				Printed on: {{ today | date: "medium" }}
			</div>
		</div> -->
	</div>
</div>
