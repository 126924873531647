export class ConfirmationPendingAdjournment {
	id: number;
	uid: string;
	jpDecisionSubmitted: Date;
	docketNumber: string;
	accusedFullName: string;
	originalDate: Date;
	statusId: number;
	locked: boolean;
}
