<ng-container *ngIf="!isMaintenanceMode; else showMaintenancePage">
	<app-layout-header headerType="public"></app-layout-header>
	<div class="banner">
		<div class="uk-section uk-container uk-container-medium">
			<h1>Adjournment Dashboard</h1>
			<div class="uk-width-1-2@m">
				<p>
					Adjournment requests for criminal adult matters in Alberta
					Court of Justice
				</p>
			</div>
		</div>
	</div>

	<div class="uk-padding-small muted">
		<div class="uk-container uk-container-small">
			<div class="uk-padding-small">
				<h3><strong>Overview</strong></h3>
				<p>
					The Adjournment Digital Service (ADS) allows counsel, legal
					assistants, and Agents to request adjournments for initial
					appearances for the Court of Justice criminal adult matters.
					This service also streamlines the review and endorsement
					process for CMO clerks, and automates many of the current
					manual steps via the Adjournment Dashboard.
				</p>
			</div>
		</div>
	</div>

	<div class="uk-container uk-container-small uk-padding-small">
		<div class="uk-padding-small">
			<p>
				<strong>Note: </strong>To access the Adjournment Dashboard,
				please use your @gov.ab.ca email address. You will be redirected
				to the Government of Alberta Microsoft identify provider to sign
				in.
			</p>

			<p class="goa-section-bottom">
				<a
					mat-raised-button
					class="button-primary text-white"
					href="/adjournment-list"
					>Sign in</a
				>
			</p>
		</div>
	</div>
</ng-container>

<ng-template #showMaintenancePage>
	<goa-system-unavailable
		[maintenance]="maintenance"
	></goa-system-unavailable>
</ng-template>
