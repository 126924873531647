


export class UidModel {
    public uid: string = '';

    constructor(init? : Partial<UidModel>) {
        
        Object.assign(this, init);
    }
}
