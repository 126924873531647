


export class MockTestScenarioModel {
    public key: string = "";
    public scenarioName: string = "";
       
    constructor(init? : Partial<MockTestScenarioModel>) {
        
        Object.assign(this, init);
    }
}
