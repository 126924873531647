<div class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-section-xlarge"> 	
	<div class="roundedIcon">
		<span class="material-icons">
			alt_route
			</span>
	</div>		
	<div class="underbar uk-flex uk-flex-center"></div>
	<div class="uk-margin uk-text-center uk-width-1-3@l uk-width-1-2@m uk-padding-small">
		<h3 class="uk-text-nowrap@s">The Adjournment Digital Service has moved!</h3>
		<p>Please ensure you update any saved bookmarks at your earliest convenience. 
			<br /><br/>
			New URL: <a href="https://{{redirectUrl}}">{{redirectUrl}}</a>
		</p>
		
	</div>
</div>