import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-table-status-chip',
	templateUrl: './table-status-chip.component.html',
	styleUrls: ['./table-status-chip.component.scss'],
})
export class TableStatusChipComponent implements OnInit {
	@Input() adjournmentStatusId: number;
	@Input() adjournmentDecisionId: number;
	// @Input() originalCourtDate: Date;

	// get isExpired(): boolean {
	// 	if (this.originalCourtDate) {
	// 		return this.originalCourtDate < new Date();
	// 	}

	// 	return false;
	// }

	constructor() {}

	ngOnInit(): void {}
}
