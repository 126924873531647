

import { CourtRoomModel } from './court-room.model';
import { LocationSettingsModel } from './location-settings.model';

export class LocationModel {
    public id: number = 0;
    public name: string = '';
    public courtHouse: string = '';
    public physicalAddress: string = '';
    public phone: string = '';
    public email: string = '';
    public cmophone: string = '';
    public cmoemail: string = '';
    public dutyCounselPhone: string = '';
    public dutyCounselEmail: string = '';
    public efsocietyPhone: string = '';
    public efsocietyEmail: string = '';
    public crownPhone: string = '';
    public crownEmail: string = '';
    public crownFax: string = '';
    public crownOffice: string = '';
    public nativeCounselPhone: string = '';
    public courtCode: string = '';
    public filedIn: string = '';
    public isCmolocation: boolean = false;
    public jaasId: string = '';
    public createDate: Date = new Date();
    public createdBy: string = '';
    public lastUpdateDate: Date = new Date();
    public lastUpdatedBy: string = '';
    public courtRooms: CourtRoomModel[] = [];
    public settings: LocationSettingsModel = new LocationSettingsModel();

    constructor(init? : Partial<LocationModel>) {
        
        Object.assign(this, init);
    }
}
