import { Charge } from './charge.model';

export class Information {
	id: number;
	docketNumber: string;
	accusedName: string;
	nextAppearanceDate: Date;
	courtRoom: string;
	createDate: Date;

	MockCharges: Charge[];
}
