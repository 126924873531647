


export class AddUpdateUserSettingsModel {
    public userUid: string = '';
    public joinPin: string = '';
    public courtLocationId: number = 0;

    constructor(init? : Partial<AddUpdateUserSettingsModel>) {
        
        Object.assign(this, init);
    }
}
