import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdjournmentDecision } from '@adj/common/models/adjournment-decision.model';
import { CodeType } from '@adj/common/models';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { AdjournmentActionInputModel } from '@adj/generators/models/adjournment-action-input.model';
import { AdjournmentActionModel } from '@adj/generators/models/adjournment-action.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';

import { NotificationComponent } from '@adj/components/notification/notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SettingsService } from '@adj/generators/services/settings.service';
import { AdjournmentMatterResponseModel } from '@adj/generators/models/adjournment-matter-response.model';
import { Constants } from '@adj/common/models/helpers/ui-constants';
import { LogsService } from '@adj/generators/services/logs.service';
import { LogRequestModel } from '@adj/generators/models/log-request.model';
import { EndorsementSheetContainerComponent } from '../../containers/endorsement-sheet-container/endorsement-sheet-container.component';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';

import { CodesService } from '@adj/generators/services/codes.service';
import { GetAppearanceTypesCommandRequestModel } from '@adj/generators/models/get-appearance-types-command-request.model';
import { CodeModel } from '@adj/generators/models/code.model';

@Component({
	selector: 'app-decision-confirmation',
	templateUrl: './decision-confirmation.component.html',
	styleUrls: ['./decision-confirmation.component.scss'],
})
export class DecisionConfirmationComponent implements OnInit, AfterViewInit {
	decision: AdjournmentDecision;
	reasonCodes: CodeType[];
	reasonText: string;
	user: AuthenticatedUserProfileKeycloakModel;


	//JOIN Legacy Write
	submitResult: CommandResult<AdjournmentActionModel> = null;


	//JOIN 2.0 response
	adjournmentResult: CommandResult<AdjournmentMatterResponseModel> = null;

	buttonsDisabled: boolean = false;


	//PDF Generator settings
	grantSuccessful: boolean = false;
	printNow: boolean = false;	
	displayManualGrantMessage: boolean = false;
	@ViewChild(EndorsementSheetContainerComponent) endorsementSheetContainer: EndorsementSheetContainerComponent;
	
	appearanceTypesData: CodeModel[] = [];
	displayAutoUpdateCrownElectionMsg: boolean = false;
	displayNotAvailableCrownElectionMsg: boolean = false;

	constructor(
		private _codesService: CodesService,
		private _logSvc: LogsService,
		private authenticationService:AuthenticationService,
		private adjSvc: AdjournmentsService,
		public dialogRef: MatDialogRef<DecisionConfirmationComponent>,
		private _snackBar: MatSnackBar,
		private SettingsService: SettingsService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		console.log(data);
		this.decision = data.decision;
	}
	async ngOnInit() { 
		
		//get data for dropdown
		var appearanceTypeResult = await this._codesService.getAppearanceTypesAsync(
			new GetAppearanceTypesCommandRequestModel({docketType: ''})); 
		if(appearanceTypeResult.isSuccess){
			this.appearanceTypesData = appearanceTypeResult.output;
		}

		// determine whether to display AutoUpdateCrownElection message
		for(let docket of this.decision.summaryDockets)
		{
			var updateCrownElections = docket.charges.filter(
				x=> x.crownElection == 'H' && (x.crownElectionPrism == 'S' || x.crownElectionPrism == 'I'));
			if (updateCrownElections.length > 0) {
				this.displayAutoUpdateCrownElectionMsg = true;
				break;
			}
		}
		// determine whether to display NotAvailable message
		for(let docket of this.decision.summaryDockets)
		{
			var crownElectionsNotAvailable = docket.charges.filter(x=> x.crownElectionPrism == 'Not available');
			if (crownElectionsNotAvailable.length > 0) {
				this.displayNotAvailableCrownElectionMsg = true;
				break;
			}
		}
	}

	async ngAfterViewInit() {
		let reasons: any = await this._codesService.reasonsAsync();

		if (reasons) {
			this.reasonCodes = reasons.payload;
			var reasonCodeType = this.reasonCodes.find(
				(r) => r.code === this.decision.reasonCode
			);

			if (reasonCodeType) {
				this.reasonText = reasonCodeType.value;
			}
		}

		
		this.user = await this.authenticationService.getCurrentUser();
	}

	//for now don't prevent double-click from happening...just log it so we can begin collecting more information on this event
	isSingleClick: Boolean = true;
	async decisionDoubleClick(event) {
		
		try {
			//log the double-click event
			let log: LogRequestModel = new LogRequestModel();
			log.adjournmentUid = this.decision.uid;
			
			log.msg = "User double-clicked on the Decision confirmation button";
			log.serviceName = Constants.ServiceName.ClerkApp;
			log.user = this.user.displayName;
			this._logSvc.logInfoAsync(log); //don't need to await info logging			
		}
		catch (err){
			console.log(err);			
			//swallow info logging exceptions for now
		}
	}




	//Event to process the request
	async onDecisionSubmit(event) {
		//disable the confirm button and let the processing methods restore when they're done
		this.buttonsDisabled = true;		
		
		//begin processing decision
		this.submitAndPrint();					
	}


	//handle the submit event
	async submitAndPrint() {		
		this.buttonsDisabled = true; //disable the Grant button as soon as it's clicked

		let decisionRequest = new AdjournmentActionInputModel({
			uid: this.decision.uid,
			decisionNotes: this.decision.jpDecisionNotes,
			updatedAdjournToDate: new Date(this.decision.adjournToDate),
			docketDecisions: this.decision.summaryDockets,
			manualGrant: this.decision.isManualGrant,
		});

		this.GrantAdjournment(decisionRequest);		
	}



	//Default decision flow for R4
	async GrantAdjournment(decisionRequest: AdjournmentActionInputModel) {
		if (this.decision.statusId == Constants.DecisionStatusIds.Grant) {
			await this.handleGrantAdjournment(decisionRequest);
		} else if (this.decision.statusId == Constants.DecisionStatusIds.Reject) {
			this.clearAppearanceTypes(decisionRequest);
			await this.handleRejectAdjournment(decisionRequest);
		}
	}

  //don't save appearance Types on deny
	clearAppearanceTypes(decisionRequest: AdjournmentActionInputModel) {
		decisionRequest.docketDecisions.forEach(x => {
			x.charges?.forEach(c => {
				c.appearanceType = null;
				c.appearanceTypeDesc = null;
			});
		});
	}



	//JOIN 2.0 Grant
	async handleGrantAdjournment(decisionModel: AdjournmentActionInputModel) {
		this.buttonsDisabled = true;
		try {
			this.adjournmentResult = await this.adjSvc.grantAsync(decisionModel);
			if (this.adjournmentResult.isSuccess) {

				// this property will eventually popup endorsement sheet via app-pdf-generator component
				this.grantSuccessful = true; 

				for (let index = 0; index < 3; index++)
				{
					if (this.endorsementSheetContainer != null) {
						break;
					}
					await this.sleep(1000);
				}
					
				// refresh endorsement sheet data before generating pdf later
				let finished = await this.endorsementSheetContainer.refreshData(this.decision.uid);

				// await this.sleep(3000);
				this.printNow = finished;

				//dialog window will be closed automatically when the PDF is finished rendering
			}
			else {
				//adjournment was not successful...close the dialog manually
				this.buttonsDisabled = false;
				this.dialogRef.close(this.adjournmentResult); //close event is on the adjourment-details PAGE in the dialog.close subscribe
			}
		} catch (ex) {
			console.log(ex);
			this.showErrorNotification();
			this.buttonsDisabled = false;
			this.dialogRef.close(this.adjournmentResult); //close event is on the adjourment-details PAGE in the dialog.close subscribe
		}
	}




	afterPdfRenders() {
		this.buttonsDisabled = false;
		this.dialogRef.close(this.adjournmentResult);
	}

	async handleRejectAdjournment(decisionModel: AdjournmentActionInputModel) {
		try {
			this.submitResult = await this.adjSvc.rejectAsync(decisionModel);
			this.dialogRef.close(this.submitResult);
		} catch (ex) {
			console.log(ex);
			this.showErrorNotification();
			this.dialogRef.close(null);
		}
	}

	showErrorNotification() {
		this._snackBar.openFromComponent(NotificationComponent, {
			data: {
				message: 'There was an error while submitting the Adjournment.',
			},
			horizontalPosition: 'center',
			verticalPosition: 'top',
			//panelClass: 'notification-error',
		});
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	getAppearanceTypeDescription(meta1: string)
	{
		let appearanceTypes = this.appearanceTypesData.filter(x=>x.meta1 == meta1);
		if (appearanceTypes.length > 0)
			return appearanceTypes[0].value;
	}
}
