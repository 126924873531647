<div class="ab-footer">
  <section class="uk-container uk-container-medium">
    <div class="copyright">
      <nav class="uk-navbar" id="navbar" data-uk-navbar>
        <!-- App version -->
        <div uk-tooltip="title: Version 5.0.6" class="uk-navbar-right">&copy; 2022 Government of Alberta</div>
      </nav>
    </div>
  </section>
</div>
