import { Credentials } from '@adj/common/models';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthSessionService {

	constructor(
		private _cookieService: CookieService,
		private authService: AuthenticationService,
	) {}

	public get currentUser(): AuthenticatedUserModel {
		var authCookieExists = this._cookieService.check('adj-auth');

		if (authCookieExists) {
			var cookieStr: string = this._cookieService.get('adj-auth');
			var user = JSON.parse(cookieStr);

			return user;
		} else {
			return null;
		}
	}

	public isUserAuthenticated() : boolean {
		var authCookieExists = this._cookieService.check('adj-auth');

		return authCookieExists;
	}

	public getRole(): string {
		let role: string = 'Clerk';

		if (this.currentUser) {
			if (this.currentUser.roles.includes('Admin')) {
				role = 'Admin';
			} else if (this.currentUser.roles.includes('Manager')) {
				role = 'Manager';
			}

			return role;
		}
	}

	public signOut() {
		this._cookieService.delete('adj-auth', '/');
	}

	public async authenticate(credentials: Credentials) {
		this._cookieService.delete('adj-auth', '/');

		var credStr = credentials.userId + ':' + credentials.password;
		var token = 'Basic ' + btoa(credStr);

		var result = await this.authService.authenticateAsync(token);

		if (result.isSuccess)
		{
			var userData = result.output;
			var cookieStr = JSON.stringify(userData);

			this._cookieService.set('adj-auth', cookieStr);

			return userData;
		}
		throw new Error('Failed to authenticate user ' + credentials.userId);
	}
}
