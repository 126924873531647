


export class GetSettingByKeyRequestModel {
    public key: string = '';

    constructor(init? : Partial<GetSettingByKeyRequestModel>) {
        
        Object.assign(this, init);
    }
}
