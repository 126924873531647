<app-layout-header headerType="admin"></app-layout-header>
<div class="uk-section uk-container uk-container-medium" *ngIf="docketDetails">
	<div class="uk-grid-small uk-width-1-1" uk-grid>
		<div class="uk-width-1-1">
			<a (click)="goBack()">
				<button mat-raised-button class="button-tertiary uk-margin-remove">
					<mat-icon>arrow_back</mat-icon> Back to Dashboard
				</button></a>
		</div>
		<div uk-grid class="uk-width-1-1 uk-grid-collapse">
			<div class="uk-width-expand">
				<h2 class="uk-text-wrap">Request ID: {{ details?.uid.substring(0,8).toUpperCase() }} -
					{{ details?.accusedFullName }}</h2>
			</div>
			<!-- <div class="uk-width-auto@l uk-width-1-1@m uk-text-nowrap uk-align-right uk-margin-remove">
				<div class="uk-text-right@l" style="margin-top: 20px;">Date request submitted
					{{ details?.createDate | adjDateFormat }} at {{ details?.createDate | date: "h:mm a" | lowercase }}
					(MST)
				</div>
			</div> -->
		</div>
		<div class="uk-width-1-3@s">
			<div class="cardSectionTop">
			</div>
			<div class="detailSection">
				<div class="uk-padding-small"><h3 class="noMarginBottom">Appearance details</h3></div>
				<div uk-grid class="uk-grid uk-grid-collapse uk-child-width-1-2@l uk-padding-remove">
					
					<div class="uk-padding-small">
						<p class="sectionLabel">Full Name</p>
						<p class="event-card-section-label-value">{{ details?.accusedFullName }} </p>
					</div>

					<div class="uk-padding-small">
						<p class="sectionLabel">Date of birth</p>
						<p class="event-card-section-label-value"> {{ details?.accusedDateOfBirth | adjDateFormat }} </p>
					</div>
				</div>

				<!-- ADJ Details Component-->
				<div *ngIf="isRetainedCounsel() != true" class="uk-grid uk-grid-collapse uk-child-width-1-1 uk-padding-remove">
					<div>
						<mat-accordion>
							<mat-expansion-panel [disabled]="details?.skipAccusedContactInfo">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<span *ngIf="!details?.skipAccusedContactInfo" class="event-card-section-label-contact">Contact Details</span>
										<span *ngIf="details?.skipAccusedContactInfo" class="event-card-section-label disableColor">Contact Details</span>
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div class="uk-padding-small uk-background-muted">
									<span class="event-card-section-label">Email address</span>
									<p class="event-card-section-label-value">{{ details?.accusedEmail != '' ? details?.accusedEmail : '&#8212;' }}</p>

									<span class="event-card-section-label">Mobile phone number</span>
									<p class="event-card-section-label-value">{{ details?.accusedMobilePhone != '' ? (details?.accusedMobilePhone | phone) : '&#8212;' }}</p>

									<span class="event-card-section-label">SMS notifications &amp; updates</span>
									<p class="event-card-section-label-value"> {{ details?.accusedReceiveSms === true ? "Yes" : "No" }} </p>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
						<div>
							<table *ngIf="details?.skipAccusedContactInfo"
								class="uk-table uk-table-divider goa-section goa-section-custom uk-table-custom">
								<tbody>
								<tr> 
									<td class="uk-table-shrink header-warning">
									<mat-icon>error_outlined</mat-icon>
									</td>
									<td class="header-warning-background">
									<p>
										No accused contact information. Accused will not receive notification updates about the decision and/or changes to the adjournment request.
									</p>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>


				<div>
					
					<div *ngIf="details else detailsFromJoin">
						<!-- Loaded directly from the database -->
						<div uk-grid class="uk-grid uk-grid-collapse uk-child-width-1-2@m uk-padding-remove">
							<div class="uk-padding-small">
								<p class="sectionLabel">Court location</p>
							<p class="event-card-section-label-value">{{ location?.courtHouse}} </p>
							</div>
	
							<div class="uk-padding-small">
								<p class="sectionLabel">Upcoming appearance date(s)</p>
								<div *ngFor="let docket of details.docketDecisions; let idx = index;"
									class="event-card-section-label-value">
									{{ docket?.requestedAppearanceDate | adjDateFormat }}
								</div>
								
							</div>
						</div>


					</div>

					<ng-template #detailsFromJoin>
						<!-- Details pulled from Join search by docket result -->
						<div uk-grid class="uk-grid uk-grid-collapse uk-child-width-1-2@m uk-padding-remove">
							<div class="uk-padding-small">
								<p class="sectionLabel">Court location</p>
								<p class="event-card-section-label-value">{{ location?.courtHouse}} </p>
							</div>
	
							<div class="uk-padding-small">
								<p class="sectionLabel">Upcoming appearance date(s)</p>
							<div *ngFor="let docket of docketDetails.results; let idx = index;"
								class="event-card-section-label-value">
									{{ docket?.nextAppearanceDate | adjDateFormat }}
								</div>								
							</div>
						</div>

						
					</ng-template>

					<div uk-grid class="uk-grid-collapse uk-padding-remove">
						<div class="uk-padding-small uk-width-1-1 noPaddingBottom">
							<p class="sectionLabel">Docket number(s) and charges</p>
						</div>
							<app-docket-detail-list [adjournment]="details" [activeDocket]="activeDocket"
								class="uk-padding-small uk-width-1-1 noPaddingTop"></app-docket-detail-list>
						<div>&nbsp;</div>
					</div>
					
					
				</div>

			</div>
			<div class="cardSectionTop requestedBySection">
			</div>
			<div class="detailSection">
				<div class="uk-padding-small"><h3 class="noMarginBottom">Requested by</h3></div>

				<div *ngIf="isCounsel || isAgent" uk-grid class="uk-grid-collapse goa-section detailSection noPaddingBottom noPaddingTop" style="border:none;">
					<div class="uk-padding-small">
						<p class="sectionLabel">{{ details?.agentTypeId != 10002 ? "Counsel name" : "Agent name" }}</p>
						<p class="event-card-section-label-value">{{ details?.counselFullName }} </p>
					</div>
					<div class="uk-grid uk-grid-collapse uk-child-width-1-1 uk-padding-remove">
						<div>
							<mat-accordion>
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title>
											<span class="event-card-section-label-contact">Contact Details</span>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div class="uk-padding-small uk-background-muted">
										<span class="event-card-section-label">Email address</span>
										<p class="event-card-section-label-value">{{ details?.counselEmail }}</p>

										<span class="event-card-section-label">Mobile phone number</span>
										<p class="event-card-section-label-value">{{ details?.counselMobilePhone != '' ? (details?.counselMobilePhone | phone) : '&#8212;' }}</p>

										<span class="event-card-section-label">SMS notifications &amp; updates</span>
										<p class="event-card-section-label-value">
											{{ details?.counselReceiveSms === true ? "Yes" : "No" }}
										</p>
									</div>
								</mat-expansion-panel>
							</mat-accordion>

						</div>
					</div>


					<div class="uk-padding-small">
						<p class="sectionLabel">{{ details?.agentTypeId != 10002 ? "Law firm" : "Organization" }}</p>
						<p class="event-card-section-label-value">{{ details?.lawFirm }}</p>
					</div>
					<div class="uk-padding-small" *ngIf="details?.agentTypeId != 10002">
						<div>
						<p class="sectionLabel">Capacity of support</p>
						<p class="event-card-section-label-value">
							{{
								details?.agentTypeId
									| capacityOfCounsel
										: details?.capacityOfCounselId
							}}
						</p>
						</div>
						<div *ngIf="details?.capacityOfCounselId == 2003">
							<mat-icon class="text-success" style="vertical-align: bottom;">check_circle_outline
							</mat-icon> Designation
							of Counsel filed
						</div>
					</div>

				</div>

				<div *ngIf="isLegalAssistant" uk-grid class="uk-grid-collapse goa-section detailSection noPaddingBottom noPaddingTop"
					style="border:none;">
					<div class="uk-padding-small">
						<p class="sectionLabel">Legal assistant name</p>
						<p class="event-card-section-label-value">{{ details?.agentLastName + ", " + details?.agentFirstName }} </p>
					</div>

					<div class="uk-grid uk-grid-collapse uk-child-width-1-1 uk-padding-remove">
						<div>
							<mat-accordion>
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title>
											<span class="event-card-section-label-contact">Contact Details</span>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div class="uk-padding-small uk-background-muted">
										<span class="event-card-section-label">Email address</span>
										<p class="event-card-section-label-value">{{ details?.agentEmail }}</p>

										<span class="event-card-section-label">Mobile phone number</span>
										<p class="event-card-section-label-value">{{ details?.agentPhone != '' ? (details?.agentPhone | phone) : '&#8212;' }}</p>
									</div>
								</mat-expansion-panel>
							</mat-accordion>

						</div> 
					</div>


					<div class="uk-padding-small">
						<h3>On behalf of</h3>
					</div>
					<div class="uk-padding-small">
						<p class="sectionLabel">Counsel name</p>
						<p class="event-card-section-label-value">{{ details?.counselFullName }} </p>
					</div>
					<div class="uk-grid uk-grid-collapse uk-child-width-1-1 uk-padding-remove">
						<div>
							<mat-accordion>
								<mat-expansion-panel>
									<mat-expansion-panel-header>
										<mat-panel-title>
											<span class="event-card-section-label-contact">Contact Details</span>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div class="uk-padding-small uk-background-muted">
										<span class="event-card-section-label">Email address</span>
										<p class="event-card-section-label-value">{{ details?.counselEmail }}</p>

										<span class="event-card-section-label">Mobile phone number</span>
										<p class="event-card-section-label-value">{{ details?.counselMobilePhone != '' ? (details?.counselMobilePhone | phone) : '&#8212;' }}</p>

										<span class="event-card-section-label">SMS notifications &amp; updates</span>
										<p class="event-card-section-label-value">
											{{ details?.counselReceiveSms === true ? "Yes" : "No" }}
										</p>
									</div>
								</mat-expansion-panel>
							</mat-accordion>

						</div>
					</div>

					<div class="uk-padding-small">
						<p class="sectionLabel">Law firm</p>
						<p class="event-card-section-label-value">{{ details?.lawFirm }}</p>
					</div>
					<div class="uk-padding-small">
						<p class="sectionLabel">Capacity of support</p>
						<p class="event-card-section-label-value">
							{{
								details?.agentTypeId
									| capacityOfCounsel
										: details?.capacityOfCounselId
							}}
						</p>
						<div *ngIf="details?.capacityOfCounselId == 2003">
							<mat-icon class="text-success" style="vertical-align: bottom;">check_circle_outline
							</mat-icon> Designation
							of Counsel filed
						</div>
					</div>

				</div>
			</div>
		</div>


		<!-- History -->
		<div class="uk-width-2-3@s">

			<div class="uk-child-width-1-1 uk-grid-small" uk-grid>

				<div class="uk-margin-remove-top">

					<div>
						<!-- ADJ Timeline Component-->
						<div id="offcanvas-usage" *ngIf="displayJoinEventHistoryLocations.length > 0" 
							uk-offcanvas>
							<div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">

								<button class="uk-offcanvas-close" type="button" uk-close></button>
								
								<h3>JOIN file history: {{docketJoinHistory?.docketNumber}}</h3>
								<hr/>
								<div *ngFor="let eventCard of docketJoinHistory?.eventCards; let idx = index">
									<div class="aboveCardTitle">
										{{eventCard.eventDate | adjShortDateFormat}}
										<!-- <ng-container *ngIf="!eventCard.joinEventCard.isEventOnly && eventCard.joinEventCard.completionCode !== 'B'">
											<b>{{ordinal_suffix_of(eventCard.joinEventCard.appearanceNumber)}} Appearance</b>&nbsp;&nbsp;&nbsp;{{eventCard.eventDate | adjShortDateFormat}}
										</ng-container>
										<ng-container *ngIf="!eventCard.joinEventCard.isEventOnly && eventCard.joinEventCard.completionCode === 'B'">
											{{eventCard.eventDate | adjShortDateFormat}}
										</ng-container>
										<ng-container *ngIf="eventCard.joinEventCard.isEventOnly">
											{{eventCard.eventDate | adjShortDateFormat}}
										</ng-container> -->
									</div>
									<div *ngIf="!eventCard.joinEventCard.isEventOnly" class="card roundedCorners">
										<div class="card-header" *ngIf="eventCard.joinEventCard.completionCode !== 'B'">
											<div class="badges">
												<p *ngFor="let courtRoomItem of eventCard.joinEventCard.courtRooms"
													class="whitePill">
													{{courtRoomItem.courtRoom}}{{courtRoomItem.displayChargeCount}}
												</p>
												<p *ngFor="let appearanceTypeItem of eventCard.joinEventCard.appearanceTypes"
													class="whitePill">
													{{getAppearanceTypeDescription(appearanceTypeItem.appearanceType)}}{{appearanceTypeItem.displayChargeCount}}
												</p>
												<p *ngFor="let crownElectionItem of eventCard.joinEventCard.crownElections"
													 class="grayPill tooltip">
													<img src="/assets/images/check.svg" />&nbsp;{{getCrownElectionDescription(crownElectionItem.crownElection)}}{{crownElectionItem.displayChargeCount}}
													<span class="tooltiptext">{{crownElectionItem.processedDateTime | adjShortDateFormat}}</span>
												</p>
											</div>
										</div>
										<div class="card-detail" [class.card-detail-completion-code-active]="eventCard.joinEventCard.completionCode == 'A'">
											
											<ng-container *ngIf="eventCard.joinEventCard.completionCode !== 'A' && eventCard.joinEventCard.completionCode !== 'B'">
											<div>
												<p class="card-detail-label">Reason for adjournment</p>
												<p>{{getAdjournmentReasonCode(eventCard.joinEventCard.adjournmentReasonCode)}}</p>
											</div>
											
											<div>
												<p class="card-detail-label">Adjourned by</p>
												<p>{{getAdjournmentByDescription(eventCard.joinEventCard.adjournedBy)}}</p>
											</div>
											<div>
												<p class="card-detail-label">Results</p>
												<p>
													<span *ngFor="let actionItem of eventCard.joinEventCard.actions;let isFirst = first;let isLast = last; ">{{getEventActionDescription(actionItem.action)}}{{ !isLast ? "," : ""}}
													</span>
													<span *ngIf="eventCard.joinEventCard.actions.length == 0">None</span>
												</p>
											</div>
											<div *ngIf="eventCard.joinEventCard.crownComments.length > 0 || eventCard.joinEventCard.documentText.length > 0">
												<p class="card-detail-label">Comments</p>
												<p *ngIf="((eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) == 1)
														&& (getCommentsLength(eventCard.joinEventCard) <= truncateCommentsLength || eventCard.joinEventCard.showMoreComments)"
														>
													<span *ngIf="eventCard.joinEventCard.crownComments.length == 1">
														{{eventCard.joinEventCard.crownComments[0]}}
													</span>
													<span *ngIf="eventCard.joinEventCard.documentText.length == 1">
														{{eventCard.joinEventCard.documentText[0]}}
													</span>
												</p>
												<p *ngIf="((eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) == 1)
														&& (getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength && !eventCard.joinEventCard.showMoreComments)"
														class="noMarginBottom">
													<span *ngIf="eventCard.joinEventCard.crownComments.length == 1">
														{{eventCard.joinEventCard.crownComments[0].substring(0, truncateCommentsLength) + 
															(eventCard.joinEventCard.crownComments[0].length > truncateCommentsLength ? "..." : "")}}
													</span>
													<span *ngIf="eventCard.joinEventCard.documentText.length == 1">
														{{eventCard.joinEventCard.documentText[0].substring(0, truncateCommentsLength) + 
															(eventCard.joinEventCard.documentText[0].length > truncateCommentsLength ? "..." : "")}}
													</span>
												</p>
												
												<ul *ngIf="(eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) > 1
													&& (getCommentsLength(eventCard.joinEventCard) <= truncateCommentsLength || eventCard.joinEventCard.showMoreComments)" class="noMarginTopBottom">
													<li *ngFor="let crownComment of eventCard.joinEventCard.crownComments">
														{{ crownComment }}
													</li>
													<li *ngFor="let docText of eventCard.joinEventCard.documentText">
														{{ docText }}
													</li>
												</ul>
												<ul *ngIf="(eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) > 1
													&& (getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength && !eventCard.joinEventCard.showMoreComments)" class="noMarginTopBottom">
													<li *ngFor="let crownComment of eventCard.joinEventCard.crownComments">
														{{ crownComment.substring(0, truncateCommentsLength) + 
															(crownComment.length > truncateCommentsLength ? "..." : "")}}
													</li>
													<li *ngFor="let docText of eventCard.joinEventCard.documentText">
														{{ docText.substring(0, truncateCommentsLength) + 
															(docText.length > truncateCommentsLength ? "..." : "")}}
													</li>
												</ul>
												<div *ngIf="getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength" (click)="eventCard.joinEventCard.showMoreComments = !eventCard.joinEventCard.showMoreComments">
													<u>Show
													<span>{{eventCard.joinEventCard.showMoreComments ? 'less' : 'more'}}</span>
													</u>
												</div>
											</div>
											</ng-container>
											<div *ngIf="eventCard.joinEventCard.completionCode == 'B'" class="badges paddingBottom">
												<p *ngFor="let courtRoomItem of eventCard.joinEventCard.courtRooms"
													class="whitePill">
													{{courtRoomItem.courtRoom}}{{courtRoomItem.displayChargeCount}}
												</p>
												<p *ngFor="let appearanceTypeItem of eventCard.joinEventCard.appearanceTypes"
													class="whitePill">
													{{getAppearanceTypeDescription(appearanceTypeItem.appearanceType)}}{{appearanceTypeItem.displayChargeCount}}
												</p>
											</div>
											<div *ngIf="eventCard.joinEventCard.completionCode == 'B'">
												<p class="card-detail-label">Events</p>
												<p>BROUGHT FORWARD TO {{docketJoinHistory?.eventCards[idx-1].eventDate | adjDateFormat:true }}</p>
											</div>
											<div *ngIf="eventCard.joinEventCard.completionCode == 'A'">
												<p class="card-detail-label">Results</p>
												<p>ACTIVE</p>
											</div>
										</div>
									</div>
									<div *ngIf="eventCard.joinEventCard.isEventOnly" class="card roundedCorners">
										
										<div class="card-detail">
											
											<div>
												<p class="card-detail-label">Events</p>
												<p>
													<span *ngFor="let actionItem of eventCard.joinEventCard.actions;let isFirst = first;let isLast = last; ">{{getEventActionDescription(actionItem.action)}}{{ !isLast ? "," : ""}}
													</span>
													<span *ngIf="eventCard.joinEventCard.actions.length == 0">None</span>
												</p>
											</div>
											<div *ngIf="eventCard.joinEventCard.crownComments.length > 0 || eventCard.joinEventCard.documentText.length > 0">
												<p class="card-detail-label">Comments</p>
												<p *ngIf="((eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) == 1)
														&& (getCommentsLength(eventCard.joinEventCard) <= truncateCommentsLength || eventCard.joinEventCard.showMoreComments)"
														>
													<span *ngIf="eventCard.joinEventCard.crownComments.length == 1">
														{{eventCard.joinEventCard.crownComments[0]}}
													</span>
													<span *ngIf="eventCard.joinEventCard.documentText.length == 1">
														{{eventCard.joinEventCard.documentText[0]}}
													</span>
												</p>
												<p *ngIf="((eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) == 1)
														&& (getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength && !eventCard.joinEventCard.showMoreComments)"
														class="noMarginBottom">
													<span *ngIf="eventCard.joinEventCard.crownComments.length == 1">
														{{eventCard.joinEventCard.crownComments[0].substring(0, truncateCommentsLength) + 
															(eventCard.joinEventCard.crownComments[0].length > truncateCommentsLength ? "..." : "")}}
													</span>
													<span *ngIf="eventCard.joinEventCard.documentText.length == 1">
														{{eventCard.joinEventCard.documentText[0].substring(0, truncateCommentsLength) + 
															(eventCard.joinEventCard.documentText[0].length > truncateCommentsLength ? "..." : "")}}
													</span>
												</p>
												
												<ul *ngIf="(eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) > 1
													&& (getCommentsLength(eventCard.joinEventCard) <= truncateCommentsLength || eventCard.joinEventCard.showMoreComments)" class="noMarginTopBottom">
													<li *ngFor="let crownComment of eventCard.joinEventCard.crownComments">
														{{ crownComment }}
													</li>
													<li *ngFor="let docText of eventCard.joinEventCard.documentText">
														{{ docText }}
													</li>
												</ul>
												<ul *ngIf="(eventCard.joinEventCard.crownComments.length + eventCard.joinEventCard.documentText.length) > 1
													&& (getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength && !eventCard.joinEventCard.showMoreComments)" class="noMarginTopBottom">
													<li *ngFor="let crownComment of eventCard.joinEventCard.crownComments">
														{{ crownComment.substring(0, truncateCommentsLength) + 
															(crownComment.length > truncateCommentsLength ? "..." : "")}}
													</li>
													<li *ngFor="let docText of eventCard.joinEventCard.documentText">
														{{ docText.substring(0, truncateCommentsLength) + 
															(docText.length > truncateCommentsLength ? "..." : "")}}
													</li>
												</ul>
												<div *ngIf="getCommentsLength(eventCard.joinEventCard) > truncateCommentsLength" (click)="eventCard.joinEventCard.showMoreComments = !eventCard.joinEventCard.showMoreComments">
													<u>Show
														<span>{{eventCard.joinEventCard.showMoreComments ? 'less' : 'more'}}</span>
														</u>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="docketJoinHistory == null || docketJoinHistory?.eventCards.length == 0">
									<h3><span style="font-weight: bold">No past appearances</span></h3>
								</div>

								<!-- for debugging purpose -->
								<div *ngIf="displayJoinEventHistoryLocations.length > 0" class="uk-hidden"> 
								<div>
									{{docketJoinHistory?.docketNumber}} - JOIN events
									<table>
										<tr>
											
											<th>Date</th>
											<th>Event Code</th>
											<!-- <th>Type</th> -->
											<th>Adjourned by</th>
											<th>Reason Code</th>
										</tr>
										<tr *ngFor="let event of docketJoinHistory?.events">
											
											<td>{{event.adjournToDate | adjDateFormat}}</td>
											<td>{{event.code}}</td>
											<!-- <td>{{event.type}}</td> -->
											<td>{{event.adjournedBy}}</td>
											<td>{{event.adjournmentReasonCode}}</td>
										</tr>
									</table>
								</div>
								<br/>
								<div>
									{{docketJoinHistory?.docketNumber}} - JOIN event appearances
									<table>
										<tr>
											<th>index</th>
											<th>Date</th>
											<th>Room</th>
											<th>Type</th>
											<th>Time</th>
											<th>Court</th>
											
											<th>Actions</th>
											
										</tr>
										<tr *ngFor="let event of docketJoinHistory?.eventAppearances; let idx = index">
											<td>{{ordinal_suffix_of(idx+1)}} Appearance</td>
											<td>{{event.fromDate | adjShortDateFormat}}</td>
											<td>{{event.room}}</td>
											<td>{{event.type}}</td>
											<td>{{event.time}}</td>
											<td>{{event.court}}</td>
											
											<td>{{event.actions}}</td>
										</tr>
									</table>
								</div>
								<br/>
								<div>
									JOIN file history: {{docketJoinHistory?.docketNumber}}
									<table>
										<tr>
											<th>index</th>
											<th>Date</th>
											<th>Room</th>
											<th>Appearance Type</th>
											<th>Actions/Events</th>
											<th>Adjourned by</th>
											<th>Adjournment reason code</th>
											
										</tr>
										<tr *ngFor="let eventCard of docketJoinHistory?.eventCards; let idx = index">
											<td>{{ordinal_suffix_of(idx+1)}} Appearance</td>
											<td>{{eventCard.eventDate | adjShortDateFormat}}</td>
											<td><!-- Appearance Summary -->
												<span *ngFor="let courtRoomItem of eventCard.joinEventCard.courtRooms">{{courtRoomItem.courtRoom}}
													<span *ngFor="let chargeCount of courtRoomItem.chargeCounts">{{chargeCount}}
													</span>
												</span>
											</td>
											<td>
												<span *ngFor="let appearanceTypeItem of eventCard.joinEventCard.appearanceTypes">{{getAppearanceTypeDescription(appearanceTypeItem.appearanceType)}}
													<span *ngFor="let chargeCount of appearanceTypeItem.chargeCounts">{{chargeCount}} 
													</span>
												</span>
											</td>
											
											<td>
												<span *ngFor="let actionItem of eventCard.joinEventCard.actions">{{getEventActionDescription(actionItem.action)}}
													<span *ngFor="let chargeCount of actionItem.chargeCounts">{{chargeCount}}
													</span>
												</span>
											</td>
											<td>{{getAdjournmentByDescription(eventCard.joinEventCard.adjournedBy)}}</td>
											<td>{{getAdjournmentReasonCode(eventCard.joinEventCard.adjournmentReasonCode)}}</td>
										</tr>
									</table>
								</div>
								</div>
							</div>
							
						</div>
						<div class="cardSectionTopActive">
						</div>
						<div class="detailSection">
							
								<div class="uk-padding-small" style="padding-bottom: 0px">
								<div class="caseHistory">
								<h3 class="noMarginBottom">File details - {{ activeDocket }}</h3>
								<p style="margin-top: 5px;margin-bottom: 20px;">{{ details?.accusedFullName }} </p>
								</div>
									<div *ngIf="displayJoinEventHistoryLocations.length > 0"
										class="floatRight">
										<button mat-raised-button class="button-secondary mat-raised-button-custom" type="button" uk-toggle="target: #offcanvas-usage">
											JOIN file history
										</button>
										
							</div>
							<div>
								<table class="uk-table uk-table-divider margin-top-20">
									<tbody>
									<tr>
										<td class="uk-table-shrink header-primary">
											<mat-icon class="material-icons-outlined">info_outlined</mat-icon>
										</td>
										<td class="header-info-background callout-info-content">
										<p>
											Only adjournment requests processed via the Adjournment Digital Service are displayed below. Select ‘JOIN file history’ to review events and past appearances in JOIN.
										</p>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
							<div style="padding: 10px; clear: both;">
								<!-- Hisotry line here-->
								<ul class="timeline">
									<li class="timeline-item">
										<div class="timeline-info">
											<span>Estimated first appearance</span>
											<p>{{ firstAppearanceDate | adjShortDateFormat}}</p>
										</div>
										<div class="timeline-marker">
											<!-- <p>0</p> -->
										</div>

										<div class="timeline-content">
											<!-- <h3 class="timeline-title">Event Title</h3> -->
											<!-- <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
										eu pede mollis pretium. Pellentesque ut neque.</p> -->

											
											
										</div>
									</li>
									<li class="timeline-item" *ngFor="let adj of history; let i = index; let last = last">
										<div class="timeline-info">
											<form [formGroup]="historyForm">
												<div>
													<strong>
														<!-- {{ ordinal_suffix_of(i + 1) }} -->
														{{ adj.statusID == 1 ? 'Upcoming appearance' : 'Appearance'}}
													</strong>
													<p *ngIf="adj.decisionID === 12002"> {{ adj.decisionDate | adjShortDateFormat }} </p>
													<p *ngIf="adj.decisionID !== 12002">{{adj.originalAppearanceDate | adjShortDateFormat}}</p>
												</div>
												<mat-accordion>
													<mat-expansion-panel [expanded]="last && adj.statusID == 1" 
														(opened)="openPanel(adj.decisionAdjournToDate ? adj.decisionAdjournToDate : adj.adjournToDate)" 
														(closed)="closePanel(adj.decisionAdjournToDate ? adj.decisionAdjournToDate : adj.adjournToDate)"
														[hideToggle]="last && adj.statusID == 1"
														[attr.data-cy]="'history-' + i">
														<mat-expansion-panel-header class="mat-expansion-panel-header-custom">
															<mat-panel-title>
																<div style="width: 80%;">
																	<div class="event-card-ads-header">
																		Adjournment request summary
																		<p *ngIf="adj.statusID == 1"
																			class="badgeWarning clearBadge">Pending</p>
																		<p *ngIf="adj.decisionID == 12002"
																			class="badgeSuccess clearBadge">Granted</p>
																		<p *ngIf="adj.decisionID == 12003"
																			class="badgeDanger clearBadge">Denied</p>
																		<p *ngIf="adj.statusID == 3"
																			class="badgePrimary clearBadge">Awaiting Confirm</p>
																		<p *ngIf="adj.statusID == 4"
																			class="badgePrimary clearBadge">Confirmed</p>
																		<p *ngIf="adj.statusID == 5"
																			class="badgeExpired clearBadge">Expired</p>
																		<ng-container *ngIf="adj.statusID !== 1" >
																		<p *ngFor="let crownElectionItem of adj.eventCard?.joinEventCard?.crownElections"
																			class="grayPill tooltip font-size-14"  matTooltip="{{crownElectionItem.processedDateTime | adjShortDateFormat}}"
																			>
																			<img src="/assets/images/check.svg"/>&nbsp;{{getCrownElectionDescription(crownElectionItem.crownElection)}}{{crownElectionItem.displayChargeCount}}
																		</p>
																		</ng-container>
																	</div>
																</div>
																<div *ngIf="last && adj.statusID == 1" class="floatRight createDate">
																	{{adj.createDate | adjDatetimeFormat2}}
																</div>
															</mat-panel-title>
															
														</mat-expansion-panel-header>
														<div  class="detailSection uk-padding-small" style="border-top:0px;"
															[class.event-card-background-not-last]="!last || adj.statusID !== 1">

															<div uk-grid class="uk-child-width-1-1 uk-grid-small">
																<div>
																	<table class="uk-width-1-1 uk-table event-card-table">
																		<thead>
																		  <tr class="trCustomHeaderReadOnly">
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Reason for adjournment</td>
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Capacity of support</td>
																		  </tr>
																		</thead>
																		<tbody>
																		  <ng-container>
																			<tr>
																			  <td class="event-card-table-td-label-value">{{ adj.reasonCode }}</td>
																			  <td class="event-card-table-td-label-value">
																				{{
																					adj.agentTypeId
																						| capacityOfCounsel
																							: adj.capacityOfCounselId
																				}}
																			  </td>
																			</tr>
																		  </ng-container>
																		</tbody>
																	</table>
																</div>
																<div *ngIf="adj.statusID !== 1" class="wrapNotes">
																	<p class="event-card-section-label">Court notes</p> <br />
																	<p class="event-card-section-label-value">{{ (adj.decisionNotes != null && adj.decisionNotes != '') ? adj.decisionNotes : 'None' }}</p>
																</div>
																<div *ngIf="adj.statusID !== 1">
																	<table class="uk-width-1-1 uk-table event-card-table">
																		<thead>
																		  <tr class="trCustomHeaderReadOnly">
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Requested by</td>
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Law firm or organization</td>
																		  </tr>
																		</thead>
																		<tbody>
																		  <ng-container>
																			<tr>
																			  <td class="event-card-table-td-label-value">{{ (adj.agentAgentTypeId === 13001 || adj.agentAgentTypeId === 13003) ? adj.counselFullName : (adj.agentAgentTypeId === 13002 ? (adj.agentLastName + ", " + adj.agentFirstName + " (legal assistant) / " + adj.counselFullName + " (lawyer)") : "" ) }}</td>
																			  <td class="event-card-table-td-label-value">{{ adj.lawfirm }}</td>
																			</tr>
																		  </ng-container>
																		</tbody>
																	</table>
																</div>
																<div class="wrapNotes margin-top-15">
																	<p class="event-card-section-label">Additional explanation for
																		adjournment</p><br />
																	<p class="event-card-section-label-value">{{ adj.reasonExplanation != '' ? adj.reasonExplanation : 'None' }}</p>
																</div>
																<!-- <div class="wrapNotes"
																	*ngIf="adj.specialInstructions !== ''">
																	<p class="event-card-section-label">Special requirements</p>
																	<br />
																	<p class="event-card-section-label-value">'{{ adj.specialInstructions }}</p>
																</div> -->
																<div *ngIf="adj.statusID === 1">
																	<table class="uk-width-1-1 uk-table event-card-table">
																		<thead>
																		  <tr class="trCustomHeaderReadOnly">
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Requested appearance date</td>
																			<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Estimated file duration if granted</td>
																		  </tr>
																		</thead>
																		<tbody>
																		  <ng-container>
																			<tr>
																			  <td class="event-card-table-td-label-value">{{ adj.adjournToDate | adjShortDateFormat }}</td>
																			  <td [class.redFont]="!historyItemValid(i, getNumberOfDays(adj.adjournToDate))" class="event-card-table-td-label-value"> {{ getNumberOfDays(adj.adjournToDate) }} days/ {{ getNumberOfWeeks(adj.adjournToDate) }} weeks 
																				<span *ngIf="!historyItemValid(i, getNumberOfDays(adj.adjournToDate))">(CMO timeout)</span></td>
																			</tr>
																		  </ng-container>
																		</tbody>
																	</table>
																</div>
																<!-- <div
																	*ngIf="adj.frenchTrial == true || adj.childFriendlyCourtroom == true || adj.interpreterRequired == true ">
																	<p class="event-card-section-label">Special requirements</p>
																	<br />
																	<div class="uk-margin-remove"
																		style="padding-left: 10px;">
																		<ul
																			class="uk-list uk-list-collapse uk-margin-remove-bottom">
																			<li *ngIf="adj.frenchTrial == true">French trial</li>
																			<li *ngIf="adj.childFriendlyCourtroom == true"> Child friendly courtroom</li>
																			<li *ngIf="adj.interpreterRequired == true"> Interpreter required: <strong>{{ adj.interpreterLanguage }}</strong> </li>
																		</ul>
																	</div>
																</div> -->
															
																<div *ngIf="adj.statusID == 1;">
																	<div><hr /></div>
																	<p class="sectionLabelCustom">Charge details</p>
																	<br/>
																	<table class="uk-width-1-1 uk-table uk-table-custom">
																		<thead>
																		  <tr class="trCustomHeader">
																			<td class="uk-table-td-custom">Charge(s)</td>
																			<td class="uk-table-td-custom">On file (JOIN)/Crown election (PRISM)</td>
																			<td class="uk-table-td-custom">Next appearance type
																				<mat-icon class="appearanceTypeIcon" 
																					matTooltip="The selected ‘Next appearance type’ will be updated in JOIN upon granting. No updates are made if the request is denied.">
																					info_outlined
																				</mat-icon>
																			</td>
																		  </tr>
																		</thead>
																		<tbody>
																		  <ng-container *ngFor="let item of currentDocketWithCharges;">
																			<tr *ngFor="let charge of item.charges; let isFirst = first; let idx = index;" class="trCustomBody">
																			  <td class="uk-table-td-custom" >
																				  <span matTooltip="{{charge.chargeActCode}} {{charge.chargeCode}} - {{charge.description}}"
																				  		matTooltipPosition="below">{{charge.chargeActCode}} {{charge.chargeCode}}</span>
																			  </td>
																			  <td class="uk-table-td-custom">
																				<mat-icon *ngIf="charge.crownElection == 'H' && (charge.crownElectionPrism == 'S' || charge.crownElectionPrism == 'I')" 
																					matTooltip="Election status will automatically update in JOIN once submitted"
																					class="crownElectionIcon">
																					error_outlined</mat-icon>
																				<mat-icon *ngIf="charge.crownElectionPrism === 'Not available'" matTooltip="PRISM is not available at this time. Crown election(s) cannot be retrieved."
																					class="notAvailableIcon">
																					warning_amber
																				</mat-icon>
																				<mat-icon *ngIf="charge.crownElectionPrism === 'Data error'" matTooltip="An error occurred while retrieving the PRISM Crown election."
																					class="notAvailableIcon">
																					warning_amber
																				</mat-icon>
																				  {{ charge.crownElection | crownElection }}/<span *ngIf="(charge.crownElectionPrism !== 'Not available')&&(charge.crownElectionPrism !== 'Data error')" matTooltip="{{ charge.crownElectionPrism === '' ? '' : 'PRISM election made on ' + (charge.crownElectionLastUpdateDate | adjDateDashTimeFormat)}}" >{{ charge.crownElectionPrism | crownElection }}</span><span *ngIf="(charge.crownElectionPrism === 'Not available')||(charge.crownElectionPrism === 'Data error')" class="notAvailable">{{ charge.crownElectionPrism | crownElection }}</span>
																				  <input type="hidden" value="{{ charge.crownElection | crownElection }}/{{ charge.crownElectionPrism | crownElection }}/{{ charge.crownElectionDisplay | crownElection }}">
																			  </td> 
																			  <td class="uk-table-td-custom">
																				<div [formGroup]="historyForm.controls['appearanceTypes']['controls'][idx]"
																				[ngClass]="(!historyForm.valid 
																					&& historyForm.controls['appearanceTypes']['controls'][idx].controls['appearanceType'].errors
																					&& historyForm.controls['appearanceTypes']['controls'][idx].controls['appearanceType'].touched
																					&& historyForm.controls['appearanceTypes']['controls'][idx].controls['appearanceType'].dirty) 
																					? 'error' : 
																						((historyForm.controls['appearanceTypes']['controls'][idx].controls['appearanceType'].value == '') ? 'defaultSelection' : '')">
																					<mat-form-field class="optionSmall">
																						<mat-select formControlName="appearanceType" class="appearanceType" tabindex="0"
																							(selectionChange)="onAppearanceTypeChange($event, charge)"
																							>
																							<mat-option class="defaultAppearanceType" value="">Please select type</mat-option>
																							<mat-option
																								[value]="l.meta1"
																								*ngFor="let l of appearanceTypesData;">
																								{{ l.meta1 + '-'  + l.value }}
																							</mat-option>
																							
																						</mat-select>
																						<!-- <mat-error class="goa-errorLabel">
																							<span>
																								Appearance type is required.</span>
																						</mat-error> -->
																					</mat-form-field>
																				</div>
																			  </td>
																			</tr>
																		  </ng-container>
																		</tbody>
																	  </table>
																</div>
																<div class="wrapNotes" *ngIf="adj.statusID == 1" [ngClass]="adj.prismDiversionEligibility ? (adj.prismDiversionEligibility === 'Not available' ? 'diversion-background-red' : 'diversion-background') : ''"
																	>
																	<p class="sectionLabelCustom diversion">
																		<ng-container *ngIf="adj.prismDiversionEligibility === 'Not available'">
																			<mat-icon class="notAvailableIcon">warning_amber</mat-icon>
																		</ng-container>
																		Diversion Eligibility:&nbsp;
																	
																		<span style="font-weight: normal">
																			{{ adj.prismDiversionEligibility | diversion }}
																		</span>
																	</p>
																</div>
																<div class="wrapNotes" *ngIf="adj.statusID == 1 && displayRACInstructions==='true'" [ngClass]="adj.prismRacNotes ? (adj.prismRacNotes === 'Not available' ? 'rac-notes-background-red' : 'rac-notes-background') : ''"
																	>
																	<p class="sectionLabelCustom rac-notes">
																		<ng-container *ngIf="adj.prismRacNotes === 'Not available'">
																			<mat-icon class="notAvailableIcon">warning_amber</mat-icon>
																		</ng-container>
																		Crown RAC instructions
																	</p>
																	<br/>
																	<p>
																			{{ adj.prismRacNotes ? 
																				(adj.prismRacNotes === 'Not available' ? 'PRISM is not available at this time.' : adj.prismRacNotes)
																				 : "None" }}
																				 <span class="lightGrey">{{ adj.prismRacNotesDateTime ? ("(" + (adj.prismRacNotesDateTime | adjDateDashTimeFormat) +")") : ""}}</span>
																	</p>
																</div>
																<div *ngIf="adj.statusID == 1">
																	<p class="sectionLabelCustom">Next courtroom</p><br />
																	<div class="uk-width-1-1">
																		<mat-form-field
																			class="uk-width-large goa-selectField">

																			<mat-select data-cy="nextCourtRoom"
																				formControlName="nextCourtRoom"
																				(selectionChange)="onNextCourtRoomChange($event)">
																				<mat-option value=""
																					data-cy="courtroom">--Select Court
																					Room--</mat-option>
																				<mat-option
																					[value]="l.courtRoomId.trim()"
																					[attr.data-cy]="'cmo-' + i"
																					*ngFor="let l of courtRooms;">{{
																					l.courtRoomId.trim() }}</mat-option>
																			</mat-select>
																		</mat-form-field>
																	</div>
																</div>
																<div *ngIf="adj.statusID !== 1 && adj.charges?.length > 0" class="charges">
																	<hr class="noMargin"/>
																	<table class="uk-width-1-1 uk-table uk-table-custom chargeTable">
																		<thead>
																		  <tr class="trCustomHeaderReadOnly">
																			<td class="uk-width-1-2 uk-table-td-custom" style="padding-bottom: 0px !important;">Charge & Crown election</td>
																			<td class="uk-width-1-2 uk-table-td-custom" style="padding-bottom: 0px !important;">Appearance type</td>
																		  </tr>
																		</thead>
																		<tbody>
																		  <ng-container>
																			<tr *ngFor="let charge2 of adj.charges; let isFirst = first; let idx = index;"
																				class="trCustomBodyReadOnly">
																			  <td class="uk-table-td-custom event-card-section-label-value" >{{charge2.chargeActCode}} {{charge2.chargeCode}} - {{charge2.crownElectionDisplay | crownElection}}</td>
																			  <td class="uk-table-td-custom event-card-section-label-value">
																				{{ charge2.appearanceType ? charge2.appearanceType + " - " + charge2.appearanceTypeDesc : '&#8212;' }}
																			  </td>
																			</tr>
																		  </ng-container>
																		</tbody>
																	</table>
																	<hr class="noMargin"/>
																</div>
																<div *ngIf="adj.statusID !== 1" class="noMargin">
																	<div class="event-card-first-column">
																		<p class="event-card-section-label">Requested appearance date</p>
																		<br />
																		<p class="event-card-section-label-value">{{ adj.adjournToDate | adjShortDateFormat  }}</p>
																	</div>
																	<div>
																		<p class="event-card-section-label">New appearance date</p>
																		<br />
																		<p class="event-card-section-label-value">
																			{{ adj.decisionAdjournToDate ? (adj.decisionAdjournToDate | adjShortDateFormat) : '&#8212;'  }}
																		</p>
																	</div>
																</div>
																<div *ngIf="adj.statusID !== 1">
																	<div class="event-card-first-column">
																		<p class="event-card-section-label">Requested received</p>
																		<br />
																		<p class="event-card-section-label-value">{{ adj?.createDate | adjDateDashTimeFormat  }}</p>
																	</div>
																	<div>
																		<p class="event-card-section-label">Decision date</p>
																		<br />
																		<p class="event-card-section-label-value">
																			{{ adj.decisionAdjournToDate ? (adj.decisionDate | adjDateDashTimeFormat) : '&#8212;'  }}
																		</p>
																	</div>
																</div>
																<div *ngIf="adj.statusID !== 1">
																	<div class="event-card-first-column">
																		<p class="event-card-section-label">Next courtroom</p>
																		<br />
																		<p class="event-card-section-label-value">{{ adj.nextCourtRoom  }}</p>
																	</div>
																	<div>
																		<p class="event-card-section-label">Decision by</p>
																		<br />
																		<p class="event-card-section-label-value">
																			{{ adj.decisionBy }} {{ adj.manualGrantDate != null ? "(Manually granted)" : ""}}
																		</p>
																	</div>
																</div>
																<div *ngIf="adj.decisionID == 12002">
																	<div class="uk-width-1-1">
																		<div class="uk-margin-remove"
																			style="padding-left: 10px;">
																			<app-pdf-generator [showLink]="true"
																				[keepTogetherClassSelectors]="'.docket-info, .next-appearance, .request-details, .disclaimers'"
																				[pageBreakClassSelectors]="'.page-break'">
																				<div class="link-style" pdf-gen-control>
																					<div>Endorsement Sheet</div>
																					<div>
																						<mat-icon>open_in_new</mat-icon>
																					</div>
																				</div>
																				<app-endorsement-sheet-container
																					[adjUid]="adj.uid" pdf-content>
																				</app-endorsement-sheet-container>
																			</app-pdf-generator>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</mat-expansion-panel>
												</mat-accordion>
												<div *ngIf="!dictPanelOpen[(adj.decisionAdjournToDate ? adj.decisionAdjournToDate.toString() : adj.adjournToDate.toString())]"
													class="detailSection uk-padding-small" style="border-top:0px;"
													[class.event-card-background-not-last]="!last || adj.statusID !== 1">
													
													<div uk-grid class="uk-child-width-1-1 uk-grid-small">
														<div>
															<table class="uk-width-1-1 uk-table event-card-table">
																<thead>
																  <tr class="trCustomHeaderReadOnly">
																	<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Reason for adjournment</td>
																	<td class="uk-width-1-2 event-card-table-td-label" style="padding-bottom: 0px !important;">Capacity of support</td>
																  </tr>
																</thead>
																<tbody>
																  <ng-container>
																	<tr>
																	  <td class="event-card-table-td-label-value">{{ adj.reasonCode }}</td>
																	  <td class="event-card-table-td-label-value">
																		{{
																			adj.agentTypeId
																				| capacityOfCounsel
																					: adj.capacityOfCounselId
																		}}
																	  </td>
																	</tr>
																  </ng-container>
																</tbody>
															</table>
														</div>
														<div class="wrapNotes">
															<p class="event-card-section-label">Court notes</p> <br />
															<p>{{ (adj.decisionNotes != null && adj.decisionNotes != '') ? adj.decisionNotes : 'None' }}</p>
														</div>
													</div>
												</div>
											</form>
										</div>
										
										<div
											[ngClass]="(adj.statusID !== 1 || historyItemValid(i, getNumberOfDays(adj.adjournToDate))) ? 'timeline-marker':'timeline-marker invalid'">
											<!-- <p [matTooltip]="daysSinceTooltip(getNumberOfDays(adj.adjournToDate))"
												matTooltipPosition="above">
												{{ getNumberOfDays(adj.adjournToDate) }}</p> -->
										</div>
										<div *ngIf="!last" class="timeline-content">
											<!-- <hr /> -->
										</div>

										<div *ngIf="last && details?.docketDecisions?.length >1"
											class="uk-text-center uk-padding-small uk-flex uk-flex-center">
											<ul class="uk-pagination">
												<li [class.uk-disabled]="activeDocketIndex <= 0">
													<div data-cy="previous-docket"
														(click)="changeActiveDocket(activeDocketIndex-1, $event);"
														class="clickable">
														<span class="uk-margin-small-right"
															uk-pagination-previous></span> Previous
													</div>
												</li>
												<li [class.inActivePage]="activeDocketIndex != idx"
													[class.activePage]="activeDocketIndex == idx"
													*ngFor="let docket of docketDetails; let idx = index;">
													<div (click)="changeActiveDocket(idx, $event);" class="clickable">
														{{idx + 1}}</div>
												</li>
												<li [class.uk-disabled]="activeDocketIndex == details?.docketDecisions?.length-1 || !historyForm.valid"
													class="uk-margin-auto-left">
													{{activeDocketIndex + 1}} of {{details?.docketDecisions?.length}}&nbsp;&nbsp;&nbsp;
													<div data-cy="next-docket"
														(click)="changeActiveDocket(activeDocketIndex+1, $event)"
														class="clickable displayInline">Next <span class="uk-margin-small-left"
															uk-pagination-next></span></div>
												</li>
											</ul>
										</div>
										
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="history && history[history.length - 1]?.statusID == 1 && ((activeDocketIndex == details?.docketDecisions.length-1) || keepDecisionOpen == true)">
					<div class="cardSectionTopActive">
					</div>
					<div class="detailSection">
						
						<div class="uk-padding-small">
							<h3 class="noMarginBottom">Adjournment request decision</h3>
						</div>
						<!-- ADJ Timeline Component-->
						<div class="uk-grid uk-grid-collapse uk-child-width-1-1 uk-padding-remove">
							<div class="uk-padding-small">

								<form [formGroup]="decisionForm">
									<div uk-grid class="uk-grid-collapse">
										<div class="uk-width-1-2@m uk-width-expand@s">
											<p><strong>Decision of adjournment request</strong></p>
											<div>
												<mat-button-toggle-group formControlName="grantDenyControl"
													(change)="selectDecision()">
													<mat-button-toggle data-cy="designation-of-counsel-yes" value="2"
														class="uk-width-small">Grant
													</mat-button-toggle>
													<mat-button-toggle data-cy="designation-of-counsel-no" value="3"
														class="uk-width-small">Deny
													</mat-button-toggle>
												</mat-button-toggle-group>
											</div>
										</div>
										<div class="uk-width-1-2@m uk-width-auto@s">

											<p><strong>Adjourn to date</strong></p>
											<div style="margin-top: -0.25em;">
												<mat-form-field *ngIf="details.locationId != 0" appearance="outline"
													class="datePicker">
													<input [min]="startDate" data-cy="adjournToDate" matInput
														[matDatepicker]="adjournToDatePicker" name="adjournToDate"
														formControlName="adjournToDateControl"
														[matDatepickerFilter]="scheduleFilter" />
													<mat-datepicker-toggle matSuffix [for]="adjournToDatePicker">
													</mat-datepicker-toggle>
													<mat-datepicker #adjournToDatePicker></mat-datepicker>
												</mat-form-field>
											</div>
										</div>
									</div>

									<div *ngIf="displayManualGrant" class="uk-width-1-1 checkboxManualGrant">
										<mat-checkbox formControlName="chkManualGrantControl" #checkboxManualGrant [disabled]="!enableManualGrant"  >
											<b>Manual grant</b> - I confirm that I have manually endorsed the matter(s) in this request in JOIN.
										</mat-checkbox><br/>
										<div *ngIf="!enableManualGrant"
											class="header-warning statusPill statusPill-custom">JOIN transaction is still processing. Manual grant will be available in {{ remainingTimeToEnableManualGrant | countdownTimer }} minutes.
										</div>
										<div *ngIf="enableManualGrantPDFGenerator">
											<app-pdf-generator 
												[genAndOpenInNewTab] = "grantSuccessful"
												[keepTogetherClassSelectors]="'.docket-info, .next-appearance, .request-details'" 
												[pageBreakClassSelectors]="'.page-break'">
												<app-endorsement-sheet-container [adjUid]="details?.uid" pdf-content> </app-endorsement-sheet-container>
											</app-pdf-generator>
										</div>
									</div>
									<div class="uk-width-1-1">
										<!--Reason-->
										<strong>Additional explanation for decision:</strong>
										<div class="uk-text-muted uk-display-inline uk-margin-left">
											(Sent to requester and/or accused)
										</div>
										<div>
											<mat-form-field appearance="outline" class="uk-width-1-1">
												<textarea matInput data-cy="txtDecison" placeholder=""
													class="uk-width-1-1" name="reason" rows="6"
													formControlName="decisionDetailControl" maxlength="500">
											  </textarea>
												<mat-hint align="end"
													[ngClass]="(decisionForm.controls.decisionDetailControl.value.length === 500)?'redFont':''">
													{{ decisionForm.controls.decisionDetailControl.value.length }}
													characters (maximum 500 characters)
												</mat-hint>
												<mat-error *ngIf="!decisionForm.controls.decisionDetailControl.valid">
													Details for denied decisions are required.
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div class="uk-text-center" style="margin-top:15px;">
										<button mat-raised-button class="button-primary uk-width-1-2@s" type="button"
											(click)="submitForm()" [disabled]="!decisionForm.valid || !enableSubmit"
											data-cy="buttonConfirm">
											Confirm
										</button>
										<div class="uk-text-italic" *ngIf="useJoinWrite() === true" )>Does not write to
											JOIN</div>
									</div>
								</form>
							</div>
						</div>
						<div class="uk-padding-small">&nbsp;</div>
					</div>
				</div>
				<div *ngIf="history && history[history.length - 1]?.statusID == 5">
					<div class="expiredContentTopBlueBar"></div>
					<div class="expiredContentMessage detailSection">
						<div>
							<h3>
								<mat-icon class="material-icons-outlined iconWarning">report_problem</mat-icon>
								&nbsp;Adjournment request has expired
							</h3>
						</div>
						<div>
							<ng-container *ngIf="!(details.acknowledgeExpireDate)">
								This adjournment request has past the day of appearance, and therefore the request has
								expired.
								By acknowledging the expiration, the adjournment request will move to the "Completed"
								tab.
								The requester and/or accused will not be notified of this action.
							</ng-container>
							<ng-container *ngIf="!(!(details.acknowledgeExpireDate))">
								This adjournment request has past the day of appearance, and therefore the request has
								expired.
								Counsel and/or accused will not be notified of this expiration.
							</ng-container>
						</div>
						<div *ngIf="!(details.acknowledgeExpireDate)" class="uk-text-center" style="margin-top:15px;">
							<button mat-raised-button class="button-primary uk-width-1-2@s" type="button"
								(click)="submitExpireAcknowledgement()">
								Acknowledge
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-expire-confirmation></app-expire-confirmation>
<app-deny-appearance-type-notice></app-deny-appearance-type-notice>

<div id="modalJoinErrors" class="uk-flex-modal" uk-modal>
	<div class="uk-modal-dialog uk-modal-body uk-padding-remove uk-margin-auto-vertical">
	  <div uk-grid class="uk-grid-collapse uk-grid-match">
		<div class="errorBlock uk-padding-small uk-width-auto">
		  <span class="material-icons">warning_amber</span>
		</div>
		<div class="uk-width-expand uk-padding-small">
		  <div>
			  <button class="uk-modal-close-default" type="button" uk-close></button>
		  </div>
		  <h3 class="uk-modal-title">Something went wrong when granting this request in JOIN</h3>
		  <p>
			An error occurred when adjourning the following matter(s) from Request ID <b>{{ details?.uid.substring(0,8).toUpperCase() }}</b>:
		  </p>
		  <div class="withPadding">
			  <div class="withPaddingHeader">JOIN error message</div>
			  <div class="withPaddingSection">
				<ul class="withNoMarginList" [innerHTML]="joinErrorMessages">
				</ul>
			  </div>
		  </div>
		  <p>
			<b>1. Troubleshooting in JOIN</b><br/>
			JOIN error: If you experience an error within ADS, please check the file in JOIN for any existing errors (displayed as ‘+’). If there are any existing errors in JOIN, ADS will not be able to write. Please ensure any errors such as additional FBFs or ADJs are removed before trying to grant again. 
		  </p>
		  <p class="withMarginBottom">
			<b>2. Manually granting request in ADS</b><br/>
			Selecting the checkbox available in the decision panel (only visible after a JOIN error has occurred) allows you to process the request in ADS and manually endorse it in JOIN. By using the manual grant feature, the requester and/or accused will still receive notifications that the request is granted. This includes any changes made and/or notes added to the request in ADS.
		  </p>
		</div>
		
	  </div>
	</div>
  </div>
