<div *ngFor="let item of items | slice: 0:sliceIndex" class="field-value">
	<ng-container *ngIf="!isDate">{{ item }}</ng-container>
	<ng-container *ngIf="isDate">{{ item | adjDateFormat }}</ng-container>
</div>
<div *ngIf="!isOpen && items && items.length > sliceIndex">
	<a (click)="onOpen()">
		<div class="expander-container">
			<div>View more</div>
			<div>
				<mat-icon>expand_more</mat-icon>
			</div>
		</div>
	</a>
</div>
<div *ngIf="isOpen">
	<div *ngFor="let item of items | slice: sliceIndex" class="field-value">
		<ng-container *ngIf="!isDate">{{ item }}</ng-container>
		<ng-container *ngIf="isDate">{{ item | adjDateFormat }}</ng-container>
	</div>
	<a (click)="onClose()">
		<div class="expander-container">
			<div>View less</div>
			<div>
				<mat-icon>expand_less</mat-icon>
			</div>
		</div>
	</a>
</div>
