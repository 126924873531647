

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { JoinResponseModel } from '@adj/generators/models/join-response.model';

import { MockServiceResultModel } from '@adj/common/models/mock-service-result-model';
import { RefreshModel } from '@adj/common/refresh-model';
import { CommandResult } from '../models/core-api/command-result';

@Injectable({
    providedIn: 'root'
})
export class MockService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('mock'));
    }

    
    public refresh(model: RefreshModel) : Observable<CommandResult<JoinResponseModel[]>> {
        return this.post('refresh', model);
    }

    public async refreshAsync(model: RefreshModel) : Promise<CommandResult<MockServiceResultModel>> {
        return await this.post<CommandResult<MockServiceResultModel>, RefreshModel>('refresh', model).toPromise();
    }    
}
