import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'crownElection'
})
export class CrownElectionPipe implements PipeTransform {

  transform(value: string): string {
    return this.crownElectionDescription(value);
  }


  crownElectionDescription(charge){
	
	let retVal = charge;
	switch(charge.toUpperCase()){

		case "I" : 
			retVal = "Indictable";
			break;
		case "H": 
			retVal = "Hybrid";
			break;
		case "S": 
			retVal = "Summary";
			break;
		case "NOT AVAILABLE": 
			retVal = "Not available";
			break;
		case "DATA ERROR": 
			retVal = "Data error";
			break;
		default:
			retVal = "None";
			break; 
	}

	return retVal;
}

}
