

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { CourtRoomsResponseModel } from '@adj/generators/models/court-rooms-response.model';
import { GetCourtRoomsRequestModel } from '@adj/generators/models/get-court-rooms-request.model';
import { CircuitLocationModel } from '@adj/generators/models/circuit-location.model';
import { GetCourtCircuitsRequestModel } from '@adj/generators/models/get-court-circuits-request.model';

@Injectable({
    providedIn: 'root'
})
export class CourtsService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('courts'));
    }

    
    public getCourtRooms(model: GetCourtRoomsRequestModel) : Observable<CourtRoomsResponseModel> {
        return this.get('getCourtRooms', model);
    }

    public async getCourtRoomsAsync(model: GetCourtRoomsRequestModel) : Promise<CourtRoomsResponseModel> {
        return await this.get<CourtRoomsResponseModel, GetCourtRoomsRequestModel>('getCourtRooms', model).toPromise();
    }

    
    public getCourtCircuits(model: GetCourtCircuitsRequestModel) : Observable<CircuitLocationModel> {
        return this.get('getCourtCircuits', model);
    }

    public async getCourtCircuitsAsync(model: GetCourtCircuitsRequestModel) : Promise<CircuitLocationModel> {
        return await this.get<CircuitLocationModel, GetCourtCircuitsRequestModel>('getCourtCircuits', model).toPromise();
    }

    
}
