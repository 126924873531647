<a *ngIf="!details.locked && !hasNoSecurityRoles && !hasNoSecurityRoleForLocation" [routerLink]="['/adjournment-detail/' + details.uid]">
	<div class="bigger-clickable-area">
		<div class="enabled-button-container">
			<div class="icon">
				<mat-icon [inline]="true">arrow_forward</mat-icon>
			</div>
		</div>
	</div>
</a>

<div *ngIf="details.locked && !hasNoSecurityRoles && !hasNoSecurityRoleForLocation" class="disabled-button-container">
	<div class="icon">
		<mat-icon [inline]="true" *ngIf="details.locked" matTooltip="{{'Locked by: ' + details.lockedBy}}">lock</mat-icon>
	</div>
</div>

<div *ngIf="hasNoSecurityRoles || hasNoSecurityRoleForLocation" class="disabled-button-container">
	<div class="icon-disabled">
		<mat-icon [inline]="true">arrow_forward</mat-icon>
	</div>
</div>
