<div class="banner">
    <div class="uk-section uk-container uk-container-medium">
        <h1 class="text-white">Adjourment request form</h1>
        <p>This online service allows defence counsel and self-represented litigants to request an adjournment; chnage the court date set by the court.</p>
    </div>
</div>
<div class="uk-section uk-container uk-container-medium">
   

    <div class="uk-section uk-section-small">   
            <h2 class="uk-text-bold">Request an electronic adjournment</h2>
            <div>
                Adjournment requester
            </div>                             
            
            <div>
                <mat-form-field>
                    <mat-label>Location</mat-label>
                    <mat-select (selectionChange)="location = $event.value" data-cy="ddlLocation">
                      <mat-option value="">--Select--</mat-option>
                      <mat-option value="Calgary">Calgary</mat-option>
                      <mat-option value="Drumheller">Drumheller</mat-option>
                      <mat-option value="Edmonton (Family Law)">Edmonton (Family Law)</mat-option>
                      <mat-option value="Edmonton (Justice or Master)">Edmonton (Justice or Master)</mat-option>
                      <mat-option value="Fort McMurray">Fort McMurray</mat-option>
                      <mat-option value="Grande Prairie">Grande Prairie</mat-option>
                      <mat-option value="High Level">High Level</mat-option>
                      <mat-option value="Lethbridge">Lethbridge</mat-option>
                      <mat-option value="Medicine Hat">Medicine Hat</mat-option>
                      <mat-option value="Peace River">Peace River</mat-option>
                      <mat-option value="Red Deer">Red Deer</mat-option>
                      <mat-option value="St. Paul">St. Paul</mat-option>
                      <mat-option value="Wetaskiwin">Wetaskiwin</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
    
    </div>

    <div class="uk-section uk-section-small">   
        <h2 id="section2" class="uk-text-bold">Electronic Adjournment locations</h2>
        <div>
           <p> The electronic adjournment service is available in all court locations that have a Court Management Office (CMO):</p>
            <ul>
                <li>Calgary</li>
                <li>Edmonton</li>
                <li>Fort MacMurry</li>
                <li>Fort Saskatchewan</li>
                <li>Grande Prairie</li>
                <li>Hinton</li>
                <li>Lethbridge</li>
                <li>Medicine Hat</li>
                <li>Peace River</li>
                <li>Red Deer</li>
                <li>St. Paul</li>
            </ul>
        </div>                              
    </div>

    <div class="uk-section uk-section-small">   
    <h1 id="section3" class="uk-text-bold">Request e-adjournment</h1>
    <div>
       <p> To request an electronic adjournment you have to fill out and submit the e-adjournment request form.</p>
       <p><button mat-icon-button class="uk-button uk-border-rounded uk-button-primary uk-display-inline-block">Request e-Adjournment <mat-icon>launch</mat-icon></button></p> 
       <p>To request an electronic adjournment online you have to fill out and submit the e-adjournment request form. Both self-represented litigans and defence counsel can request an adjournment if you meet the eligibility requirements. you or your representing counsel will be elligible to requetst a digital adjournment if:</p>
       <ul>
            <li>You have requested less than 3 adjournments in the past for this matter, or if</li>
            <li>Your court date has been pushed out less than 8 weeks from the origininal date stated on your "Notice to Appear"</li>
            <li>You request the digital adjournment more than 24 hours before your original court date </li>
            <li>You provide a valid reason to adjourn</li>
            <li>you have an active email address with which you can receive correspondence</li>
            <li>You have no record of misconduct with the courts</li>        
        </ul>

        <p>If you don't meeet the requirements for representing an e-adjournment, you may still request an adjournment via phone, email or fax to the court where the matter is listed. Please contact your court location about your options.</p>
        <div class="uk-margin-medium">
            <table class="uk-table uk-table-divider">            
                <tbody>
                    <tr>
                        <td class="uk-table-shrink header-primary"><mat-icon>information</mat-icon></td>
                        <td class="uk-background-muted">
                            <h1 class="uk-text-bold">Before you apply</h1>
                            <p>Please make sure you check the requirements to use the electronic adjournment service.</p>
                        </td>                    
                    </tr>                
                </tbody>
            </table>
        </div>

        <h2>Receive a request confirmation</h2>
        <p>
            Once you have submitted the e-adjournment request for you will receive a confirmation email and/or text message. 
            If you have not recevied a confirmation email after 1 hour of submitting our e-adjournment request form, we recommend you to contact the court office where you submitted the request.
        </p>
        <div class="uk-margin-medium">
            <table class="uk-table uk-table-divider">            
                <tbody>
                    <tr>
                        <td class="uk-table-shrink header-primary"><mat-icon>information</mat-icon></td>
                        <td class="uk-background-muted">
                            <h1 class="uk-text-bold">After you apply</h1>
                            <p>Your request will be processed within 1 business day.</p>
                        </td>                    
                    </tr>                
                </tbody>
            </table>
        </div>


        <h2>Approval of Request</h2>
        <p>
            The court location where you reuqested the adjournment has to approve the new court date. 
            Once the court has appoved your new court date, you will receive an email to confirm your new court date.
            If your request  has not been appoved after 2 business days, we recommend you to contact the court office where you submitted the request.
        </p>


        <h2>Confirm new court date</h2>
        <p>
            After the court has appoved your e-adjournment request, you will receive an email to notify you of the apporival of your new court date.
            This email will be sent to the email address you have provided in the request form.  To complete the rescheduling of your court date, you must click the "Approve my new court date" button in the email to confirm that you are aware of and agree with the new court date.
            By clicking this button you agree to appearing on the rescheduled date.
        </p>

        <div class="uk-margin-medium">
            <table class="uk-table uk-table-divider">            
                <tbody>
                    <tr>
                        <td class="uk-table-shrink header-warning"><mat-icon>warning</mat-icon></td>
                        <td class="uk-background-muted">
                            <h1 class="uk-text-bold">Confirm new court date</h1>
                            <p>
                                You are requested to appear in court on the original date until you have confirmed your new court date by clicing on the "Approve my new court date" button in the confirmation new hew court date email.
                            </p>
                        </td>                    
                    </tr>                
                </tbody>
            </table>
        </div>
    </div>                              
    </div>

    <div class="uk-section uk-section-small">   
        <h1 id="section4" class="uk-text-bold">Resources</h1>
        
        <div>
           <p> <a href="#">E-Adjournment Request Guide <mat-icon>get_app</mat-icon></a> (PDF, 1.0MB) - full details about the electronic adjournment request service.</p>            
        </div>                              
    </div>

    <div class="uk-section uk-section-small">   
        <h1 id="section5" class="uk-text-bold">Contact</h1>
        
        <div>
            <p>If you require assistance with the electronic adjournment request service contact the JSG-IMG Support Desk:</p>
            <p>Hours: 8:15 am to 4:30pm (Monday - Friday), closed statutory holidays)</p>
            <p>Phone: <a href="tel:780-427-6957">780-427-6957</a></p>            
            <p>Toll free: <a href="tel:310-0000">310-0000</a> before the phone number (in Alberta)</p>     
            <br>
            <p>Email: <a href="mailto:jsg-mit-supportdesk@gov.ab.ca">jsg-mit-supportdesk@gov.ab.ca</a></p>      
            <p>If you need to contact the court location of where your matter is listed to check the progress of your request or any questions
                related to the rescheduling a new court dtae, pllease find an overview of the contact information of al court locations in Alberta.
            </p>
            <p> <a href="#">Courts contact list <mat-icon>get_app</mat-icon></a> (PDF, 1.0MB) - overview of contact information of all Alberta court locations.</p>
        </div>                              
    </div>
</div>
