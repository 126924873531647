<table mat-table [dataSource]="confirmPendingDataSource" class="w-100">
	<!-- Name Column -->
	<ng-container matColumnDef="jpDecisionDate">
		<th mat-header-cell *matHeaderCellDef>
			<b>Adjournment review date</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.jpDecisionDate | adjDateFormat }}
		</td>
	</ng-container>

	<ng-container matColumnDef="jpDecision">
		<th mat-header-cell *matHeaderCellDef>
			<b>JP Decision</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-table-status-chip
				[adjournmentStatusId]="element.statusId"
				[adjournmentDecisionId]="element.decisionId"
			></app-table-status-chip>
		</td>
	</ng-container>

	<ng-container matColumnDef="decidingJp">
		<th mat-header-cell *matHeaderCellDef>
			<b>Deciding JP</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.decidingJp }}
		</td>
	</ng-container>

	<ng-container matColumnDef="docketNumber">
		<th mat-header-cell *matHeaderCellDef>
			<b>Docket number(s)</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-docket-numbers [docketNumbers]="element.docketNumbers"></app-docket-numbers>
		</td>
	</ng-container>

	<ng-container matColumnDef="accusedFullName">
		<th mat-header-cell *matHeaderCellDef>
			<b>Accused name</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.accusedFullName }}
		</td>
	</ng-container>

	<ng-container matColumnDef="daysUntilOriginalAppearance">
		<th mat-header-cell *matHeaderCellDef>
			<b>Days until original appearance</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.originalDate | daysUntil }}
		</td>
	</ng-container>

	<ng-container matColumnDef="details">
		<th mat-header-cell *matHeaderCellDef></th>
		<td
			mat-cell
			*matCellDef="let element"
			[attr.data-cy]="'detail-' + element.uid"
		>
			<app-table-details-button
				[details]="element"
			></app-table-details-button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onOpenQuickView(row)" class="clickable"></tr>
</table>
