export class AuthenticatedUser {
	token: string;
	sessionId: string;
	permissions: string[];
	roles: string[];
	username: string;
	displayName: string;
	firstName: string;
	lastName: string;
	email: string;
	isJustice: boolean;
	timestamp: any;
}
