

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { QueryResult } from '@adj/common/models/core-api/query-result';
import { SearchByDocketResponseModel } from '@adj/generators/models/search-by-docket-response.model';
import { JoinSearchRequestModel } from '@adj/generators/models/join-search-request.model';
import { SearchAccusedIdModel } from '@adj/generators/models/search-accused-id.model';
import { JoinGetChargeCodeResponseModel } from '@adj/generators/models/join-get-charge-code-response.model';
import { JoinGetChargeCodeRequestModel } from '@adj/generators/models/join-get-charge-code-request.model';
import { CourtRoomModel } from '@adj/generators/models/court-room.model';
import { IdModel } from '@adj/generators/models/id.model';
import { JoinGetUserSecurityResponseModel } from '@adj/generators/models/join-get-user-security-response.model';
import { JoinGetUserSecurityRequestModel } from '@adj/generators/models/join-get-user-security-request.model';

@Injectable({
    providedIn: 'root'
})
export class Join2Service extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('join2'));
    }

    
    public searchByDocket(model: JoinSearchRequestModel) : Observable<QueryResult<SearchByDocketResponseModel>> {
        return this.get('searchByDocket', model);
    }

    public async searchByDocketAsync(model: JoinSearchRequestModel) : Promise<QueryResult<SearchByDocketResponseModel>> {
        return await this.get<QueryResult<SearchByDocketResponseModel>, JoinSearchRequestModel>('searchByDocket', model).toPromise();
    }

    
    public searchForAccuseds(model: JoinSearchRequestModel) : Observable<QueryResult<SearchAccusedIdModel>> {
        return this.get('searchForAccuseds', model);
    }

    public async searchForAccusedsAsync(model: JoinSearchRequestModel) : Promise<QueryResult<SearchAccusedIdModel>> {
        return await this.get<QueryResult<SearchAccusedIdModel>, JoinSearchRequestModel>('searchForAccuseds', model).toPromise();
    }

    
    public getChargeCode(model: JoinGetChargeCodeRequestModel) : Observable<QueryResult<JoinGetChargeCodeResponseModel>> {
        return this.get('getChargeCode', model);
    }

    public async getChargeCodeAsync(model: JoinGetChargeCodeRequestModel) : Promise<QueryResult<JoinGetChargeCodeResponseModel>> {
        return await this.get<QueryResult<JoinGetChargeCodeResponseModel>, JoinGetChargeCodeRequestModel>('getChargeCode', model).toPromise();
    }

    
    public getCourtRoomsByLocationId(model: IdModel) : Observable<QueryResult<CourtRoomModel[]>> {
        return this.get('getCourtRoomsByLocationId', model);
    }

    public async getCourtRoomsByLocationIdAsync(model: IdModel) : Promise<QueryResult<CourtRoomModel[]>> {
        return await this.get<QueryResult<CourtRoomModel[]>, IdModel>('getCourtRoomsByLocationId', model).toPromise();
    }

    
    public getUserSecurity(model: JoinGetUserSecurityRequestModel) : Observable<QueryResult<JoinGetUserSecurityResponseModel>> {
        return this.get('getUserSecurity', model);
    }

    public async getUserSecurityAsync(model: JoinGetUserSecurityRequestModel) : Promise<QueryResult<JoinGetUserSecurityResponseModel>> {
        return await this.get<QueryResult<JoinGetUserSecurityResponseModel>, JoinGetUserSecurityRequestModel>('getUserSecurity', model).toPromise();
    }

    
}
