<!-- <h3 style="margin-bottom: 10px" mat-dialog-title>Submission Confirmation</h3>
<hr style="border-top-width: 2px; margin: 0px 0px 5px 0px" /> -->
<div mat-dialog-content style="max-height: 80vh !important;max-width: 725px">
	<div>
		<h3 style="margin-bottom: 5px">
			You are about to
			<strong>{{ decision?.statusId == 2 ? "grant" : "deny" }}</strong>
			adjournment request ID {{ decision?.uid | shortAdjUid }}.
		</h3>
		<h4 style="margin-bottom: 20px">
			Once submitted, the requester and/or accused will be notified of your decision.
		</h4>
		
			<table *ngIf="decision.isManualGrant"
				class="uk-table uk-table-divider goa-section uk-table-custom noMarginTop">
				<tbody>
				<tr> 
					<td class="uk-table-shrink header-warning morePadding">
					<mat-icon>error_outlined</mat-icon>
					</td>
					<td class="header-warning-background morePadding">
					<p>
						You have selected to manually grant this request, you hereby confirm that you have manually endorsed the matter(s) in this request in JOIN, including the Crown election(s).
						<!-- You have selected to manually grant this request, you hereby confirm that you have manually endorsed the matter(s) in this request in JOIN. -->
					</p>
					</td>
				</tr>
				</tbody>
			</table>
			<table *ngIf="decision.isManualGrant" 
				class="uk-table uk-table-divider goa-section uk-table-custom noMarginTop">
				<tbody>
				<tr>
					<td class="uk-table-shrink header-primary morePadding">
						<mat-icon class="material-icons-outlined iconWarning">info_outlined</mat-icon>
					</td>
					<td class="header-info-background morePadding">
					<p>
						The requester and/or accused will still receive notifications that the request is granted. This includes any changes made and/or notes added to the request in ADS.
					</p>
					</td>
				</tr>
				</tbody>
			</table>
			<table *ngIf="displayNotAvailableCrownElectionMsg"
				class="uk-table uk-table-divider goa-section uk-table-custom noMarginTop">
				<tbody>
				<tr> 
					<td class="uk-table-shrink header-danger morePadding">
					<mat-icon>warning_amber</mat-icon>
					</td>
					<td class="header-danger-background morePadding">
					<p>
						PRISM Crown election(s) are not available at this time. No updates to the Crown election(s) will be made in JOIN.
					</p>
					</td>
				</tr>
				</tbody>
			</table>
			<!-- display only if denied -->
			<table *ngIf="displayAutoUpdateCrownElectionMsg && decision?.statusId == 3" 
				class="uk-table uk-table-divider goa-section uk-table-custom noMarginTop">
				<tbody>
				<tr>
					<td class="uk-table-shrink header-warning morePadding">
						<mat-icon>error_outlined</mat-icon>
					<!-- <td class="uk-table-shrink header-primary morePadding">
						<mat-icon class="material-icons-outlined iconWarning">info_outlined</mat-icon> -->
					</td>
					<td class="header-warning-background morePadding" style="width:660px">
					<p>
						Crown election(s) are not automatically updated in JOIN when a request is denied.
						<!-- Updates to the Crown election status will automatically write to JOIN once the adjournment request has been submitted. -->
					</p>
					</td>
				</tr>
				</tbody>
			</table>
		<div class="uk-container noPaddingLeft maxWidth">
			<!-- <div class="summaryHeader">Summary:</div> -->
			
			
			<div class="uk-grid-row-small uk-child-width-1-1 detailSection">				
				<!-- <div *ngIf="decision?.statusId == 2" class="uk-padding-small uk-padding-remove-bottom">
					<h3 class="uk-display-inline">
						<strong>Adjournment requested</strong>
						<span> to {{ decision?.adjournToDate |adjDateFormat }}</span>
					</h3>
				</div> -->


				<table class="uk-table uk-table-small">
					<thead>
						<tr>
							<th class="firstColumnHeader">Accused name</th>
							<th class="">Date of birth</th>								
						</tr>
					</thead>
					<tr>
						<td class="firstColumnHeader">{{ decision?.accusedFullName }}</td>
						<td class="">{{ decision?.accusedDOB | adjDateFormat }}</td>
					</tr>
				</table>

				

				<p class="uk-padding-small uk-padding-remove-vertical">Adjournment details</p>	
				<div class="uk-padding-small uk-padding-remove-vertical">
					<div class="uk-background-muted ">

						<table class="summaryTable uk-table uk-table-small">											
							<thead>
								<tr>
									<th>Docket number(s)</th>
									<th>Upcoming appearance date(s)</th>
									<th>Adjourn to date</th>
									<th>Next courtroom</th>
								</tr>
							</thead>
							<tr *ngFor="let docket of decision?.summaryDockets; let isFirst = first;">
								<td>{{ docket?.docketNumber }}</td>
								<td *ngIf="isFirst">{{ docket?.requestedAppearanceDate | adjDateFormat }}</td>
								<td *ngIf="!isFirst">{{ docket?.requestedAppearanceDate | adjDateFormat }}</td>
								<td>{{ decision?.statusId == 2 ? (decision?.adjournToDate | adjDateFormat) : "&#8212;" }}</td>
								<td>{{ docket?.requestedCourtRoomId}}</td>												 
							</tr>
						</table>
					</div>
				</div>
				
				<table class="uk-table uk-table-small">
					<thead>
						<tr>
							<th class="firstColumnHeader">Reason for adjournment</th>
							<th class="">Capacity of support</th>								
						</tr>
					</thead>
					<tr>
						<td class="firstColumnHeader">{{ reasonText }}</td>
						<td class="">{{
							decision.agentTypeId
								| capacityOfCounsel
									: decision.capacityOfCounselId
						}}</td>
					</tr>
				</table>

				<div class="uk-padding-small-custom">
					<p>Additional explanation for adjournment</p>					
					<span>{{ decision?.explanation ? decision?.explanation : "None" }}</span>
				</div>


				<div  *ngIf="decision?.specialInstructions" class="uk-padding-small">
					<p>Special Requirements</p>
					<span>{{ decision?.specialInstructions }}</span>
				</div>

				<p class="uk-padding-small uk-padding-remove-vertical">Charge details</p>	
				<div class="uk-padding-small uk-padding-remove-vertical">
					<div class="uk-background-muted divChargeDetails">

						<table class="summaryTable uk-table uk-table-small chargesTable">											
							<thead>
								<tr>
									<!-- <th class="chargeDetails">Docket number</th> -->
									<th class="headerColumnChargeDetails">Charge(s)</th>
									<th class="headerColumnChargeDetails">Crown election</th>
									<th class="headerColumnChargeDetails">Appearance type</th>
								</tr>
							</thead>
							<ng-container *ngFor="let docket of decision?.summaryDockets; let isFirst = first;">
								<tr *ngFor="let charge of docket.charges; let isFirstCharge = first;" [ngClass]="(isFirstCharge)?'separatorByDocket':''">
								
									<!-- <td *ngIf="isFirstCharge"><b>{{ docket?.docketNumber }}</b></td>
									<td *ngIf="!isFirstCharge"> </td> -->
									<td class="columnChargeDetails"><b *ngIf="isFirstCharge" class="docketNum">{{ docket?.docketNumber }}<br /></b>{{ charge.chargeActCode}} {{charge.chargeCode}}</td>
									<td class="columnChargeDetails">
										<mat-icon *ngIf="charge.crownElection == 'H' && (charge.crownElectionPrism == 'S' || charge.crownElectionPrism == 'I') && (decision?.statusId == 2 && !decision.isManualGrant)" 
											matTooltip="Automatically updated in JOIN once submitted"
											class="crownElectionIcon">
											autorenew</mat-icon>
										<mat-icon *ngIf="charge.crownElection == 'H' && (charge.crownElectionPrism == 'S' || charge.crownElectionPrism == 'I') && (decision?.statusId == 3 || decision.isManualGrant)" 
											matTooltip="Election status will not update in JOIN."
											class="crownElectionIcon">
											cancel</mat-icon>
										{{ charge.crownElectionDisplay | crownElection }}</td>
									<td class="columnChargeDetails">{{ decision?.statusId == 2 ? charge.appearanceType + " - " + getAppearanceTypeDescription(charge.appearanceType) : '&#8212;' }}</td>										 
								</tr>
								
							</ng-container>
							
						</table>
					</div>
				</div>

				<div class="uk-padding-small-custom2">
					<p>Court notes</p>				
					<span>{{ decision?.jpDecisionNotes ? decision?.jpDecisionNotes : "None"  }}</span>
				</div>

				<div class="uk-padding-small">
					
					<p *ngIf="decision?.statusId == 2" class="badgeSuccess clearBadge" style="color: white">Granted</p>
					<p *ngIf="decision?.statusId == 3" class="badgeDanger clearBadge" style="color: white">Denied</p>
					<p *ngIf="decision?.statusId != 2 && decision?.statusId != 3" class="badgePrimary clearBadge" style="color: white">Other</p>
					<span class="uk-margin-remove"><strong> by {{ user?.displayName }}</strong>, on {{ decision.jpDecisionDate | adjDateFormat }} at {{ decision.jpDecisionDate | date: "h:mm a" | lowercase }}</span>
				</div>
				<div>&nbsp;</div>
			</div>
			<div mat-dialog-actions class="mat-dialog-actions-custom">
				<button mat-raised-button [mat-dialog-close]="false" data-cy="btnCancel" [disabled]="buttonsDisabled">
					<strong>Cancel</strong>
				</button>
				<button
				data-cy="btnSubmit"
					mat-raised-button
					(click)="onDecisionSubmit($event)"
					(dblclick)="decisionDoubleClick($event)"
					class="button-primary"
					cdkFocusInitial
					[disabled]="buttonsDisabled"
				>
					<strong *ngIf="decision.statusId == 2">Submit and Print</strong>
					<strong *ngIf="decision.statusId != 2">Submit</strong>
				</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="grantSuccessful==true">
	<app-pdf-generator 
	[genAndOpenInNewTab] = "printNow"
	[keepTogetherClassSelectors]="'.docket-info, .next-appearance, .request-details'" 
	[pageBreakClassSelectors]="'.page-break'"
	(afterRender)="afterPdfRenders()">
		<app-endorsement-sheet-container [adjUid]="decision.uid" pdf-content> </app-endorsement-sheet-container>
	</app-pdf-generator>
</div>
