export class Constants {

	public static Settings = {

		// Console-driven settings that dynamically apply business rules in one or both of the submission / clerk applications
		// Alberta Notify
		NotifyEnabled: "notify_enabled",

		NotifyApiKey: "notify_apikey",

		NotifyTemplateEmailReminder: "notify_emailremindertemplateid",

		NotifyTemplateEmailSubmit: "notify_emailsubmittemplateid",

		NotifyTemplateEmailGrant: "notify_emailgranttemplateid",

		NotifyTemplateEmailGrantConfirm: "notify_emailgrantconfirmtemplateid",

		NotifyTemplateEmailReject: "notify_emailrejecttemplateid",

		NotifyTemplateTextReminder: "notify_textremindertemplateid",

		NotifyTemplateTextSubmit: "notify_textsubmittemplateid",

		NotifyTemplateTextGrant: "notify_textgranttemplateid",

		NotifyTemplateTextGrantConfirm: "notify_textgrantconfirmtemplateid",

		NotifyTemplateTextReject: "notify_textrejecttemplateid",

		NotifyTemplateConfirmAccusedUrl: "notify_variableconfirmaccusedurl",

		// Clerk-related
		ClerkAvailableLocations: "clerk_available_locations",
		JoinEventHistoryLocations: "join_event_history_locations",
		DisplayRACInstructions: "display_rac_instructions",
		ClerkUserGuideDocumentName: "ADS-Clerk-Guide.pdf",

		// Submisison-related
		UseSkeletonLoader: "use_skeleton_loader",

		SelectionCutoffDays: "selection_cutoff_days",

		SubmissionAvailableLocations: "submission_available_locations",

		SubmissionOffsetDays: "submission_offset_days",

		AdjournmentReasonsSubmissionApp: "adjournment_reasons_submission_app",

		// System Messaging
		UrgentMessageSubmissionApp: "urgent_message_submission_app",
		WarningMessageSubmissionApp: "warning_message_submission_app",
		InfoMessageSubmissionApp: "info_message_submission_app",

		UrgentMessageClerkApp: "urgent_message_clerk_app",
		WarningMessageClerkApp: "warning_message_clerk_app",
		InfoMessageClerkApp: "info_message_clerk_app",
		MaintenanceMessageClerkApp: "maintenance_message_clerk_app",

		// // R3/R4 settings -- TO Be deprecated / removed once we complete R4
		// UseR3Write: "use_r3_write",

		// // default functionality is false => USE R4
		// UseR3Read: "use_r3_read",

		// default functionality is false => USE R4
		ImpersonateGoaUser: "impersonate_goa_user",

		// not to be used in production
		ImpersonateCassUser: "impersonate_cass_user",

		//PIPE-delimited list of specific AdjournmentIDs (First 8 chars)  that can be used to test R4 integration while preserving R3 write functionality enabled
		ApprovedR4Adjournments: "approved_r4_adjournments",

		// job related settings
		CutoffHourForExpiredStatus: "cutoffhour_for_expired_status",

		ManualGrantAdjournments: "manual_grant_adjournments",

		SubmissionAppRedirectUrl: "submission_app_redirect_url",
		ClerkAppRedirectUrl: "clerk_app_redirect_url",
	}

	public static AgentType = {
		Lawyer: "lawyer",
		LegalAssistant: "assistant"
	}

	public static AgentTypeId = {
		Lawyer: 13001,
		LegalAssistant: 13002,
		Agent: 13003,
	}

	public static CounselTypeId = {
		DutyCounsel: 10000,
		DefenseCounsel: 10001,
		OtherAgent: 10002,
	}
	public static CapacityOfCounselId = {
		Retained: 11000,
		NotRetained: 11001,
	}
	public static CapacityOfCounselText = {
		DutyCounselNotRetained: "Duty Counsel - this appearance only",
		DefenceCounselNotRetained: "Defence Counsel - this appearance only",
		DefenceCounselRetained: "Defence Counsel - retained",
		AgentNotRetained: "Agent",
	}

	public static DecisionStatusIds = {
		Grant: 2,
		Reject: 3
	}

	public static AdjournmentStatusId = {
		Submitted: 1,
		ReviewCompleted: 2,
		ReviewAwaitingConfirm: 3,
		ReviewConfirmed: 4,
		Expired: 5
	}

	public static ServiceName =
		{
			Auth: "Auth",
			ClerkApp: "ClerkApp",
			ConsoleApp: "ConsoleApp",
			EmailConfirmationLink: "EmailConfirmationLink",
			Join: "Join",
			JoinLegacy: "JoinLegacy",
			Mock: "Mock",
			ScheduledJob: "ScheduledJob",
			SubmissionApp: "SubmissionApp",
			UnknownApp: "UnknownApp"
		}


	public static Cookies = {
		UserFilteredLocations: "_USER_FILTERED_LOCATIONS"
	}


}
