import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	constructor() {}

	public composeErrorMessage(errorResult): string {
		var errorMsg = '';
		if (errorResult.error.errors) {
			var errorMsgs = '';
			var errorObj = errorResult.error.errors;
			for (var prop in errorObj) {
				if (Object.prototype.hasOwnProperty.call(errorObj, prop)) {
					console.log(errorObj[prop]);

					errorMsgs += errorObj[prop] + '\r\n';
				}
			}

			errorMsg = errorMsgs;
		} else {
			errorMsg = errorResult.message;
		}

		return errorMsg;
	}
}
