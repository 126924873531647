import { EnvConfigService } from '@adj/common/services/env-config.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard {


	constructor(
		protected router: Router,
		protected keycloak: KeycloakService,
		protected authenticationService: AuthenticationService,
		protected enviroService: EnvConfigService
	) {
		super(router, keycloak);
		
	}

	isAccessAllowed(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		return new Promise(async (resolve, reject) => {
			
			//check keycloak authenticated property and if we don't have access token we need to make call to keycloak login
			if (!this.authenticated) {
				this.keycloakAngular.login({
					redirectUri: this.enviroService.get("url") + state.url,
					idpHint: "core"
				});
				resolve(false);
			}
			else{
			
				//Role access check
				//Business rule: User is logged in at this point, however, if a user doesn't have ads-clerk role we want to redirect to my-account
				var syncResult = await this.authenticationService.sync(state);
				resolve(syncResult);
			}
		});
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		return this.isAccessAllowed(next,state);
	}
}
