import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from './common/models/helpers/ui-constants';
import { GetSettingByKeyRequestModel } from './generators/models/get-setting-by-key-request.model';
import { SettingsService } from './generators/services/settings.service';
import { VersionService } from './generators/services/version.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {

	version$: Observable<string>;
	environment$: Observable<string>;


	constructor(private router: Router,
		private titleService: Title,		
		private settingsService:SettingsService,
		@Inject(DOCUMENT) private document) {
			
			this.router.events.subscribe(async (e) => {
				if (e instanceof NavigationEnd) {
					// Function you want to call here
					let request = new GetSettingByKeyRequestModel({
						key: Constants.Settings.ClerkAppRedirectUrl
					});
			
					var settingsResults = await settingsService.getSettingByKeyAsync(request);
					if(settingsResults.isSuccess){
						//split the value into existing:new urls
						let values = settingsResults.payload.value.split('|');
						let sourceUrl = values[0];
						let redirecturl = values[1];
		
						//if the current app is in the source value...go to the redirect page with the proper redirectUrl value set
						if(sourceUrl.toLowerCase() === document.location.host.toLowerCase() && document.location.href.indexOf('app-redirect') === -1){							
							this.router.navigateByUrl(`/app-redirect?redirectUrl=${redirecturl}`);
							
						}
					}
		
				}
			 });			
	}

	public setTitle(newTitle: string) {
		//this.titleService.setTitle(newTitle);
	}

	
}
