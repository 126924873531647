import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MaintenanceModel } from '@adj/shared/models/maintenance.model';

@Component({
	selector: 'goa-system-unavailable',
	templateUrl: './system-unavailable.component.html',
	styleUrls: ['./system-unavailable.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemUnavailableComponent {
	@Input() maintenance: MaintenanceModel;
}
