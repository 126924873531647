import { EnvConfigService } from '@adj/common/services/env-config.service';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';
import { UserLastLoginUpdateModel } from '@adj/generators/models/user-last-login-update.model';
import { UserService } from '@adj/generators/services/user.service';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { last } from 'rxjs/operators';
import { AuthenticatedUserProfileKeycloakModel } from '../models/authenticationed-user-keycloak.model';

@Injectable({
  providedIn: 'root'
})

///<summary>
///Authentication service is used to update the user profile upon signin from Keycloak
///The update method is called from the AuthGuard only, and once this is called, AuthenticationService can be used within anywhere in the application to get the user context (aka AuthenticatedUserProfileKeycloakModel)
///</summary>
export class AuthenticationService {
	private excludeRoles: string[] = ["view-realm","view-identity-providers","manage-identity-providers","impersonation","realm-admin","create-client","manage-users","query-realms","view-authorization","query-clients","query-users","manage-events","manage-realm","view-events","view-users","view-clients","manage-authorization","manage-clients","query-groups","manage-account","manage-account-links","view-profile","offline_access","uma_authorization"];
	private user:AuthenticatedUserProfileKeycloakModel;

  	constructor(
		protected enviroService: EnvConfigService,
		private keycloakService:KeycloakService,
		private cookieService:CookieService,
		private userService:UserService,
		private router: Router
		) { 
		
	}

	///<summary>
	///Sync is intended to be called from the authguard once authentication has occurred, acting as a callback/event to then do everything else we need to do related to having a token
	///1. Get name and roles from bearer token
	///2. Get keycloakUserId 
	///3. Check business rules and role access
	///</summary>
	public async sync(state):Promise<boolean>
	{
		return new Promise((resolve, reject) => {
		
			var user = new AuthenticatedUserProfileKeycloakModel();
			
			//retrieve the UserProfile and roles from KeyCloak and pass into updateUserSession on AuthService
			this.keycloakService.loadUserProfile(true).then((profile) => {
			
				//exclude default keycloak roles
				var allRoles = this.keycloakService.getUserRoles();
				var roles = new Array<string>(); 
				allRoles.forEach(role => {
					if (!this.excludeRoles.includes(role))
						roles.push(role)
				});

				user.firstName = profile.firstName;
				user.lastName= profile.lastName;
				user.displayName = `${profile.firstName[0]} ${profile.lastName}`;
				user.email= profile.email;
				user.userRoles=roles;

			}).then(async()=>{
				//await new Promise(f => setTimeout(f, 1000));  //simulate a long delay from keycloak 
				//only way to get KeycloakUserId
				let instance = this.keycloakService.getKeycloakInstance();
				var data = await instance.loadUserInfo();
				user.keycloakUserId = data["sub"]; //actual keycloak UserId
				this.user = user;
			
			}).then(async()=>{
				//role/access checks
				await this.isRoleAllowed(state);
			}).then(async()=>{
				//update the users last login date on a 5 minute basis (as required); we definitely don't want to do this every route activation so it is implemented to do it every 5 mins
				await this.updateLastLoginDate();
			}).finally(()=>{
				resolve(true);
			})
			.catch(error=>{
				console.log(error);
				reject(error);
			});
		});
	}

	///<summary>
	///Get current user keycloak model
	///</summary>	
	public getCurrentUser():Promise<AuthenticatedUserProfileKeycloakModel>
	{
		return new Promise((resolve, reject) => {
			if (this.user){
				resolve(this.user);
			}
			else{
				reject('Current user is null');
			}
		});
	}
	

	///<summary>
	///Logout kills identity provider token and uses redirectUri to come back to app
	///</summary>
	public logout() {
		var redirectUri = this.enviroService.get("url") + 'home';
		this.keycloakService.clearToken();
		return this.keycloakService.logout(redirectUri);
	}

	///<summary>
	///Add other role workflows here only, it will be invoked by the AuthGuard everytime a isAccessCheck is done on a route
	///</summary>
	private async isRoleAllowed(state: RouterStateSnapshot){
		
		if (state.url == '/users/my-account') return;
		
		var roles = this.keycloakService.getUserRoles();
		//User is logged in at this point, however, if a user doesn't have ads-clerk role we want to redirect to my-account
		if (!roles.includes('ads-clerk')){
			this.router.navigate(['/users/my-account']);
		}
	}

	private async updateLastLoginDate(){
		try
		{
			//If an existing cookie exists that hasn't yet expired than we know agent 
			var lastLoginDate = this.cookieService.get("adj-app-agent-last-login-date");
			if (lastLoginDate) return;

			var date = new Date();
			date.setTime(date.getTime() + (5* 60 * 1000)); //set expiry for 5 minutes from now
			this.cookieService.set("adj-app-agent-last-login-date",Date(),date);
			await this.userService.updateUserLastLoginDateAsync(new UserLastLoginUpdateModel({keycloakUserId:this.user.keycloakUserId}));
		}
		catch(error)
		{
			console.log(error);
			//supress error as we can't allow this to break primary promise chain in .sync method but we do want to know if it is not happening
		}
	}
}
