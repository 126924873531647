


export class JoinSearchRequestModel {
    public accusedId: string = '';
    public docketNumber: string[] = [];
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public dateOfBirth: string = '';
    public locationID: number;
    public isTest: boolean;
    public includeTickets: boolean = false;
    public isCompany: boolean = false;

    constructor(init? : Partial<JoinSearchRequestModel>) {
        
        Object.assign(this, init);
    }
}
