import { Constants } from '@adj/common/models/helpers/ui-constants';
import { GetDocumentByKeyRequestModel } from '@adj/generators/models/get-document-by-key-request.model';
import { DocumentService } from '@adj/generators/services/document.service';

import { Component, Inject, OnInit } from '@angular/core';

@Component({
	selector: 'help-contact-us',
	templateUrl: './help-contact-us.component.html',
	styleUrls: ['./help-contact-us.component.scss'],
})
export class HelpContactUsComponent {
	constructor(
		private documentService: DocumentService,
		@Inject('window') private $window: Window
	) {}

	async showClerkGuide() {
		const data = await this.documentService
			.getDocumentUrl(
				new GetDocumentByKeyRequestModel({
					DocumentKey: Constants.Settings.ClerkUserGuideDocumentName,
				})
			)
			.toPromise();

		this.$window.open(data.output.url);
	}
}
