


export class GetAdjournmentReasonsCommandRequestModel {
    public docketType: string = '';

    constructor(init? : Partial<GetAdjournmentReasonsCommandRequestModel>) {
        
        Object.assign(this, init);
    }
}
