

import { DocketDecisionModel } from './docket-decision.model';

export class AdjournmentModel {
    public id: number = 0;
    public uid: string = '';
    public locationId: number = 0;
    public accusedID: string = '';
    public accusedKey: string = '';
    public accusedFirstName: string = '';
    public accusedMiddleName: string = '';
    public accusedLastName: string = '';
    public accusedEmail: string = '';
    public accusedDateOfBirth: Date;
    public accusedMobilePhone: string = '';
    public accusedReceiveSms: boolean;
    public skipAccusedContactInfo: boolean;
    public agentUid: string;
    public agentFirstName: string = '';
    public agentLastName: string = '';
    public agentEmail: string = '';
    public agentPhone: string = '';
    public agentReceiveSms: boolean;
    public counselFullName: string = '';
    public lawFirm: string = '';
    public counselMobilePhone: string = '';
    public counselReceiveSms: boolean;
    public counselEmail: string = '';
    public docketDecisions: DocketDecisionModel[] = [];
    public originalAppearanceDate: Date = new Date();
    public reasonCode: number = 0;
    public adjournToDate: Date = new Date();
    public reasonExplanation: string = '';
    public specialInstructions: string = '';
    public confirmAllCharges: boolean;
    public joinref: string = '';
    public adjournmentGroupUid: string = '';
    public adjournmentStatusId: number = 0;
    public capacityOfCounselId: number;
    public lockTime: Date;
    public lockedBy: string = '';
    public adjournmentDecisionId: number = 0;
    public decisionAdjournToDate: Date;
    public decisionNotes: string = '';
    public confirmDate: Date;
    public createDate: Date = new Date();
    public createdBy: string = '';
    public lastUpdateDate: Date = new Date();
    public lastUpdatedBy: string = '';
    public capacityOfCounsel: string = '';
    public accusedFullName: string = '';
    public status: string = '';
    public reason: string = '';
    public agentTypeId: number = 0;
    public agentType: string = '';
    public acknowledgeExpireJp: string = '';
    public acknowledgeExpireDate: Date;
    public joinTransactionStartTime: Date;
    public joinTransactionEndTime: Date;
    public joinTransactionErrorTime: Date;
    public joinTransactionErrorMsg: string = '';
    public isTest: boolean;

    constructor(init? : Partial<AdjournmentModel>) {
        
        Object.assign(this, init);
    }
}
