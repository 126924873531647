export class HttpObjectMapper {
	//Recursively traverse an object or array
	public static mapObjectFromServer<TOutput>(entity: TOutput): TOutput {
		if (!entity) return entity;

		const objectKeys = Object.keys(entity) as Array<string>;

		for (let key of objectKeys) {
			let value = entity[key];

			if (typeof value === 'object' && value !== null) {
				this.mapObjectFromServer.call(this, value);
			}

			entity = this.handleCustomTypeMapping(entity, key, value);
		}
		return entity;
	}

	//Handle any mapping necessary to maintain type correctness etc
	public static handleCustomTypeMapping<TOutput>(
		entity: TOutput,
		fieldName: string,
		fieldValue: any
	): TOutput {
		if (fieldName.endsWith('Date') || fieldName.endsWith('DateTime')) {
			if (fieldValue) {
				//These input fieldValue values MUST be in ISO 8601 format, otherwise the client locale will be used to shift the time on instantiation!
				var fromUtcToLocal = new Date(fieldValue);
				entity[fieldName] = fromUtcToLocal;
			}
		}

		return entity;
	}

	public static mapObjectToServer<TOutput>(entity: TOutput): TOutput {
		if (!entity) return entity;

		const objectKeys = Object.keys(entity) as Array<string>;

		for (let key of objectKeys) {
			let value = entity[key];

			entity = this.handleToServerCustomTypeMapping(entity, key, value);

			if (typeof value === 'object' && value !== null) {
				this.mapObjectToServer.call(this, value);
			}
		}
		return entity;
	}

	public static handleToServerCustomTypeMapping<TOutput>(
		entity: TOutput,
		fieldName: string,
		fieldValue: any
	): TOutput {
		let copiedUnfrozenObject = Object.assign({}, entity);

		if (fieldName.endsWith('Date') || fieldName.endsWith('DateTime')) {
			if (fieldValue && fieldValue['_d']) {
				var utcDate = fieldValue['_d'].toISOString();
				copiedUnfrozenObject[fieldName] = new Date(utcDate);
			}
		}

		return copiedUnfrozenObject;
	}
}
