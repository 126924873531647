import { AdjournmentSummaryModel } from '@adj/generators/models/adjournment-summary.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-docket-numbers',
	templateUrl: './docket-numbers.component.html',
	styleUrls: ['./docket-numbers.component.scss'],
})
export class DocketNumbersComponent implements OnInit {
	@Input() docketNumbers: string[];
	
	constructor() {}

	ngOnInit(): void {}
}
