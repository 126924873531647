


export class LocationSettingsModel {
    public id: number = 0;
    public locationId: number = 0;
    public openTime: string;
    public closeTime: string;
    public schedulePattern: string = '';
    public maxAdjAllowed: number;
    public maxAdjPeriod: number;
    public createDate: Date = new Date();
    public createdBy: string = '';
    public lastUpdateDate: Date = new Date();
    public lastUpdatedBy: string = '';

    constructor(init? : Partial<LocationSettingsModel>) {
        
        Object.assign(this, init);
    }
}
