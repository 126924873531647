import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'daysUntil',
})
export class DaysUntilPipe implements PipeTransform {
	transform(value: Date, ...args: unknown[]): string | Date {
		if (value) {
			var currentDate = new Date();

			var diff = value.getTime() - currentDate.getTime();
			var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

			if (diffDays <= 0 && diffDays > -1) {
				return 'Today';
			}
			else if (diffDays <= -1) {
				return 'Past';
			}
			return diffDays.toString();
		}

		return value;
	}
}
