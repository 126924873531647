import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvConfigService } from '@adj/common/services/env-config.service';
import { EndPointService } from '@adj/common/services/end-point.service';
import { CodeType } from '@adj/common/models';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CodesService extends EndPointService {
	constructor(http: HttpClient, private envConfig: EnvConfigService) {
		super(http, envConfig.get('codes'));
	}

	async getAdjournmentReasons() {
		return await this.get('reasons').toPromise();
	}

	async getCodeTestScenarios() {
		return await this.get('getcodetestscenarios').toPromise();
	}
}
