import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'adjDateFormat'
})
export class AdjDateFormatPipe implements PipeTransform {

	transform(adjDate: Date, uppercase = false): string {
		if (adjDate != null) {

			let retVal: string;
			if (uppercase)
				retVal = moment(adjDate).format("MMMM DD, YYYY").toUpperCase();
			else
				retVal = moment(adjDate).format("MMMM DD, YYYY");
			return retVal;
		}
		else
			return "N/A";
	}

}
