


export class GetCourtRoomsRequestModel {
    public locationId: number = 0;

    constructor(init? : Partial<GetCourtRoomsRequestModel>) {
        
        Object.assign(this, init);
    }
}
