import { AdjournmentSummaryModel } from '@adj/generators/models/adjournment-summary.model';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-table-details-button',
	templateUrl: './table-details-button.component.html',
	styleUrls: ['./table-details-button.component.scss'],
})
export class TableDetailsButtonComponent implements OnInit {
	@Input() details: AdjournmentSummaryModel;
	@Input() hasNoSecurityRoles:Boolean = true;
	@Input() hasNoSecurityRoleForLocation:Boolean=true;
	@Input() currentUser: AuthenticatedUserProfileKeycloakModel;
	constructor() {}

	ngOnInit(): void {
	

	}
}
