import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CommandResult } from '@adj/common/models/core-api/command-result';
import { LogRequestModel } from '@adj/generators/models/log-request.model';
import { LogResponseModel } from '@adj/generators/models/log-response.model';

@Injectable({
	providedIn: 'root',
})
export class LogsService extends EndPointService {
	constructor(http: HttpClient, config: EnvConfigService) {
		super(http, config.get('logs'));
	}

	public async logErrorAsync(
		model: LogRequestModel
	): Promise<CommandResult<LogResponseModel>> {
		return await this.post<
			CommandResult<LogResponseModel>,
			LogRequestModel
		>('logError', model).toPromise();
	}

	public async logInfoAsync(
		model: LogRequestModel
	): Promise<CommandResult<LogResponseModel>> {
		return await this.post<
			CommandResult<LogResponseModel>,
			LogRequestModel
		>('logInfo', model).toPromise();
	}
}
