import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { SettingsService } from '@adj/generators/services/settings.service';
import { Constants } from '@adj/common/models/helpers/ui-constants';


import { CookieService } from 'ngx-cookie-service';
import { Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';


@Component({
	selector: 'app-app-banner',
	templateUrl: './app-banner.component.html',
	styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit, OnDestroy {

	//subscriptions
	infoPollingData: Subscription;
	warningPollingData: Subscription;
	urgentPollingData: Subscription;

	//banner messages
	urgentMessage: string = "";
	infoMessage: string = "";
	warningMessage: string = "";
	
	settings: SettingsModel[];
	errors: any;

	constructor(private SettingsService: SettingsService, private cookieService: CookieService) { }


	ngOnInit() {		
		const myTimer = timer(0, 90000); //setup timer to poll every 90 seconds
		
		let urgentrequest = new GetSettingByKeyRequestModel({
			key: Constants.Settings.UrgentMessageClerkApp
		});
		
		this.urgentPollingData = myTimer.pipe(			
			mergeMap(() => this.SettingsService.getSettingByKey(urgentrequest))
		).subscribe(result => {		
			this.urgentMessage = this.getBlockMessage(Constants.Settings.UrgentMessageClerkApp,result?.payload?.value?.trim());
		});


		let warningrequest = new GetSettingByKeyRequestModel({
			key: Constants.Settings.WarningMessageClerkApp
		});
		this.warningPollingData = myTimer.pipe(			
			mergeMap(() => this.SettingsService.getSettingByKey(warningrequest))
		).subscribe(result => {
			this.warningMessage = this.getBlockMessage(Constants.Settings.WarningMessageClerkApp,result?.payload?.value?.trim());
		});


		let inforequest = new GetSettingByKeyRequestModel({
			key: Constants.Settings.InfoMessageClerkApp
		});
		this.infoPollingData = myTimer.pipe(			
			mergeMap(() => this.SettingsService.getSettingByKey(inforequest))
		).subscribe(result => {
			this.infoMessage = this.getBlockMessage(Constants.Settings.InfoMessageClerkApp,result?.payload?.value?.trim());
		});

	}

	ngOnDestroy(): void {
		//unsubscribe from all 
		this.infoPollingData.unsubscribe();
		this.warningPollingData.unsubscribe();
		this.urgentPollingData.unsubscribe();
	}


	closeBanner(banner: string, messageText: string) {
		
		switch (banner) {
			case "urgent":
				this.setCookieMessage(Constants.Settings.UrgentMessageClerkApp,messageText); 
				this.urgentMessage  = "";
				break;

			case "warning":
				this.setCookieMessage(Constants.Settings.WarningMessageClerkApp,messageText); 
				this.warningMessage = "";
				break;

			case "info":
				this.setCookieMessage(Constants.Settings.InfoMessageClerkApp,messageText); 
				this.infoMessage = "";
				break;
		}

		//write the message to the cookies
		
	}

	//this gets any values that are already saved in the cookies for a particular type of message
	getCookieMessage(cookieName: string): string {

		let retVal = "";
		var existingMessage = this.cookieService.check(cookieName);
		if (existingMessage) {
			retVal = this.cookieService.get(cookieName).trim()
		}

		return retVal.trim();
	}

	getBlockMessage(banner: string, messageText: string) {
		let retVal = "";
		let existingCookie = this.getCookieMessage(banner);
		if (existingCookie == '' || existingCookie != messageText) {
			retVal = messageText; //return the new banner text
			
		}

		return retVal;
	}

	setCookieMessage(cookieName: string, messageText: string) {
		this.cookieService.set(cookieName, messageText.trim());
	}

}
