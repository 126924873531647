<section  class="uk-section uk-padding-remove">
	<div class="uk-section uk-container uk-container-medium">
		<div style="font-size: 36px; margin-bottom: 40px">
			Digital Adjournment Data Scenario Chooser
        </div>
     </div>  
     <div class="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport="offset-top: true; offset-bottom:150px;">

    <div class="uk-child-width-1-1" uk-grid >
        <div class="uk-margin-remove-horizontal">               
            <div>
                Scenarios
            </div>                             
            
            <div class="uk-width-1-1">
                <mat-form-field class="uk-width-1-1">
                    <mat-select  data-cy="ddlScenario" value="" (selectionChange)="changeScenario($event)">
                        <mat-option value="">--Select--</mat-option>
                        <mat-option 
							*ngFor="let scenario of scenarios"
							[value]="scenario">
							{{ scenario }}
						</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
    
    </div>
    
    <div class="uk-text-center">               
            
            <button uk-toggle="target: #modalConfirmReset"
            mat-raised-button
            class="button-primary uk-width-medium"
            type="button" 
            data-cy="btnResetMockData"            
        >
            Reset Data
        </button>
    </div>
    </div>
        
    
</div>  


<div id="modalConfirmReset" class="uk-flex-top" uk-modal data-cy="modalConfirm">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <h2 class="uk-modal-title">Resetting the JP Admin Data</h2>
        <p>You are going to reset the JP Admin to use <strong> {{scenarioName }}</strong></p>
        <p>Do you want to continue?</p>
        <p class="uk-text-right">
            <button mat-raised-button class="uk-modal-close" data-cy="btnCancelConfirm">
                <strong>Cancel</strong>
            </button>
            &nbsp;
            <button 
            mat-raised-button
            class="button-primary"
            type="button" 
            data-cy="btnConfirmResetMockData" 
            (click)="refreshData()"           
        >
         Confirm Reset
        </button>
        </p>
    </div>
</div>



