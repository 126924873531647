import { Constants } from '@adj/common/models/helpers/ui-constants';
import { AdjournmentModel } from '@adj/generators/models/adjournment.model';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';

import { IdModel } from '@adj/generators/models/id.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { UidModel } from '@adj/generators/models/uid.model';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { LocationsService } from '@adj/generators/services/locations.service';
import { AuthSessionService } from '@adj/services/auth-session.service';
import { ErrorHandlerService } from '@adj/services/error-handler.service';
import { MessageNotificationService } from '@adj/services/message-notification.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.scss'],
})
export class QuickViewComponent implements OnInit {
	@Input() set selectedAdjournmentUid(value: string) {
		this.getAdjournmentDetails(value);
	}

	@Output() closeQuickView: EventEmitter<void> = new EventEmitter<void>();


	currentUser: AuthenticatedUserModel;

	adjournmentDetails: AdjournmentModel;
	location: LocationModel;

	docketNumbers: string[] = [];
	requestedAppearanceDates: Date[] = [];

	constructor(
		private adjournmentService: AdjournmentsService,
		private locationService: LocationsService,
		private authService: AuthSessionService,
		private messageService: MessageNotificationService		
	) {}

	ngOnInit() {
		this.currentUser = this.authService.currentUser;
	}

	determineCounsel(){
		if (this.adjournmentDetails){
			if (this.adjournmentDetails.agentTypeId == Constants.CounselTypeId.DutyCounsel 
				&& this.adjournmentDetails.capacityOfCounselId == Constants.CapacityOfCounselId.NotRetained){
				return Constants.CapacityOfCounselText.DutyCounselNotRetained;
			}
			if (this.adjournmentDetails.agentTypeId == Constants.CounselTypeId.DefenseCounsel 
				&& this.adjournmentDetails.capacityOfCounselId == Constants.CapacityOfCounselId.NotRetained){
				return Constants.CapacityOfCounselText.DefenceCounselNotRetained;
			}
			if (this.adjournmentDetails.agentTypeId == Constants.CounselTypeId.DefenseCounsel 
				&& this.adjournmentDetails.capacityOfCounselId == Constants.CapacityOfCounselId.Retained){
				return Constants.CapacityOfCounselText.DefenceCounselRetained;
			}
			if (this.adjournmentDetails.agentTypeId == Constants.CounselTypeId.OtherAgent 
				&& this.adjournmentDetails.capacityOfCounselId == Constants.CapacityOfCounselId.NotRetained){
				return Constants.CapacityOfCounselText.AgentNotRetained;
			}
		}

		return '';
	}

	async getAdjournmentDetails(uid) {
		if (uid) {
			var adjResult = await this.adjournmentService.getAdjournmentDetailAsync(
				new UidModel({ uid })
			);

			if(adjResult.isSuccess){
				this.adjournmentDetails = adjResult.payload;
				this.docketNumbers = this.adjournmentDetails.docketDecisions.map(x=>x.docketNumber);
				this.requestedAppearanceDates = this.adjournmentDetails.docketDecisions.map(x=>x.requestedAppearanceDate);
				
				var locResult = await this.locationService.getLocationAsync(
					new IdModel({ id: this.adjournmentDetails.locationId })
				);
	
				if(locResult.isSuccess){
					this.location = locResult.payload;
				}
				else{
					this.messageService.showErrorMessage("An error occured getting location details", locResult.errors[0], false);
				}
				
			}
			else{
				//display error message
				let errorMessage = adjResult.errors[0];
				this.messageService.showErrorMessage("An error occured getting adjournment details", errorMessage, false);

			}			
		}
	}

	onCloseQuickView() {
		this.closeQuickView.emit();
	}
}
