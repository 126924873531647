<div class="uk-container uk-container-medium uk-padding-small uk-padding-remove-horizontal">
	<div class="uk-grid-collapse uk-width-1-1" uk-grid>
		<div class="uk-width-1-1">
			<a href="/">
				<button mat-raised-button class="button-secondary uk-margin-remove">
					<mat-icon>arrow_back</mat-icon> Back to Dashboard
				</button></a>
		</div>
	</div>
</div>

<form [formGroup]="userSettingsForm" (submit)="saveSettings()" class="uk-width-1-1" *ngIf="(userSettings$) && (this.location$);">
	<div class="uk-flex uk-flex-center uk-flex-middle " uk-height-viewport="offset-top: true; offset-bottom: 100px">
		<div uk-grid class="uk-text-left uk-grid-column-collapse uk-grid-row-small component-border uk-child-width-1-1 uk-padding-large uk-width-1-4@l uk-width-auto">
			<div>
				<h1>User Settings</h1>
			</div>
			<div>
				<table class="uk-table uk-margin-remove uk-table-divider">
					<tbody>
						<tr>
							<td class="uk-table-shrink header-warning">

								<mat-icon>error_outlined</mat-icon>
							</td>
							<td class="uk-background-muted">								
								<p>
									Your CASS/JOIN User ID starts with a * and contains 8 characters or less (e.g.
									*BROWND). It can be found in the event history of a file endorsed via CASS, and it
									is also known by your manager/supervisor. It is not the same as your JOIN pin.
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div>
				<p class="formLabel">CASS/JOIN User ID:</p>
				<div>
					<mat-form-field appearance="outline">
						<input data-cy="joinPin" matInput name="joinPin" formControlName="joinPin"
							style="text-transform: uppercase;" />
						<mat-error class="goa-errorLabel" *ngIf="userSettingsForm.controls.joinPin.invalid">
							<span *ngIf="userSettingsForm.controls.joinPin.errors.required">CASS/JOIN User ID is required.</span>
							<span *ngIf="userSettingsForm.controls.joinPin.errors.pattern">Invalid CASS/JOIN User ID.</span>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div>
				<p class="formLabel">Court location</p>
				<div>
					<mat-form-field class="goa-selectField">
						<mat-select data-cy="location" formControlName="courtLocationId">
							<!-- Default Value set in .ts -->
							<mat-option *ngFor="let location of location$" [(value)]="location.id"
								[attr.data-cy]="'location-' + location.id">{{ location.name }}</mat-option>
						</mat-select>
						<mat-error class="goa-errorLabel" *ngIf="userSettingsForm.controls.courtLocationId.invalid">
							<span *ngIf="userSettingsForm.controls.courtLocationId.errors.min">Court location is
								required.</span>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
			<div class="uk-margin-medium-top uk-margin-small-bottom">
				<button mat-raised-button data-cy="btnSubmit" class="button-primary uk-width-1-1" type="submit">
					Save
				</button>
			</div>
		</div>
	</div>
</form>