import { EnvConfigService } from '@adj/common/services/env-config.service';
import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function keyCloakInitializer(
	configService: EnvConfigService,
	keycloak: KeycloakService
): any {
	let result = configService.load().then((result) => {
		var keycloakConfig = configService.get('keycloakConfig');

		const options: KeycloakOptions = {
			config: keycloakConfig,
			initOptions: {
				checkLoginIframe: false,
			},
			bearerExcludedUrls: ['/assets', 'amazonaws.com'],
		};

		return keycloak.init(options);
	});

	return () => result;
}
