import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import * as UIkit from 'uikit';

@Component({
	selector: 'app-deny-appearance-type-notice',
	templateUrl: './deny-appearance-type-notice.component.html',
	styleUrls: ['./deny-appearance-type-notice.component.scss'],
})
export class DenyAppearanceTypeNoticeComponent implements OnInit {

	async ngOnInit(){ 
		
	}


	async showModal(){
		
		UIkit.modal("#deny-appearance-type-prompt").show();
	}

	hideModal(){
    UIkit.modal("#deny-appearance-type-prompt").hide();
	}
	
}
