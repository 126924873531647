import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'matchHighlight',
})
export class MatchHighlightPipe implements PipeTransform {
	constructor(private _sanitizer: DomSanitizer) {}

	transform(value: string, term: string): unknown {
		if (value && term) {
			var highlightedTermRegexMatch = "<strong>$&</strong>";
			var rgx = new RegExp(term, "gi");
			var strWithHighlight = value.replace(rgx, highlightedTermRegexMatch);

			return this._sanitizer.bypassSecurityTrustHtml(strWithHighlight);
		}

		return value;
	}
}
