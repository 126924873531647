

import { ChargeModel } from './charge.model';

export class DocketDecisionModel {
    public docketNumber: string = '';
    public requestedCourtRoomId: string = '';
    public originalCourtRoomId: string = '';
    public requestedAppearanceDate: Date = new Date();
    public charges: ChargeModel[] = [];

    constructor(init? : Partial<DocketDecisionModel>) {

        Object.assign(this, init);
    }
}
