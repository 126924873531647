


export class JoinGetUserSecurityRequestModel {
    public joinUserId: string = '';

    constructor(init? : Partial<JoinGetUserSecurityRequestModel>) {
        
        Object.assign(this, init);
    }
}
