import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'countdownTimer'
})
export class CountdownTimerPipe implements PipeTransform {

  transform(timeInSeconds: number): string {

	if (timeInSeconds < 0)
		return "00:00";
	let dateString = new Date(timeInSeconds * 1000).toISOString();

	return dateString.substr(14, 5);
  }

}
