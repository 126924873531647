import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'adjDateDashTimeFormat'
})
export class AdjDateDashTimeFormatPipe implements PipeTransform {

  transform(adjDate: Date): string {
    if (adjDate < new Date(2000, 1, 1, 0, 0, 0, 0)) //for cases when date is set to min date
      return "-";

    return moment(adjDate).format("MMM DD, YYYY - h:mm a");
  }

}
