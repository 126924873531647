


export class ChargeModel {
    public id: string = '';
    public fileAccusedChargeUniqueKey: string = '';
    public docketNumber: string = '';
    public chargeCode: string = '';
    public description: string = '';
    public crownElection: string = '';
    public crownElectionPrism: string = '';
    public crownElectionDisplay: string = '';
    public appearanceType: string = '';
    public appearanceTypeDesc: string = '';
    public createDate: Date = new Date();
    public chargeActCode: string = '';
    public appearanceCourtId: string = '';
    public filedAtCourtId: string = '';
    public agencyFileId: string = '';

    constructor(init? : Partial<ChargeModel>) {
        
        Object.assign(this, init);
    }
}
