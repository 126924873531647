<section>
	<div class="uk-container uk-container-medium">
		<div class="uk-section-small uk-flex uk-flex-middle uk-flex-center">
			<div uk-grid class="uk-width-large@m uk-width-1-1@s uk-grid-collapse" style="border:solid 1px #dcdcdc; padding: 56px 54px;">
				<h1 class="uk-width-1-1 uk-text-bold uk-margin-small" style="margin-bottom: 0px;">
					Sign In
				</h1>
				<app-callout *ngIf="error" [type]="'important'">
					<span heading>Login Error</span>
					<div content>
						<p>The credentials provided are invalid.</p>
						<p>{{ error }}</p>
					</div>
				</app-callout>
				<form
					[formGroup]="signInForm"
					(submit)="signIn()"
					class="uk-width-1-1"
				>
					<div>
						<div
							class="uk-margin-small uk-padding-small uk-padding-remove-horizontal"
						>
							<table
								*ngIf="hasErrors == true"
								class="uk-table uk-table-divider uk-animation-fade"
							>
								<tbody>
									<tr>
										<td
											class="uk-table-shrink header-danger"
										>
											<mat-icon>warning</mat-icon>
										</td>
										<td class="uk-background-muted">
											<p>
												{{ serviceMessage }}
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div>
						<div>
							<p
								class="uk-text-bold uk-padding-remove uk-margin-remove-bottom uk-inline"
							>
								Email:
							</p>
							<div class="uk-grid-collapse uk-width-1-1" uk-grid>
								<div
									class="uk-width-1-1 uk-grid uk-grid-collapse"
								>
									<mat-form-field
										appearance="outline"
										class="enhancedEmail uk-width-expand"
									>
										<input
											data-cy="username"
											matInput
											placeholder=""
											name="username"
											formControlName="username"
										/>
										<mat-error
											class="goa-errorLabel"
											*ngIf="
												signInForm.controls.username
													.invalid &&
												signInForm.controls.username
													.errors.required
											"
										>
											Username is required.
										</mat-error>
									</mat-form-field>

									<div class="suffixBoxContainer">
										<div class="suffixBox">@gov.ab.ca</div>
									</div>
								</div>
							</div>
						</div>

						<div class="uk-width-1-1">
							<p
								class="uk-text-bold uk-padding-remove uk-margin-remove-bottom uk-inline"
							>
								Password:
							</p>
							<div class="uk-width-1-1">
								<mat-form-field
									appearance="outline"
									class="uk-margin-remove uk-width-1-1"
								>
									<input
									data-cy="password"
										matInput
										placeholder=""
										class="uk-width-1-1"
										name="password"
										formControlName="password"
										type="password"
									/>
									<mat-error
										class="goa-errorLabel"
										*ngIf="
											signInForm.controls.password
												.invalid &&
											signInForm.controls.password.errors
												.required
										"
									>
										Password is required.
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="uk-width-1-1 uk-margin-small-top">
							<button
								mat-raised-button
								data-cy="btnSubmit"
								class="button-primary uk-width-1-1"
								type="submit"
							>
								Sign In
							</button>
						</div>

						<div class="uk-width-1-1 goa-section-top">
							<p>
								<a href="https://password.gov.ab.ca/goa/psf.exe?JUMPTOCGI=PSA#2" target="_blank" data-cy="lnkForgotPassword">Forgot your password?</a>
							</p>
						</div>
					</div>
				</form>

				<!-- <div *ngIf="error" class="uk-width-1-1 error">
					{{ error }}
				</div> -->
			</div>
		</div>
	</div>
</section>
