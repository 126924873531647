import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core/';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {
	ErrorStateMatcher,
	ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core/';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';

const MODULES = [
	MatCheckboxModule,
	MatNativeDateModule,
	MatDatepickerModule,
	MatButtonModule,
	MatInputModule,
	MatAutocompleteModule,
	MatIconModule,
	MatRadioModule,
	MatSelectModule,		
	MatFormFieldModule,
	MatPaginatorModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule,
	MatProgressSpinnerModule,
	MatExpansionModule,
	MatDialogModule,
	MatChipsModule,
	MatSnackBarModule,
	MatSidenavModule,
	MatButtonToggleModule
];

@NgModule({
	declarations: [],
	imports: [MODULES],
	providers: [
		{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
	],
	exports: [MODULES],
})
export class MaterialModule {}
