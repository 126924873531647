<div class="uk-grid uk-grid-collapse uk-width-auto" *ngIf="!data.errorType">
	<div class="uk-width-expand" [innerHtml]="data.message"></div>
	<div class="uk-width-auto" *ngIf="data.duration == 0"><a (click)="dismissMessage()" style="color: #ffffff; margin-left: 10px;">DISMISS</a></div>
  </div>
  
  <div uk-grid class="uk-grid-collapse uk-grid-match uk-width-auto" *ngIf="data.errorType">
  <div class="errorBlock uk-padding-small uk-width-auto">
	<span class="material-icons">warning_amber</span>
  </div>
  <div class="uk-width-expand uk-padding-small">
	<div class="uk-grid uk-grid-collapse">
		<h3 class="uk-width-1-1">{{ data.title }}</h3>
		<div class="uk-width-expand" [innerHtml]="data.message"></div> 
		<div class="uk-width-auto"><a (click)="dismissMessage()" style="color: #000000; margin-left: 10px;">DISMISS</a></div>		
	</div>
  </div>
</div>