import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'adjShortDateFormat'
})
export class AdjShortDateFormatPipe implements PipeTransform {

  transform(adjDate: Date): string {
    return moment(adjDate).format("MMM DD, YYYY");
  }

}
