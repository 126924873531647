import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GeneralAuthGuard } from '@adj/services/general-auth-guard.service';
import { AdjournmentDetailsContainerComponent } from '@adj/containers/adjournment-details-container/adjournment-details-container.component';
import { AlreadyAuthorizedGuard } from '@adj/services/already-authorized-guard.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DecisionConfirmationComponent } from './dialogs/decision-confirmation/decision-confirmation.component';
import { ScenarioBuilderComponent } from './pages/scenario-builder/scenario-builder.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { UserSettingsComponent } from './pages/users/user-settings/user-settings.component';
import { EndorsementSheetContainerComponent } from './containers/endorsement-sheet-container/endorsement-sheet-container.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { PrintLayoutComponent } from './layouts/print-layout/print-layout.component';
import { AppAuthGuard } from './shared/authentication/guards/app-auth.guard';
import { MyAccountComponent } from './pages/users/my-account/my-account.component';
import { SignOutComponent } from './pages/users/sign-out/sign-out.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRedirectComponent } from './pages/app-redirect/app-redirect.component';
import { HelpContactUsComponent } from './pages/users/help-contact-us/help-contact-us.component';

const appRoutes: Routes = [
	//default path -- Home page
	{
		path: '',
		component: HomeComponent,
	},
	{
		path: 'app-redirect',

		component: AppRedirectComponent,
	},
	{
		path: 'adjournment-detail/:uid',
		component: AdjournmentDetailsContainerComponent,
		canActivate: [AppAuthGuard],
	},
	{
		path: 'adjournment-list',
		component: DashboardComponent,
		canActivate: [AppAuthGuard],
	},
	{
		path: 'details/:uid',
		component: AdjournmentDetailsContainerComponent,
		canActivate: [AppAuthGuard],
	},

	{
		path: 'sign-in',
		component: HomeComponent,
	},
	{
		path: 'sign-out',
		component: SignOutComponent,
	},
	{
		path: 'scenario-builder',
		component: ScenarioBuilderComponent,
		canActivate: [AppAuthGuard],
	},
	{
		path: 'users/usersettings',
		component: UserSettingsComponent,
		canActivate: [AppAuthGuard],
	},
	{
		path: 'users/my-account',
		component: MyAccountComponent,
		canActivate: [AppAuthGuard],
	},

	{
		path: 'confirm',
		component: DecisionConfirmationComponent,
	},

	{
		path: 'help',
		component: HelpContactUsComponent,
		canActivate: [AppAuthGuard],
	},
];

const printRoutes: Routes = [
	{
		path: 'endorsement-sheet/:uid',
		component: EndorsementSheetContainerComponent,
		canActivate: [AppAuthGuard],
	},
];

const routes: Routes = [
	{
		path: '',
		component: AppLayoutComponent,
		children: appRoutes,
	},
	{
		path: '',
		component: PrintLayoutComponent,
		children: printRoutes,
	},
	{ path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
