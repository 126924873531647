


export class DocketJoinHistoryRequestModel {
    public docketNumber: string = '';
    public accusedKey: string = '';

    constructor(init? : Partial<DocketJoinHistoryRequestModel>) {
        
        Object.assign(this, init);
    }
}
