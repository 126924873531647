<div class="uk-grid uk-margin-remove-vertical urgent-block uk-animation-fade" *ngIf="urgentMessage != ''">
	<div class="uk-width-auto"><span class="material-icons-outlined">
		report_problem
		</span></div>
	<div class="uk-width-expand"><h6>{{urgentMessage}}</h6></div>
	<div class="uk-width-auto"><span class="material-icons pointer" (click)="closeBanner('urgent',urgentMessage)">
			close
		</span></div>
</div>
<div class="uk-grid uk-margin-remove-vertical warning-block uk-animation-fade" *ngIf="warningMessage != ''">
	<div class="uk-width-auto"><span class="material-icons-outlined">
			error_outline
		</span></div>
	<div class="uk-width-expand"><h6>{{warningMessage}}</h6></div>
	<div class="uk-width-auto"> <span class="material-icons pointer" (click)="closeBanner('warning', warningMessage)">
			close
		</span> </div>	
</div>
<div class="uk-grid uk-margin-remove-vertical info-block uk-animation-fade" *ngIf="infoMessage != ''">
	<div class="uk-width-auto"><span class="material-icons" style="width:24px;">
			info_outlined
		</span></div>	
	<div class="uk-width-expand"><h6>{{infoMessage}}</h6></div>
	<div class="uk-width-auto"> <span class="material-icons pointer" (click)="closeBanner('info', infoMessage)">
			close
		</span> </div>
</div>

