


export class GetUserByKeycloakUserIdQueryRequestModel {
    public keycloakUserId: string = '';

    constructor(init? : Partial<GetUserByKeycloakUserIdQueryRequestModel>) {
        
        Object.assign(this, init);
    }
}
