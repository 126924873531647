<!-- Popup modal -->
<div id="expire-prompt" uk-modal class="uk-padding-remove">
	<div class="uk-modal-dialog uk-margin-auto-vertical expire-modal">
		<div class="uk-modal-body">
			<div uk-grid>
				<h3 style="margin-bottom: 0px">
					You are about to <strong>acknowledge the expiration</strong> of adjournment
					request id {{ decision?.uid | shortAdjUid }}.
				</h3>
				<div class="uk-container">
			
					<div class="uk-grid-row-small uk-child-width-1-1 detailSection">				
					
						<table class="uk-table uk-table-small">
							<thead>
								<tr>
									<th>Accused name</th>
									<th>Date of birth</th>								
								</tr>
							</thead>
							<tr>
								<td>{{ decision?.accusedFullName }}</td>
								<td>{{ decision?.accusedDOB | adjDateFormat }}</td>
							</tr>
						</table>
		
						<p class="uk-padding-small uk-padding-remove-vertical">Adjournment details</p>	
						<div class="uk-padding-small uk-padding-remove-vertical">
							<div class="uk-background-muted ">
		
								<table class="summaryTable uk-table uk-table-small">											
									<thead>
										<tr>
											<th>Docket number(s)</th>
											<th>Upcoming appearance date(s)</th>
											<th>Adjourn to date</th>
											<th>Next courtroom</th>
										</tr>
									</thead>
									<tr *ngFor="let docket of decision?.summaryDockets; let isFirst = first;">
										<td>{{ docket?.docketNumber }}</td>
										<td *ngIf="isFirst">{{ docket?.upcomingAppearanceDate | adjDateFormat }}</td>
										<td *ngIf="!isFirst">{{ docket?.upcomingAppearanceDate | adjDateFormat }}</td>
										<td>{{ decision?.adjournToDate | adjDateFormat }}</td>
										<td>{{ docket?.requestedCourtRoomId ? docket?.requestedCourtRoomId : 'N/A'}}</td>												 
									</tr>
								</table>
							</div>
						</div>
						
						<div class="uk-padding-small">
							<p>Reason for adjournment</p>					
							<span>{{ reasonText }}</span>
						</div>
		
		
						<div class="uk-padding-small" *ngIf="decision?.explanation">
							<p>Explanation of reason for adjournment</p>					
							<span>{{ decision?.explanation }}</span>
						</div>
		
		
						<div  *ngIf="decision?.specialInstructions" class="uk-padding-small">
							<p>Special Requirements</p>
							<span>{{ decision?.specialInstructions }}</span>
						</div>
		
						<div class="uk-padding-small" *ngIf="decision?.jpDecisionNotes">
							<p>Decision notes</p>				
							<span>{{ decision?.jpDecisionNotes }}</span>
						</div>
		
						<div class="uk-padding-small">
							<mat-chip-list class="uk-align-left uk-margin-remove">
								<mat-chip
									*ngIf="decision?.statusId == 5"
									class="header-expired"
									selected
									>Expired</mat-chip
								>
							</mat-chip-list>
							<span class="uk-align-left uk-margin-remove" *ngIf="currentUser | async as user"><strong> by {{ user?.displayName }}</strong>
								on {{ decision?.jpDecisionDate | adjDateFormat }} at {{ decision?.jpDecisionDate | date: "h:mm a" | lowercase }}
							</span>
						</div>
						<div>&nbsp;</div>
					</div>
				</div>
				<div class="buttonsRow">
					
					<button style="float: right"
					data-cy="btnSubmit"
						mat-raised-button
						(click)="submit()"
						class="button-primary uk-modal-close"
						cdkFocusInitial
					>
						<strong>Submit</strong>
					</button>
					<span style="float: right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					<button mat-raised-button data-cy="btnCancel" class="uk-modal-close" style="float: right">
						<strong>Cancel</strong>
					</button>
					
				</div>
			</div>
		</div>
	</div>
</div>




