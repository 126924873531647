import { AdjournmentModel } from '@adj/generators/models/adjournment.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-docket-detail-list',
  templateUrl: './docket-detail-list.component.html',
  styleUrls: ['./docket-detail-list.component.scss']
})
export class DocketDetailListComponent implements OnInit {

  @Input() adjournment: AdjournmentModel;
  @Input() activeDocket: string = "";
  constructor() { }

  ngOnInit(): void {

  }

}
