import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shortAdjUid',
})
export class ShortAdjUidPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		if (value) {
			return value.slice(0, 8).toUpperCase();
		}

		return value;
	}
}
