import { DocketDecisionModel } from "../../generators/models/docket-decision.model";


export class AdjournmentDecision {
	uid: string;
	decision: string;
	statusId: number;
	reasonCode: number;
	accusedFullName: string;
	accusedDOB: Date;
	docketNumbers: DocketDecisionModel[];
	adjournToDate: string;
	reasonForAdjournment: string;
	adjournmentNumber: number;
	explanation: string;
	specialInstructions: string;
	jpDecisionNotes: string;
	jpDecisionDate: Date;
	jpName: string;
	summaryDockets: DocketDecisionModel [];
	isManualGrant: boolean;
	agentTypeId: number;
	capacityOfCounselId: number;

	constructor(init?: Partial<AdjournmentDecision>) {
		Object.assign(this, init);
	}
}
