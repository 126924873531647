export class SaveAdjournment {
	location: number;
	docketNumber: string;
	setCourtDate: string;
	reasonCode: number;
	reasonExplanation: string;
	adjournToDate: string;
	specialInstructions: string;
	confimAllCharges: boolean;

	counselFirstName: string;
	counselMiddleName: string;
	counselLastName: string;
	lawFirm: string;
	counselEmailAddress: string;
	counselMobileNumber: string;
	counselReceiveSMS: boolean;

	accusedFirstName: string;
	accusedMiddleName: string;
	accusedLastName: string;
	accusedDOB: string;
	accusedEmail: string;
	accusedMobileNumber: string;
	accusedReceiveSMS: boolean
}
