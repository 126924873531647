


export class GetCourtCircuitsRequestModel {
    public courtId: number = 0;
    public courtType: string = '';

    constructor(init? : Partial<GetCourtCircuitsRequestModel>) {
        
        Object.assign(this, init);
    }
}
