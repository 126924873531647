

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';


@Injectable({
    providedIn: 'root'
})
export class VersionService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('version'));
    }

    
    public getVersion() : Observable<string> {
        return this.get('getVersion');
    }

    public async getVersionAsync() : Promise<string> {
        return await this.get<string, null>('getVersion').toPromise();
    }

    
    public getDateTime() : Observable<string> {
        return this.get('getDateTime');
    }

    public async getDateTimeAsync() : Promise<string> {
        return await this.get<string, null>('getDateTime').toPromise();
    }

    
    public getEnvironment() : Observable<string> {
        return this.get('getEnvironment');
    }

    public async getEnvironmentAsync() : Promise<string> {
        return await this.get<string, null>('getEnvironment').toPromise();
    }

    
}
