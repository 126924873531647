


export class GetUserSettingsRequestModel {
    public keycloakUserId: string = '';

    constructor(init? : Partial<GetUserSettingsRequestModel>) {
        
        Object.assign(this, init);
    }
}
