import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AdjournmentDecision, AuthenticatedUser } from '@adj/common/models';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray,  } from '@angular/forms';
import { DecisionConfirmationComponent } from '@adj/dialogs/decision-confirmation/decision-confirmation.component';
import { ExpireConfirmationComponent } from '@adj/dialogs/expire-confirmation/expire-confirmation.component';
import { DenyAppearanceTypeNoticeComponent } from '@adj/dialogs/deny-appearance-type-notice/deny-appearance-type-notice.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../../components/notification/notification.component';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { UidModel } from '@adj/generators/models/uid.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { DecisionNotificationComponent } from '../../components/decision-notification/decision-notification.component';
import { AcknowledgeExpireNotificationComponent } from '../../components/acknowledge-expire-notification/acknowledge-expire-notification.component';
import { ShortAdjUidPipe } from '@adj/pipes/short-adj-uid.pipe';
import { AdjournmentHistoryDetailModel } from '@adj/generators/models/adjournment-history-detail.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';
import { Router } from '@angular/router';
import { DocketListModel } from '@adj/generators/models/docket-list.model';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import moment from 'moment';
import { VersionService } from '@adj/generators/services/version.service';
import { tap } from 'rxjs/operators';
import { LocationWithScheduleModel } from '@adj/generators/models/location-with-schedule.model';
import { AdjournmentHistoryRequestModel } from '@adj/generators/models/adjournment-history-request.model';
import { AdjournmentMatterResponseModel } from '@adj/generators/models/adjournment-matter-response.model';
import { AdjournmentModel } from '@adj/generators/models/adjournment.model';
import { SettingsService } from '@adj/generators/services/settings.service';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { Constants } from '@adj/common/models/helpers/ui-constants';
import { Join2Service } from '@adj/generators/services/join2.service';
import { JoinSearchRequestModel } from '@adj/generators/models/join-search-request.model';
import { EndorsementSheetModel } from '@adj/generators/models/endorsement-sheet.model';
import { LocationCourtRoomModel } from '@adj/common/models/locations-api/shared-court-location.model';
import { AgentsService } from '@adj/generators/services/agents.service';
import { AgentContactCardRequestModel } from '@adj/generators/models/agent-contact-card-request.model';
import { AgentContactCardModel } from '@adj/generators/models/agent-contact-card.model';
import UIkit from 'uikit';
import { EndorsementSheetContainerComponent } from '../../containers/endorsement-sheet-container/endorsement-sheet-container.component';
import { JoinSearchDocketResponseModel } from '@adj/generators/models/join-search-docket-response.model';
import { ChargeModel } from '@adj/generators/models/charge.model';
import { JoinChargeModel } from '@adj/generators/models/join-charge.model';
import { DocketDecisionModel } from '@adj/generators/models/docket-decision.model';
import { CodesService } from '@adj/generators/services/codes.service';
import { CodeModel } from '@adj/generators/models/code.model';
import { GetAppearanceTypesCommandRequestModel } from '@adj/generators/models/get-appearance-types-command-request.model';
import { ChargesHistoryDetailModel } from '@adj/generators/models/charges-history-detail.model';
import { DocketJoinHistoryModel } from '@adj/generators/models/docket-join-history.model';
import { CodeGroupRequestModel } from '@adj/generators/models/code-group-request.model';
import { GetEventActionsCommandRequestModel } from '@adj/generators/models/get-event-actions-command-request.model';
import { GetAdjournmentWhoCommandRequestModel } from '@adj/generators/models/get-adjournment-who-command-request.model';
import { GetAdjournmentReasonsCommandRequestModel } from '@adj/generators/models/get-adjournment-reasons-command-request.model';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';
import { GetUserSettingsResponseModel } from '@adj/generators/models/get-user-settings-response.model';
import { GetUserSettingsRequestModel } from '@adj/generators/models/get-user-settings-request.model';
import { UserService } from '@adj/generators/services/user.service';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { DocketJoinHistoryRequestModel } from '@adj/generators/models/docket-join-history-request.model';
import { MessageNotificationService } from '@adj/services/message-notification.service';
import { JoinEventCardModel } from '@adj/generators/models/join-event-card.model';


@Component({
	selector: 'app-adjournment-detail',
	templateUrl: './adjournment-detail.component.html',
	styleUrls: ['./adjournment-detail.component.scss'],
})
export class AdjournmentDetailComponent implements AfterViewInit {
	pageTitle: string = 'Adjournment Details - File: ';


	//ADJ Details retooling
	adjDetail: AdjournmentModel = null; //this is the adjournment info retrieved from the repo ..this replces "details"







	details: AdjournmentModel = null; //adjournment detail from our repo
	history: AdjournmentHistoryDetailModel[];
	settings: SettingsModel[];

	originalCourtDate: Date;
	availableDates: string[];

	//keeps track of which docket history we're currently looking at
	activeDocketIndex: number = 0;
	activeDocket: string;
	activeDocketCharges: ChargesHistoryDetailModel[] = []; //saved crown election/charges
	currentDocketWithCharges: JoinSearchDocketResponseModel[] = [];

	//form submission
	formSubmitted: boolean;
	decisionStatusId: number;
	decisionForm: FormGroup;
	historyForm: FormGroup;

	keepDecisionOpen: boolean = false;

	docketDetails: any; //make docketDetails dynamic object becauase it may be an R3 OR R4 response object
	endorsementSheetData: EndorsementSheetModel;

	//retrieved and over-ridden docket infos
	summaryDockets: DocketDecisionModel[] = [];



	environmentName: string = '';


	//flags for handling editable adjournToDate
	editCourtDate: boolean = false;
	disableAdjournToDate: boolean = false;
	minAdjournToDate: Date;
	startDate: Date;

	@ViewChild(ExpireConfirmationComponent) expireConfirmationChild: ExpireConfirmationComponent;
	expireDecision: AdjournmentDecision;

	@ViewChild(DenyAppearanceTypeNoticeComponent) denyAppearanceTypeNoticeChild: DenyAppearanceTypeNoticeComponent;
	isAppearanceTypeChanged: boolean = false;

	isLegalAssistant: boolean = false;
	isCounsel: boolean = false;
	isAgent: boolean = false;

	//Manual grant
	joinErrorMessages: string;
	displayManualGrant: boolean = false;
	enableManualGrant: boolean = false;
	intervalTimeInSeconds: number = 180;
	manualGrantInterval;
	remainingTimeToEnableManualGrant: number; //in seconds
	enableSubmit: boolean = true;
	enableManualGrantPDFGenerator: boolean = false;
	retrievingEndorsementSheetData: boolean = false;

	//PDF Generator settings
	grantSuccessful: boolean = false;
	printNow: boolean = false;
	@ViewChild(EndorsementSheetContainerComponent) endorsementSheetContainer: EndorsementSheetContainerComponent;

	appearanceTypesData: CodeModel[] = [];
	reasons: CodeModel[] = [];
	eventActionsData: CodeModel[] = [];
	adjournmentByData: CodeModel[] = [];
	adjournmentReasonsData: CodeModel[] = [];

	displayJoinEventHistoryLocations: string[] = [];
	userSettings$: GetUserSettingsResponseModel;
	userLocationCode: string = "";
	currentUser: AuthenticatedUserProfileKeycloakModel;
	displayRACInstructions: string;
	firstAppearanceDate: Date;

	dictPanelOpen = {};

	truncateCommentsLength: number = 160;

	constructor(
		private _formBuilder: FormBuilder,
		private adjSvc: AdjournmentsService,
		private navRouter: Router,
		private titleSvc: Title,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private shortReqIdPipe: ShortAdjUidPipe,
		private versionSvc: VersionService,
		private settingsSvc: SettingsService,
		private join2Service: Join2Service,
		private agentService: AgentsService,
		private codeService: CodesService,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private messageService:MessageNotificationService
	) {
		this.versionSvc.getEnvironment().pipe(
			tap((result) => {
				this.environmentName = result;
			})
		);
	}



	@Input() loggedInUser: AuthenticatedUser;
	@Input() location: LocationModel;
	@Input() courtRooms: LocationCourtRoomModel[];
	@Input() locationSchedule: LocationWithScheduleModel;

	@Input() set adjournmentDetails(value) {
		this.details = value;

		if (this.details?.id != 0) {
			this.minAdjournToDate = this.details.originalAppearanceDate;
			this.startDate = this.details.originalAppearanceDate;

			this.pageTitle += this.details.id;
			this.titleSvc.setTitle(this.pageTitle);

			if (this.details.docketDecisions.length == 1) {
				this.keepDecisionOpen = true; //if there is only 1 docket number then automatically set this.
			}

			this.getAgentType(this.details.agentUid);

			this.showManualGrantCheckbox();
		}
	}

	//set the history
	@Input() set adjournmentDetailHistory(value: any) {
		if (value.length > 0) {
			this.history = value;
			this.originalCourtDate = value[0]?.originalAppearanceDate;
			this.activeDocket = value[0]?.docketNumber;
			this.activeDocketCharges = value[0]?.charges;

			this.initDocketNumbers();

			//select the value of the
			this.historyForm.controls.nextCourtRoom.setValue(value[0]?.nextCourtRoom);
		}
	}


	@Input() docketJoinHistory: DocketJoinHistoryModel;
	@Output() grantedAdjournment: EventEmitter<string> = new EventEmitter<string>();
	@Output() rejectedAdjournment: EventEmitter<string> = new EventEmitter<string>();

	async ngAfterViewInit(): Promise<void> {
		this.decisionForm = this._formBuilder.group({
			//default decision controls to be required
			grantDenyControl: new FormControl('', RxwebValidators.required()),
			decisionDetailControl: ['', RxwebValidators.required({ conditionalExpression: (x) => x.grantDenyControl == "3" })],
			adjournToDateControl: new FormControl({ value: '', disabled: true }),
			chkManualGrantControl: new FormControl(),
		});

		this.initializeHistoryFormBuilderGroup();

		//get adjournment reasons codes
		let codeRequestModel = new CodeGroupRequestModel();
		codeRequestModel.codeGroupId = 2;
		var result = await this.codeService.getCodeByGroupAsync(codeRequestModel);
		if(result.isSuccess){
			this.reasons = result.payload;
		}

		//get event actions codes
		var eventActionsResult = await this.codeService.getEventActionsAsync(new GetEventActionsCommandRequestModel());
		if(eventActionsResult.isSuccess){
			this.eventActionsData = eventActionsResult.output;
		}

		//get adjournment by codes
		var adjournmentByResult = await this.codeService.getAdjournmentByAsync(new GetAdjournmentWhoCommandRequestModel());
		if(adjournmentByResult.isSuccess){
			this.adjournmentByData = adjournmentByResult.output;
		}

		//get adjournment reason codes
		var adjournmentReasonsResult = await this.codeService.getAdjournmentReasonsAsync(new GetAdjournmentReasonsCommandRequestModel());
		if(adjournmentReasonsResult.isSuccess){
			this.adjournmentReasonsData = adjournmentReasonsResult.output;
		}

		//will help determine whether to display JOIN event history link to open the quickview
		this.getJoinEventHistoryLocationsSetting();

		//will help determine whether to display RAC Instructions
		this.getRacInstructionSetting();
	}

	async ngOnChanges(changes: SimpleChanges) {
		let change = changes["docketJoinHistory"];
		if (change && change.currentValue)
		{
			let docketJoinHistory = change.currentValue;

      // find first appearance date from JOIN history
			let appearances = docketJoinHistory.eventCards.filter(e => !e.joinEventCard.isEventOnly);
			if (appearances && appearances.length > 0)
				this.firstAppearanceDate = appearances[0].eventDate;
		}
	}
	async getJoinEventHistoryLocationsSetting()
	{
		//get user location
		this.currentUser = await this.authenticationService.getCurrentUser();
		var settingsResult = await this.userService.getSettingsAsync(new GetUserSettingsRequestModel({keycloakUserId:this.currentUser.keycloakUserId}));
		this.userSettings$ = settingsResult.payload;
		this.userLocationCode = this.userSettings$.locationCode;

		//get settings for displaying JOIN event history link
		let locationSettings:SettingsModel;
		var settingsResults = await this.settingsSvc.getSettingByKeyAsync(new GetSettingByKeyRequestModel({key:Constants.Settings.JoinEventHistoryLocations}))
		.then(data=>{

			if (data.isSuccess)
				locationSettings = data.payload;
			else
			{

				this.messageService.showErrorMessage("An error occurred while getting JoinEventHistoryLocations setting.",data.errors[0],true);
				//throw new Error('An error occurred while getting JoinEventHistoryLocations setting.');
			}
		});
		if (locationSettings) {
			let values = locationSettings.value.split('|');
			if (values.length > 0) {
				//filter based on values in the settings
				this.displayJoinEventHistoryLocations = values.filter(setting => setting == this.userLocationCode);
			}
		}
	}
	async getRacInstructionSetting(){
		let racInstructionSettings:SettingsModel;
			var settingsResults2 = await this.settingsSvc.getSettingByKeyAsync(new GetSettingByKeyRequestModel({key:Constants.Settings.DisplayRACInstructions})).then(data=>{

				if (data.isSuccess){
					racInstructionSettings = data.payload;
					this.displayRACInstructions = racInstructionSettings.value;
				}
				else
					throw new Error('An error occurred while getting "display_rac_instructions" settings.');
			});
	}
	initializeHistoryFormBuilderGroup()
	{

		this.historyForm = this._formBuilder.group({
			//default decision controls to be required
			nextCourtRoom: new FormControl('CMO'),
			appearanceTypes: this._formBuilder.array([]),
		});

	}
	getAppearanceTypeFormGroup(defaultValue): FormGroup {

		return this._formBuilder.group({
			appearanceType: [
				defaultValue,
				[
					Validators.required
				],
			],
		});
	}


	addAppearanceTypeField() {

			//add new field with empty value to the UI
			let appearanceTypesArray = <FormArray>(
				this.historyForm.controls.appearanceTypes
			);
			appearanceTypesArray.push(this.getAppearanceTypeFormGroup(''));

		return false; //don't trigger validation
	}

	async getAgentType(agentUid){
		let cardModel: AgentContactCardRequestModel = new AgentContactCardRequestModel();
		cardModel.agentUid = agentUid;


		let contactCard = await this.agentService.getContactCardAsync(cardModel).then(response => {
			let agentCard: AgentContactCardModel = response.payload;
			this.isCounsel = agentCard.agentTypeId === Constants.AgentTypeId.Lawyer;
			this.isLegalAssistant = agentCard.agentTypeId === Constants.AgentTypeId.LegalAssistant;
			this.isAgent = agentCard.agentTypeId === Constants.AgentTypeId.Agent;
		});
	}

	showManualGrantCheckbox() {
		// decide whether to show checkbox for manual grant
		if (this.details.joinTransactionStartTime != null && this.details.joinTransactionEndTime == null)
		{
			this.displayManualGrant = true;

			var currentdate = new Date();
			var joinTransactionStartTime = new Date(this.details.joinTransactionStartTime);
			var currentTransactionTimeInSeconds = (currentdate.getTime() - joinTransactionStartTime.getTime()) / 1000;
			if (currentTransactionTimeInSeconds > this.intervalTimeInSeconds) // show manual grant after 3 mins
			{
				this.enableManualGrant = true;
			}
			else {
				this.enableManualGrant = false;
				this.enableSubmit = false;

				// enable manual grant checkbox after certain timer
				this.remainingTimeToEnableManualGrant = this.intervalTimeInSeconds - currentTransactionTimeInSeconds;
				this.manualGrantInterval = setInterval(this.showManualGrant.bind(this), 1000);
			}
		}
	}

	refreshAdjournToDate() {
		this.decisionForm.controls.adjournToDateControl.setValue(
			this.details.adjournToDate
		);
	}

	decisionRequired(): boolean {
		if (this.decisionForm.controls.grantDenyControl.value === "3")
			return true;
		else
			return false;

	}

	editDate(editMode: boolean) {
		this.editCourtDate = editMode;
	}


	//this method calls searchByDocket from the JOIN 2.0 endpoints to get docket/charge info for each of the dockets
	async initDocketNumbers() {
		if (this.details.docketDecisions.length > 0) {
			let list: DocketListModel = new DocketListModel();

			let request: JoinSearchRequestModel = new JoinSearchRequestModel();
			request.accusedId = this.details.accusedID; //inlude accusedID for specific filtering
			if(this.details.isTest === true)
				request.isTest = true;//pass along the isTest flag from the DB

			//add the docket numbers to the request so we can do a SearchByDocketNumber request
			this.details.docketDecisions.forEach(docket => {
				request.docketNumber.push(docket.docketNumber);
			});


			//pipe the  search Results to docketInfo$ ..
			var results = await this.join2Service.searchByDocketAsync(request);

			if (results.isSuccess === true) {
				this.docketDetails = results.payload;

				this.docketDetails.results.forEach(x => {
					//set up output model
					let docket: DocketDecisionModel = new DocketDecisionModel();
					docket.requestedCourtRoomId = x.courtRoom;
					docket.docketNumber = x.docketNumber;
					docket.requestedAppearanceDate = x.nextAppearanceDate;

					for(let charge of x.charges)
					{
						let chargeModel: ChargeModel = new ChargeModel();
						chargeModel.fileAccusedChargeUniqueKey = charge.fileAccusedChargeUniqueKey;
						chargeModel.appearanceType = charge.appearanceTypeDisplay;
						chargeModel.crownElection = charge.crownElection;
						chargeModel.crownElectionPrism = charge.crownElectionPrism;
						chargeModel.crownElectionDisplay = charge.crownElectionDisplay;
						chargeModel.chargeActCode = charge.chargeActCode;
						chargeModel.chargeCode = charge.chargeCode;
						docket.charges.push(chargeModel);
					}

					var idx = this.summaryDockets.findIndex(doc => doc.docketNumber == x.docketNumber);

					if (idx === -1) {

						this.summaryDockets.push(docket);
					}
				});

				this.initializeAppearanceTypeDropdown();
			}
		}
		else {
			this.messageService.showErrorMessage("Failed to get adjournment details",results.message,true);

		}

	}

	async initializeAppearanceTypeDropdown()
	{
		let docketType = this.activeDocket.substr(9, 1);
		//get data for dropdown
		var appearanceTypeResult = await this.codeService.getAppearanceTypesAsync(new GetAppearanceTypesCommandRequestModel({docketType: docketType}));
		if(appearanceTypeResult.isSuccess){
			this.appearanceTypesData = appearanceTypeResult.output;
		}

		this.currentDocketWithCharges = this.docketDetails.results.filter( d => d.docketNumber == this.activeDocket);
		if (this.currentDocketWithCharges.length == 0)
			return;
		let i = 0;
		for(let charge of this.currentDocketWithCharges[0].charges)
		{
			this.addAppearanceTypeField();

			let appearanceTypeExist = this.appearanceTypesData.filter(x=>x.meta1 == charge.appearanceTypeDisplay);
			if (appearanceTypeExist.length > 0)
			{
				// set default value on dropdown based on back end business logic
				this.historyForm.controls['appearanceTypes']['controls'][i].controls['appearanceType'].setValue(charge.appearanceTypeDisplay);
			}
			else{
        if (this.appearanceTypesData.length>0) {
          // always set a default value when scrolling through the docket instead of greying out the next/previous button.
          this.historyForm.controls['appearanceTypes']['controls'][i].controls['appearanceType'].setValue(this.appearanceTypesData[0].meta1);
        }
        else { 	// set to none selected on dropdown
          this.historyForm.controls['appearanceTypes']['controls'][i].controls['appearanceType'].setValue('');
        }
			}
			i++;
		}
	}

	selectDecision() {

		//handle some presentation logic with valdation rules depending on what option has been selected.
		if (this.decisionForm.controls.grantDenyControl.value == "2") {
			//granted
			this.decisionForm.controls.adjournToDateControl.enable();
			this.minAdjournToDate = this.details.adjournToDate;
			this.decisionForm.controls.adjournToDateControl.setValue(this.minAdjournToDate);

			this.decisionForm.controls.decisionDetailControl.markAsPristine(); //reset any busted valiators
		}
		else {
			//deny
			this.refreshAdjournToDate();
			this.decisionForm.controls.adjournToDateControl.disable()
			this.minAdjournToDate = this.originalCourtDate;
			this.decisionForm.controls.adjournToDateControl.setValue(this.minAdjournToDate);
			this.decisionForm.controls.decisionDetailControl.markAsDirty(); //automatically invalidate the decision control to make it visibily errored

			if (this.isAppearanceTypeChanged)
			{
				this.denyAppearanceTypeNoticeChild.showModal();
				this.isAppearanceTypeChanged = false; //reset this flag since we show the message
			}
		}

		this.decisionForm.controls.adjournToDateControl.updateValueAndValidity();
		this.decisionForm.controls.decisionDetailControl.updateValueAndValidity();
	}


	goBack() {
		window.history.back();
	}
	onAppearanceTypeChange(event: any, charge: JoinChargeModel) {
		this.isAppearanceTypeChanged = true;

		//set output model
		var chargeItems = this.summaryDockets[this.activeDocketIndex].charges.filter( c => c.fileAccusedChargeUniqueKey == charge.fileAccusedChargeUniqueKey);
		if (chargeItems.length > 0)
			chargeItems[0].appearanceType = event.value;

		//set input model that is used for binding in case user goes back to previous docket
		var chargeDetails = this.currentDocketWithCharges[0].charges.filter( c => c.fileAccusedChargeUniqueKey == charge.fileAccusedChargeUniqueKey);
		if (chargeDetails.length > 0)
			chargeDetails[0].appearanceTypeDisplay = event.value;
	}
	onNextCourtRoomChange(event: any) {
		//save the selected court room to the correct DocketModel
		this.summaryDockets[this.activeDocketIndex].requestedCourtRoomId = event.value;
	}

	async changeActiveDocket(idx: number, event: any) {
		event.preventDefault();

		if (this.historyForm.valid) {
		}
		else
			return; // need user to fix selecting appearance types

		this.activeDocket = this.details?.docketDecisions[idx].docketNumber;

		//the following three lines of code setup the appearance dropdown
		this.currentDocketWithCharges = this.docketDetails.results.filter( d => d.docketNumber == this.activeDocket);
		this.initializeHistoryFormBuilderGroup();
		this.initializeAppearanceTypeDropdown();

		this.originalCourtDate = this.details?.docketDecisions[idx].requestedAppearanceDate;
		this.activeDocketIndex = idx;

		if (idx == this.details?.docketDecisions.length - 1) {
			this.keepDecisionOpen = true; //only set this one-time if they've hit the last docketnumber
		}

		let adjHistory = await this.adjSvc.getAdjournmentDetailHistoryAsync(
			new AdjournmentHistoryRequestModel({
				docketNumber: this.activeDocket,
				accusedKey: this.details.accusedKey
			})
		);
		this.history = adjHistory.payload;

		this.activeDocketCharges = this.history[0]?.charges;

		//select the court room from the list
		this.historyForm.controls.nextCourtRoom.setValue(
			this.summaryDockets[idx]?.requestedCourtRoomId.trim()
		);

		//clear variable used to control the state of mat-expansion-panel.
    	this.dictPanelOpen = {};

		let joinHistory = await this.adjSvc.getDocketJoinHistoryAsync(
			new DocketJoinHistoryRequestModel({
				docketNumber: this.activeDocket,
				accusedKey: this.details.accusedKey
			})
		);
		this.docketJoinHistory = joinHistory.payload;

		this.mergeJoinDataToAdsCard(this.history, this.docketJoinHistory);

		// find first appearance date from JOIN history
		let appearances = this.docketJoinHistory.eventCards.filter(e => !e.joinEventCard.isEventOnly);
		if (appearances && appearances.length > 0)
			this.firstAppearanceDate = appearances[0].eventDate;

	}

	public submitForm() {

		var decisionValue = this.decisionForm.controls.grantDenyControl.value;
		if (decisionValue == 2) // skip validation if denied
		{
			//trigger the recursive validation
			this.triggerDocketValidators(this.historyForm.controls.appearanceTypes as FormArray);

			if (this.historyForm.valid) {
			}
			else
				return;
		}
		if (this.decisionForm.valid) {
			//set decision values
			this.details.adjournmentStatusId = JSON.parse(
				this.decisionForm.controls.grantDenyControl.value
			);
			this.details.decisionNotes = this.decisionForm.controls.decisionDetailControl.value;
			this.openDialog();
			return false;
		}
	}
	//mark all recursive invalid fields as dirty to trigger UI behavior
	triggerDocketValidators(formGroup: FormGroup | FormArray): void {
		Object.keys(formGroup.controls).forEach((key) => {
			const control = formGroup.controls[key] as
				| FormControl
				| FormGroup
				| FormArray;
			if (control instanceof FormControl) {
				control.markAsDirty();
			} else if (
				control instanceof FormGroup ||
				control instanceof FormArray
			) {

				this.triggerDocketValidators(control);
			} else {
				//
			}
		});
  }

	determineCounsel() {
		if (this.details) {
			if (
				this.details.agentTypeId == Constants.CounselTypeId.DutyCounsel && // 10000 &&
				this.details.capacityOfCounselId == Constants.CapacityOfCounselId.NotRetained // 11001
			) {
				return 'Duty Counsel - this appearance only';
			}
			if (
				this.details.agentTypeId == Constants.CounselTypeId.DefenseCounsel && // 10001 &&
				this.details.capacityOfCounselId == Constants.CapacityOfCounselId.NotRetained // 11001
			) {
				return 'Defence Counsel - this appearance only';
			}
			if (
				this.details.agentTypeId == Constants.CounselTypeId.DefenseCounsel && // 10001 &&
				this.details.capacityOfCounselId == Constants.CapacityOfCounselId.Retained // 11000
			) {
				return 'Defense Counsel - retained';
			}
		}

		return '';
	}

	getNumberOfDays(adjournToDate) {
		var days = this.getDifferenceInDays(this.firstAppearanceDate, adjournToDate);
		return days;
	}
	getNumberOfWeeks(adjournToDate) {
		var days = this.getDifferenceInDays(this.firstAppearanceDate, adjournToDate);
		var weeks = Math.round(days / 7);
		return weeks;
	}
	getDifferenceInDays(date1, date2) {
		var diffInMs = date2?.getTime() - date1?.getTime();
		var days = Math.round(diffInMs / (1000 * 60 * 60 * 24))
		return days;
	  }
	public historyItemValid(idx, days) {
		if (!this.location || days == 0) return true;
		else {
			if (
				idx + 1 <= this.location.settings.maxAdjAllowed &&
				Math.round(days / 7) < this.location.settings.maxAdjPeriod
			) {
				return true;
			} else {
				return false;
			}
		}
	}

	public daysSinceTooltip(days) {
		if (!this.location || days == 0) {
			return '';
		} else {
			var calc = Math.round(days / 7);
			return `Days since original court date - ~ ${calc} weeks`;
		}
	}

	openDialog(): void {
		let decision = this.getAdjournmentDecision();

		const dialogRef = this.dialog.open(DecisionConfirmationComponent, {
			height: null,
			data: { decision },
			autoFocus: false,
		});

		// Close event on the confiration dialog modal
		dialogRef.afterClosed().subscribe(async (result: any) => {
			let output = result.output;
				this.closeWithResult(result, decision)
		});
	}

	getAdjournmentDecision() {
		let decision = new AdjournmentDecision();

		decision.uid = this.details.uid;

		decision.statusId = this.details.adjournmentStatusId;
		decision.reasonCode = this.details.reasonCode;
		decision.accusedFullName = this.details.accusedFullName;
		decision.accusedDOB = this.details.accusedDateOfBirth;

    decision.summaryDockets = this.summaryDockets;

		decision.adjournToDate = this.decisionForm.controls.adjournToDateControl.value.toDateString();
		decision.adjournmentNumber = this.history.length; //todo...figure out how to get this number more accurately
		decision.explanation = this.details.reasonExplanation;
		decision.specialInstructions = this.details.specialInstructions;

		decision.jpDecisionNotes = this.details.decisionNotes;
		decision.jpDecisionDate = new Date();
		decision.jpName = this.loggedInUser?.username; //TODO: how to get logged in user info ??

		decision.isManualGrant = this.decisionForm.controls.chkManualGrantControl.value;
		decision.capacityOfCounselId = this.details.capacityOfCounselId;
		decision.agentTypeId = this.details.agentTypeId;

		return decision;
	}

	closeWithResult(result: CommandResult<AdjournmentMatterResponseModel>, decision: AdjournmentDecision) {
		if (result) {
			try {
				if (result.isSuccess) {

					this.showDecisionConfirmationToaster(result.output != null, decision.isManualGrant);
					this.navRouter.navigate(['adjournment-list']);
				}
				else {
					var errorMessages = '';
					result.errors.forEach((err) => {
						errorMessages += '<li>' + err + '</li>';
					})

					this.joinErrorMessages = errorMessages;
					UIkit.modal("#modalJoinErrors").show();

					// check whether to display manual grant checkbox
					let adjournmentMatterResponseModel = result.output;
					let adjournment = adjournmentMatterResponseModel.adjournment;
					if (adjournment.joinTransactionStartTime != null && adjournment.joinTransactionEndTime != null)
					{
						this.displayManualGrant = true;
						this.enableManualGrant = true;
					}
					else
					{
						this.remainingTimeToEnableManualGrant = this.intervalTimeInSeconds;
						if (this.remainingTimeToEnableManualGrant > 0) // only do this the first time
							this.manualGrantInterval = setInterval(this.showManualGrant.bind(this), 1000);
					}
				}

			} catch (ex) {
				this.showErrorNotification();
			}
		}
	}

	async showManualGrant() {
		if (this.remainingTimeToEnableManualGrant <= 0)
		{
      		if (this.retrievingEndorsementSheetData) // the setInterval timer will keep coming back, don't do anything if we are in this state
				return;
			if (this.grantSuccessful)
			{
				clearInterval(this.manualGrantInterval);
				this.showDecisionConfirmationToaster(true, false);
				this.navRouter.navigate(['adjournment-list']);
				return;
			}
			// check if grant was succcessfuls
			let response = await this.adjSvc.getAdjournmentDetailAndLockItAsync(
				new UidModel({ uid: this.details.uid })
			);
			if (response.isSuccess) {
				this.details = response.output;
				if (this.details.adjournmentStatusId != Constants.AdjournmentStatusId.Submitted &&
					this.details.joinTransactionEndTime != null
						)
				{
          			this.retrievingEndorsementSheetData = true;

					// refresh endorsement sheet data before generating pdf later
					let finished = await this.endorsementSheetContainer.refreshData(this.details.uid);

					// this property will eventually popup endorsement sheet via app-pdf-generator component
					this.grantSuccessful = finished; // will bind this property once this returns to setinterval()

          			this.retrievingEndorsementSheetData = false;

					return;
				}
				else if (this.details.adjournmentStatusId === Constants.AdjournmentStatusId.Submitted)
				{
					var errorMessages = '';
					if (this.details.joinTransactionEndTime != null) // assume there is a JOIN error
					{
						if (this.details.joinTransactionErrorMsg != null)
						{
							errorMessages = '<li>' + this.details.joinTransactionErrorMsg + '</li>';
						}
						else
							errorMessages = '<li>' + 'UNKNOWN ERROR - ADS did not receive confirmation of a successful JOIN transaction. Please try confirming the submission in ADS again. If the error continues, check JOIN to confirm and/or manually complete the transaction. Please use the manual grant feature to mark the request as granted in ADS.' + '</li>';
					}
					else // assume timeout error
					{
						errorMessages = '<li>' + 'TIME OUT ISSUE - ADS did not receive confirmation of a successful JOIN transaction. Please try confirming the submission in ADS again. If the error continues, check JOIN to confirm and/or manually complete the transaction. Please use the manual grant feature to mark the request as granted in ADS.' + '</li>';
					}

					// else grant is not successful
					this.joinErrorMessages = errorMessages;
					UIkit.modal("#modalJoinErrors").show();

					this.enableManualGrant = true;
					this.enableSubmit = true;
					clearInterval(this.manualGrantInterval);
				}
			}
		}
		else
		{
			if (this.remainingTimeToEnableManualGrant <= 1)
				this.enableManualGrantPDFGenerator = true;

			this.remainingTimeToEnableManualGrant--;
		}
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	showErrorNotification() {
		var shortenedReqId = this.shortReqIdPipe.transform(
			this.details.uid
		);
		this._snackBar.openFromComponent(NotificationComponent, {
			data: {
				message:
					'There was a problem while saving the Decision for Request ' +
					shortenedReqId +
					', notifications may not have been delivered.',
			},
			horizontalPosition: 'center',
			verticalPosition: 'top',
			//panelClass: 'notification-error',
		});
	}

	openInNewTab(namedRoute) {
		let newRelativeUrl = this.navRouter.parseUrl(namedRoute);
		let baseUrl = window.location.href.replace(this.navRouter.url, '');
		window.open(baseUrl + newRelativeUrl, '_blank');
	}

	private showDecisionConfirmationToaster(joinEnabled: boolean, isManualGrant: boolean) {
		var decision = 'granted';
		if (this.details.adjournmentStatusId != 2) {
			decision = 'rejected';
		}

		this._snackBar.openFromComponent(DecisionNotificationComponent, {
			duration: 5000,
			data: { decision: decision, uid: this.details.uid, joinEnabled, isManualGrant },
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'decision-notification',
		});
	}

	public refreshValidators() {
		if (isNaN(this.decisionStatusId)) {
			this.decisionForm.get('decisionDetailControl').setValidators(null);
		} else if (Number(this.decisionStatusId) === 3) {
			//deny request requires decision details
			this.decisionForm
				.get('decisionDetailControl')
				.setValidators(Validators.required);
		} else {
			this.decisionForm.get('decisionDetailControl').setValidators(null);
		}

		this.decisionForm.get('decisionDetailControl').updateValueAndValidity();
	}

	useJoinWrite(): boolean {
		let retVal: boolean = false;

		if (
			this.environmentName.toLowerCase() == 'production' ||
			this.environmentName.toLowerCase() == 'uat'
		)
			retVal = true;

		return retVal;
	}

	public changeDecisionStatus(e) {
		this.decisionStatusId = e.value;
		this.refreshValidators();
	}

	public ordinal_suffix_of(i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + 'st';
		}
		if (j == 2 && k != 12) {
			return i + 'nd';
		}
		if (j == 3 && k != 13) {
			return i + 'rd';
		}
		return i + 'th';
	}

	scheduleFilter = (d): boolean => {
		const date = moment(d);
		const day = d || moment().day();
		//calenar dates are disabled based on location specific business rules
		if (this.locationSchedule && this.locationSchedule.dates.length > 0) {
			var elligibleDate = this.locationSchedule.dates.find(
				(x) =>
					x == date.format('MM/DD/YYYY') ||
					x == date.format('MM-DD-YYYY')
			);
			if (
				(day !== 0 &&
					day !== 6 &&
					typeof elligibleDate !== 'undefined' &&
					moment(date) >
					moment(this.details.originalAppearanceDate)) ||
				moment(elligibleDate) ==
				moment(this.details.originalAppearanceDate)
			) {
				return true;
			} else return false;
		} else {
			return day !== 0 && day !== 6;
		}
	};

	public submitExpireAcknowledgement() {

		this.openExpireAcknowledgementDialog();

	}

	openExpireAcknowledgementDialog(): void {
		this.expireDecision = this.getAdjournmentDecision();

		// call child component
		this.expireConfirmationChild.showModal(this.expireDecision);
	}

	expireAdjournmentAcknowledged() { // call by child component
		this.showAcknowledgeExpireConfirmationToaster(true);

		this.navRouter.navigate(['adjournment-list']);
	}

	private showAcknowledgeExpireConfirmationToaster(success: boolean) {

		this._snackBar.openFromComponent(AcknowledgeExpireNotificationComponent, {
			duration: 5000,
			data: { uid: this.details.uid, success },
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'decision-notification',
		});
	}

	isRetainedCounsel() {
		return (this.details.agentTypeId == 10001
			&& this.details.capacityOfCounselId == 11000);
	}

	getAppearanceTypeDescription(meta1: string)
	{
		let appearanceTypes = this.appearanceTypesData.filter(x=>x.meta1 == meta1);
		if (appearanceTypes.length > 0)
			return appearanceTypes[0].value;

		return meta1;
	}
	getEventActionDescription(meta1: string)
	{
		let eventActions = this.eventActionsData.filter(x=>x.meta1 == meta1);
		if (eventActions.length > 0)
			return eventActions[0].value;
		else if (meta1 === "FA")
			return "FIRST APPEARANCE";

    return meta1;
	}
	getCrownElectionDescription(crownElection: string)
	{
		return "Crown election made"; // right now we just want to display this instead of the CPS/CPI event code
	}
	getAdjournmentByDescription(meta1: string)
	{
		let adjounmentBy = this.adjournmentByData.filter(x=>x.meta1 == meta1);
		if (adjounmentBy.length > 0)
			return adjounmentBy[0].value;
		else
			return "None";
	}
	getAdjournmentReasonCode(reason: string){
		let reasons = this.reasons.filter(x=>x.meta1 == reason);
		if (reasons.length > 0)
			return reasons[0].value;
		else
			return "None";
	}

	getChargeCountString(chargeCounts: number[]){
		let countString: string = "";
		for(let chargeCount of chargeCounts)
		{
			countString+=chargeCount + "-";
		}
		return countString;
	}

	openPanel(adjournToDate: Date){
		// alert("open" + adjournToDate);
		this.dictPanelOpen[adjournToDate.toString()] = true;
	}
	closePanel(adjournToDate: Date){
		// alert("close" + adjournToDate);
		this.dictPanelOpen[adjournToDate.toString()] = false;
	}

	// currently used to show on ADS card the crown elections from JOIN data
	mergeJoinDataToAdsCard(adjournmentHistory: AdjournmentHistoryDetailModel[], docketJoinHistory: DocketJoinHistoryModel)
	{
		for (let history of adjournmentHistory)
		{
			// if granted, stitch JOIN appearance to ADS appearance based on decision date
			if (history.decisionID === 12002 && history.decisionDate)
			{
				let decisionDate = history.decisionDate;
				let decisionDatePartOnly = new Date(decisionDate.getFullYear(), decisionDate.getMonth(), decisionDate.getDate());
				let cards = docketJoinHistory?.eventCards.filter(e => e.eventDate?.toString() === decisionDatePartOnly.toString());
				if (cards && cards.length > 0)
					history.eventCard = cards[0];
			}
			// else if (history.decisionID !== 12002 && history.originalAppearanceDate)
			// {
			// 	let cards = docketJoinHistory?.eventCards.filter(e => e.eventDate?.toString() === history.originalAppearanceDate?.toString());
			// 	if (cards && cards.length > 0)
			// 		history.eventCard = cards[0];
			// }
		}
	}

	getCommentsLength(eventCard: JoinEventCardModel)
	{
		let count = 0;
		if (!eventCard)
			return count;

		//calculated length of crown comments and document text combined
		for (let crownComment of eventCard.crownComments)
		{
			if (crownComment.length > this.truncateCommentsLength)
				count = crownComment.length;
		}
		for (let documentTex of eventCard.documentText)
		{
			if (documentTex.length > this.truncateCommentsLength)
				count = documentTex.length;
		}
		return count;
	}
}
