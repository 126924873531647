import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Input,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'app-callout',
	templateUrl: './callout.component.html',
	styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent {

	_type: string = '';

	@Input() set type(value: string) {
		this._type = value;
		if (value){
			var classToAdd = this.typeMap.get(value);

			if (classToAdd){
				this.urgencyClass = classToAdd;
			}
		}
	}

	urgencyClass: string = '';

	private typeMap: Map<string, string> = new Map<string, string>([
		['normal', ''],
		['event', 'goa--event'],
		['important', 'goa--important'],
		['emergency', 'goa--emergency'],
	]);
}
