<div
	matTooltip="{{ docketNumbers | joinList }}"
	matTooltipPosition="above"
>
	<div class="container">
		<div *ngIf="docketNumbers.length > 1" style="margin-right: 5px">
			<div class="badge">
				{{ docketNumbers.length }}
			</div>
		</div>
		<div>
			{{ docketNumbers | joinList | ellipsis: 17 }}
		</div>
	</div>
</div>
