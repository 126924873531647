import { HttpRequestListenerService } from '@adj/services/http-request-listener.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-progress-spinner',
	template: `<mat-spinner></mat-spinner>`,
	styles: [],
})
export class ProgressSpinnerComponent implements OnInit{
	constructor() {}

	ngOnInit(): void {}

	

}
