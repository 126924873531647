<table mat-table [dataSource]="searchResultsDataSource" class="w-100">
	<!-- Name Column -->
	<ng-container matColumnDef="daysUntilAppearance">
		<th mat-header-cell *matHeaderCellDef>
			<b>Days Until Appearance</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.originalDate | daysUntil }}
		</td>
	</ng-container>

	<ng-container matColumnDef="dateRequested">
		<th mat-header-cell *matHeaderCellDef>
			<b>Date Request</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.newDate | date: "MMM dd, yyyy" }}
		</td>
	</ng-container>

	<ng-container matColumnDef="docketNumber">
		<th mat-header-cell *matHeaderCellDef>
			<b>Docket Number</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-search-result-docket-numbers
				[searchTerm]="searchTerm"
				[docketNumbers]="element.docketNumbers"
			></app-search-result-docket-numbers>
		</td>
	</ng-container>

	<ng-container matColumnDef="accusedFullName">
		<th mat-header-cell *matHeaderCellDef>
			<b>Accused Full Name</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<span
				[innerHTML]="
					element.accusedFullName | matchHighlight: searchTerm
				"
			></span>
		</td>
	</ng-container>

	<ng-container matColumnDef="status">
		<th mat-header-cell *matHeaderCellDef>
			<b>Status</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-table-status-chip
				[adjournmentStatusId]="element.statusId"
				[adjournmentDecisionId]="element.decisionId"
			></app-table-status-chip>
		</td>
	</ng-container>

	<ng-container matColumnDef="requestId">
		<th mat-header-cell *matHeaderCellDef>
			<b>Request Id</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<span
				[innerHTML]="
					element.uid | shortAdjUid | matchHighlight: searchTerm
				"
			></span>
		</td>
	</ng-container>

	<ng-container matColumnDef="details">
		<th mat-header-cell *matHeaderCellDef></th>
		<td mat-cell *matCellDef="let element">
			<app-table-details-button
				[currentUser]="currentUser"
				[hasNoSecurityRoleForLocation]="hasNoSecurityRoleForLocation"
				[hasNoSecurityRoles]="hasNoSecurityRoles"
				[details]="element"
			></app-table-details-button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onOpenQuickView(row)" class="clickable"></tr>
</table>

<div class="uk-container">
	<div class="uk-flex uk-flex-middle uk-flex-center">
		<div
			*ngIf="!_searchResults || _searchResults?.length === 0"
			style="margin-top: 20px"
		>
			<span
				><strong
					>There are no results matching your search criteria</strong
				></span
			>
		</div>
	</div>
</div>
