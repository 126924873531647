import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfirmationPendingAdjournment } from '@adj/common/models';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationPendingAdjournmentModel } from '@adj/generators/models/confirmation-pending-adjournment.model';

@Component({
	selector: 'app-confirmation-pending-listing',
	templateUrl: './confirmation-pending-listing.component.html',
	styleUrls: ['./confirmation-pending-listing.component.scss'],
})
export class ConfirmationPendingListingComponent implements OnInit {
	displayedColumns: string[] = [
		'jpDecisionDate',
		'jpDecision',
		'decidingJp',
		'docketNumber',
		'accusedFullName',
		'daysUntilOriginalAppearance',
		'details',
	];

	private _adjournmentConfirmPending: ConfirmationPendingAdjournmentModel[] = [];
	confirmPendingDataSource: MatTableDataSource<ConfirmationPendingAdjournmentModel>;
	@Input() set adjournmentConfirmPending(value: ConfirmationPendingAdjournmentModel[]) {
		this._adjournmentConfirmPending = value;
		this.confirmPendingDataSource = new MatTableDataSource(value || []);
	}

	@Output() openQuickView: EventEmitter<string> = new EventEmitter<string>();
	
	ngOnInit() {}

	onOpenQuickView(row: ConfirmationPendingAdjournmentModel){
		this.openQuickView.emit(row.uid);
	}
}
