import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';

import {
	EnvConfigService,
	loadConfiguration,
} from './common/services/env-config.service';
import { LayoutHeaderComponent } from './shared/components/layout-header/layout-header.component';
import { LayoutFooterComponent } from './shared/components/layout-footer/layout-footer.component';

import { AdjListComponent } from './components/adj-list/adj-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangeCourtDateComponent } from './components/change-court-date/change-court-date.component';

import { AdjournmentDetailComponent } from './pages/adjournment-detail/adjournment-detail.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SignInComponent } from '@adj/components/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { OverlayContainer } from '@angular/cdk/overlay';
import { InAppRootOverlayContainer } from './services/root-overlay.service';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { AdjournmentDetailsContainerComponent } from './containers/adjournment-details-container/adjournment-details-container.component';
import { ConfirmationPendingListingComponent } from './components/confirmation-pending-listing/confirmation-pending-listing.component';
import { CompletedRequestsListingComponent } from './components/completed-requests-listing/completed-requests-listing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RequestSearchResultsComponent } from './components/request-search-results/request-search-results.component';
import { DaysUntilPipe } from './pipes/days-until.pipe';
import { ShortAdjUidPipe } from './pipes/short-adj-uid.pipe';
import { MatchHighlightPipe } from './pipes/match-highlight.pipe';
import { DecisionConfirmationComponent } from './dialogs/decision-confirmation/decision-confirmation.component';
import { ExpireConfirmationComponent } from './dialogs/expire-confirmation/expire-confirmation.component';
import { DenyAppearanceTypeNoticeComponent } from './dialogs/deny-appearance-type-notice/deny-appearance-type-notice.component';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { NotificationComponent } from './components/notification/notification.component';
import { CalloutComponent } from './components/callout/callout.component';
import { DecisionNotificationComponent } from './components/decision-notification/decision-notification.component';
import { AcknowledgeExpireNotificationComponent } from './components/acknowledge-expire-notification/acknowledge-expire-notification.component';
import { ScenarioBuilderComponent } from './pages/scenario-builder/scenario-builder.component';
import { JoinListPipe } from './pipes/join-list.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { DocketNumbersComponent } from './components/docket-numbers/docket-numbers.component';
import { TableDetailsButtonComponent } from './components/table-details-button/table-details-button.component';
import { SearchResultDocketNumbersComponent } from './components/search-result-docket-numbers/search-result-docket-numbers.component';
import { TableStatusChipComponent } from './components/table-status-chip/table-status-chip.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { QuickViewComponent } from './components/quick-view/quick-view.component';
import { interceptorProviders } from './shared/interceptorProviders';
import { ViewMoreComponent } from './components/view-more/view-more.component';
import { BetaCalloutComponent } from './shared/components/beta-callout/beta-callout.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { UserSettingsComponent } from './pages/users/user-settings/user-settings.component';
import { EndorsementSheetComponent } from './components/endorsement-sheet/endorsement-sheet.component';
import { EndorsementSheetContainerComponent } from './containers/endorsement-sheet-container/endorsement-sheet-container.component';
import { PrintLayoutComponent } from './layouts/print-layout/print-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { PdfGeneratorComponent } from './components/pdf-generator/pdf-generator.component';
import { CapacityOfCounselPipe } from './pipes/capacity-of-counsel.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { AdjDateFormatPipe } from './pipes/adj-date-format.pipe';
import { AdjShortDateFormatPipe } from './pipes/adj-shortdate-format.pipe';
import { AdjDatetimeFormatPipe } from './pipes/adj-datetime-format.pipe';
import { AdjDatetimeFormat2Pipe } from './pipes/adj-datetime-format2.pipe';
import { AdjDateDashTimeFormatPipe } from './pipes/adj-datedashtime-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CountdownTimerPipe } from './pipes/countdown-timer.pipe';
import { CrownElectionPipe } from './pipes/crown-election.pipe';
import { DiversionPipe } from './pipes/diversion.pipe';
import { AppBannerComponent } from './shared/components/app-banner/app-banner.component';
import { KeycloakService } from 'keycloak-angular';
import { keyCloakInitializer } from './shared/authentication/keycloak.init';
import { MyAccountComponent } from './pages/users/my-account/my-account.component';
import { SignOutComponent } from './pages/users/sign-out/sign-out.component';
import { HomeComponent } from './pages/home/home.component';
import { DocketDetailListComponent } from './components/docket-detail-list/docket-detail-list.component';
import { AppRedirectComponent } from './pages/app-redirect/app-redirect.component';
import { HelpContactUsComponent } from './pages/users/help-contact-us/help-contact-us.component';
import { SystemUnavailableComponent } from './shared/components/system-unavailable/system-unavailable.component';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		// NoopAnimationsModule,
		BrowserAnimationsModule,
		MaterialModule,
		HttpClientModule,
	],
	declarations: [
		AppComponent,
		ChangeCourtDateComponent,
		LayoutHeaderComponent,
		LayoutFooterComponent,
		AppBannerComponent,
		AdjournmentDetailComponent,
		AdjListComponent,
		SignInComponent,
		ProgressSpinnerComponent,
		SpinnerOverlayComponent,
		AdjournmentDetailsContainerComponent,
		ConfirmationPendingListingComponent,
		CompletedRequestsListingComponent,
		DashboardComponent,
		RequestSearchResultsComponent,
		DaysUntilPipe,
		ShortAdjUidPipe,
		MatchHighlightPipe,
		DecisionConfirmationComponent,
		ExpireConfirmationComponent,
		DenyAppearanceTypeNoticeComponent,
		NumberSuffixPipe,
		NotificationComponent,
		CalloutComponent,
		DecisionNotificationComponent,
		AcknowledgeExpireNotificationComponent,
		ScenarioBuilderComponent,
		JoinListPipe,
		EllipsisPipe,
		DocketNumbersComponent,
		TableDetailsButtonComponent,
		SearchResultDocketNumbersComponent,
		TableStatusChipComponent,
		StatusBadgeComponent,
		QuickViewComponent,
		ViewMoreComponent,
		BetaCalloutComponent,
		NoResultsComponent,
		UserSettingsComponent,
		EndorsementSheetComponent,
		EndorsementSheetContainerComponent,
		PrintLayoutComponent,
		AppLayoutComponent,
		PdfGeneratorComponent,
		CapacityOfCounselPipe,
		PhonePipe,
		AdjDateFormatPipe,
		AdjShortDateFormatPipe,
		AdjDatetimeFormatPipe,
		AdjDatetimeFormat2Pipe,
		AdjDateDashTimeFormatPipe,
		MyAccountComponent,
		SafeHtmlPipe,
		CountdownTimerPipe,
		CrownElectionPipe,
		DiversionPipe,
		SignOutComponent,
		HomeComponent,
		DocketDetailListComponent,
		AppRedirectComponent,
		HelpContactUsComponent,
		SystemUnavailableComponent,
	],
	providers: [
		CookieService,
		ShortAdjUidPipe,
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfiguration,
			deps: [EnvConfigService],
			multi: true,
		},
		interceptorProviders,
		{ provide: OverlayContainer, useClass: InAppRootOverlayContainer },
		{
			provide: 'window',
			useValue: window,
		},
		KeycloakService,
		{
			provide: APP_INITIALIZER,
			useFactory: keyCloakInitializer,
			multi: true,
			deps: [EnvConfigService, KeycloakService],
		},
		SystemUnavailableComponent,
	],

	bootstrap: [AppComponent],
	entryComponents: [ProgressSpinnerComponent],
})
export class AppModule {}
