export class PropertyErrorModel {
	public propertyName: string = '';
	public errorCode: string = '';
	public message: string = '';
	public severity: string = '';

	constructor(init?: Partial<PropertyErrorModel>) {
		Object.assign(this, init);
	}
}
