export class Adjournment {
	id: number;
	uid: string;
	accusedFirstName: string;
	accusedLastName: string;
	accusedFullName: string;
	accusedEmail: string;
	accusedDateOfBirth: string;
	accusedMobilePhone: string;
	accusedReceiveSms: boolean;
	counselFullName: string;
	counselEmail: string;
	counselMobilePhone: string;
	counselReceiveSms: boolean;
	lawFirm: string;
	docketNumbers: string [];
	originalAppearanceDate: string;
	adjournToDate: string;
	reasonCode: number;
	locationId: number;
	location: string;
	reasonExplanation: string;
	specialInstructions: string;
	confirmAllCharges: boolean;
	isLatest: boolean;
	effectiveDate: string;
	endDate: string;
	adjournmentGroupUid: string;
	adjournmentStatusId: number;
	capacityOfCounselId: number;
	capacityOfCounsel: string;
	decisionNotes: string;
	jOINRef: string;
	createDate: string;
	createdBy: string;
	lastUpdateDate: string;
	lastUpdatedBy: string;
}
