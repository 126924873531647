


export class LogRequestModel {
    public serviceName: string = '';
    public msg: string = '';
    public user: string = '';
    public adjournmentUid: string = '';
    public timestamp: Date;

    constructor(init? : Partial<LogRequestModel>) {
        
        Object.assign(this, init);
    }
}
