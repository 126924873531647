


export class GetLocationWithScheduleModel {
    public courtDate: Date = new Date();
    public locationId: number = 0;
    public overrideOffset: number;
    public maxAdjPeriod: number;

    constructor(init? : Partial<GetLocationWithScheduleModel>) {
        
        Object.assign(this, init);
    }
}
