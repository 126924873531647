


export class AgentContactCardRequestModel {
    public agentUid: string = '';

    constructor(init? : Partial<AgentContactCardRequestModel>) {
        
        Object.assign(this, init);
    }
}
