import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-search-result-docket-numbers',
	templateUrl: './search-result-docket-numbers.component.html',
	styleUrls: ['./search-result-docket-numbers.component.scss'],
})
export class SearchResultDocketNumbersComponent implements OnInit {
	@Input() docketNumbers: string[];
	@Input() searchTerm: string;

	get hasMatch(): boolean {
		if (this.docketNumbers && this.searchTerm) {
			return this.matchingDocket != null;
		}

		return false;
	}

	get matchingDocket(): string {
		if (this.docketNumbers && this.searchTerm) {
			var match = this.docketNumbers.find((d) =>
				d.includes(this.searchTerm)
			);

			return match;
		}

		return "";
	}

	constructor() {}

	ngOnInit(): void {}
}
