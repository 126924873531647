import { Constants } from '@adj/common/models/helpers/ui-constants';
import { DocketListModel } from '@adj/generators/models/docket-list.model';
import { DocketModel } from '@adj/generators/models/docket.model';
import { EndorsementSheetModel } from '@adj/generators/models/endorsement-sheet.model';
import { IdModel } from '@adj/generators/models/id.model';
import { JoinResponseModel } from '@adj/generators/models/join-response.model';
import { JoinSearchRequestModel } from '@adj/generators/models/join-search-request.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { UidModel } from '@adj/generators/models/uid.model';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { JoinService } from '@adj/generators/services/join.service';
import { Join2Service } from '@adj/generators/services/join2.service';
import { LocationsService } from '@adj/generators/services/locations.service';
import { SettingsService } from '@adj/generators/services/settings.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocationCourtRoomModel } from '@adj/common/models/locations-api/shared-court-location.model';
import { CourtsService } from '@adj/generators/services/courts.service';
import { GetCourtRoomsRequestModel } from '@adj/generators/models/get-court-rooms-request.model';

@Component({
	selector: 'app-endorsement-sheet-container',
	templateUrl: './endorsement-sheet-container.component.html',
	styleUrls: ['./endorsement-sheet-container.component.scss'],
})
export class EndorsementSheetContainerComponent implements OnChanges {
	@Input() adjUid: string;

	settings: SettingsModel[];
	useR3Read: boolean = false; //by default we'll want to use the R4 functionality

	sheetDetails: EndorsementSheetModel;
	location: LocationModel;
	subscription$: Subscription;
	docketData: any; //make docketData generic because we could be getting two different types of responses based on which service we call.
	finishedLoading: boolean;
	courtRooms: LocationCourtRoomModel[] = []; //get courtrooms from JOIN

	constructor(
		private adjournmentService: AdjournmentsService,
		private locationService: LocationsService,
		private joinService: JoinService,
		private join2Service: Join2Service,		
		private settingsSvc: SettingsService,
		private courtsService: CourtsService,
	) {}


	async ngOnChanges(changes: SimpleChanges) {
		let change = changes["adjUid"];
		let uid = change.currentValue;
		this.refreshData(uid);
	}

	async refreshData(uid: string): Promise<boolean> {
		var result = await this.adjournmentService.getEndorsementSheetDetailsAsync(new UidModel({ uid: uid }))
		if(result.isSuccess){
			this.sheetDetails = result.payload
		}
		else{
			throw new Error('An error occurred getting endorsement sheet details.');
		}

		//assume the call will be successful
		this.location = await (await this.locationService.getLocationAsync(new IdModel({ id: this.sheetDetails.locationId }))).payload;

		//var settingsResults = await this.settingsSvc.getAllSettingsAsync();
		//this.settings = settingsResults.payload;

		//get courtroom list from Location Services
		let requestModel = new GetCourtRoomsRequestModel();
		requestModel.locationId = this.sheetDetails.locationId;
		let courtLocation = await this.courtsService.getCourtRoomsAsync(requestModel);          
		this.courtRooms = courtLocation.courtRooms;

    // no longer getting charges info from JOIN as it is not stored in our db
		//await this.initDocketDataAsync(); //Get docket info via R4

		console.log(this.docketData);
		this.finishedLoading = true;
		return true;
	}

	async initDocketDataAsync() {
		if (this.sheetDetails.docketDecisions.length > 0) {
			let request: JoinSearchRequestModel = new JoinSearchRequestModel();		
			request.accusedId = this.sheetDetails.accusedID; //pass along the accusedID	
			request.isTest = this.sheetDetails.isTest;//pass along the isTest flag from the DB

			//add the docket numbers to the request so we can do a SearchByDocketNumber request
			this.sheetDetails.docketDecisions.forEach(docket => {
				request.docketNumber.push(docket.docketNumber);
			});

			let docketResults = await this.join2Service.searchByDocketAsync(request);									
			this.docketData = docketResults.payload.results;
		}
	}

	async initR3DocketDataAsync() {
		
		if (this.sheetDetails.docketDecisions.length > 0) {
			let list: DocketListModel = new DocketListModel();

			//build the list of docket numbers to get the data from JOIN using searchByDocketNumber
			for (let matter of this.sheetDetails.docketDecisions) {
				var d = new DocketModel();
				d.docketNumber = matter.docketNumber;
				list.docketNumbers.push(d);
			}

			//we only need to get the docket info 1 time
			let joinSearchResults = await this.joinService.searchByDocketNumberAsync(list);			
			if(joinSearchResults.isSuccess === true){
				//filter out results based on accusedKey
				this.docketData = joinSearchResults.output.filter((obj) => obj.accusedKey == this.sheetDetails.accusedKey);
			}
			else{
				throw joinSearchResults.message;
			}							
		}
	}
}
