

import { DocketModel } from './docket.model';

export class DocketListModel {
    public docketNumbers: DocketModel[] = [];

    constructor(init? : Partial<DocketListModel>) {
        
        Object.assign(this, init);
    }
}
