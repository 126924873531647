

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { QueryResult } from '@adj/common/models/core-api/query-result';
import { AdjournmentSummaryModel } from '@adj/generators/models/adjournment-summary.model';
import { GetAdjournmentListRequestModel } from '@adj/generators/models/get-adjournment-list-request.model';
import { ConfirmationPendingAdjournmentModel } from '@adj/generators/models/confirmation-pending-adjournment.model';
import { CompletedAdjournmentModel } from '@adj/generators/models/completed-adjournment.model';
import { AdjSearchResultModel } from '@adj/generators/models/adj-search-result.model';
import { GetAdjSearchResultsRequestModel } from '@adj/generators/models/get-adj-search-results-request.model';
import { AdjournmentHistoryDetailModel } from '@adj/generators/models/adjournment-history-detail.model';
import { AdjournmentHistoryRequestModel } from '@adj/generators/models/adjournment-history-request.model';
import { AdjournmentModel } from '@adj/generators/models/adjournment.model';
import { UidModel } from '@adj/generators/models/uid.model';
import { EndorsementSheetModel } from '@adj/generators/models/endorsement-sheet.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';
import { SubmitAdjournmentRequestModel } from '@adj/generators/models/submit-adjournment-request.model';
import { SubmitAdjournmentModel } from '@adj/generators/models/submit-adjournment.model';
import { AdjournmentConfirmationModel } from '@adj/generators/models/adjournment-confirmation.model';
import { AdjournmentMatterResponseModel } from '@adj/generators/models/adjournment-matter-response.model';
import { AdjournmentActionInputModel } from '@adj/generators/models/adjournment-action-input.model';
import { AdjournmentActionModel } from '@adj/generators/models/adjournment-action.model';
import { AdjournmentAcknowledgeExpireRequestModel } from '@adj/generators/models/adjournment-acknowledge-expire-request.model';
import { GetAdjournmentByRequestIDRequestModel } from '@adj/generators/models/get-adjournment-by-request-idrequest.model';
import { DocketJoinHistoryModel } from '@adj/generators/models/docket-join-history.model';
import { DocketJoinHistoryRequestModel } from '@adj/generators/models/docket-join-history-request.model';

@Injectable({
    providedIn: 'root'
})
export class AdjournmentsService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('adjournments'));
    }

    
    public getAdjournmentsForReview(model: GetAdjournmentListRequestModel) : Observable<QueryResult<AdjournmentSummaryModel[]>> {
        return this.get('getAdjournmentsForReview', model);
    }

    public async getAdjournmentsForReviewAsync(model: GetAdjournmentListRequestModel) : Promise<QueryResult<AdjournmentSummaryModel[]>> {
        return await this.get<QueryResult<AdjournmentSummaryModel[]>, GetAdjournmentListRequestModel>('getAdjournmentsForReview', model).toPromise();
    }

    
    public getAdjournmentsAwaitingConfirmation(model: GetAdjournmentListRequestModel) : Observable<QueryResult<ConfirmationPendingAdjournmentModel[]>> {
        return this.get('getAdjournmentsAwaitingConfirmation', model);
    }

    public async getAdjournmentsAwaitingConfirmationAsync(model: GetAdjournmentListRequestModel) : Promise<QueryResult<ConfirmationPendingAdjournmentModel[]>> {
        return await this.get<QueryResult<ConfirmationPendingAdjournmentModel[]>, GetAdjournmentListRequestModel>('getAdjournmentsAwaitingConfirmation', model).toPromise();
    }

    
    public getCompletedAdjournments(model: GetAdjournmentListRequestModel) : Observable<QueryResult<CompletedAdjournmentModel[]>> {
        return this.get('getCompletedAdjournments', model);
    }

    public async getCompletedAdjournmentsAsync(model: GetAdjournmentListRequestModel) : Promise<QueryResult<CompletedAdjournmentModel[]>> {
        return await this.get<QueryResult<CompletedAdjournmentModel[]>, GetAdjournmentListRequestModel>('getCompletedAdjournments', model).toPromise();
    }

    
    public search(model: GetAdjSearchResultsRequestModel) : Observable<QueryResult<AdjSearchResultModel[]>> {
        return this.get('search', model);
    }

    public async searchAsync(model: GetAdjSearchResultsRequestModel) : Promise<QueryResult<AdjSearchResultModel[]>> {
        return await this.get<QueryResult<AdjSearchResultModel[]>, GetAdjSearchResultsRequestModel>('search', model).toPromise();
    }

    
    public getAdjournmentDetailHistory(model: AdjournmentHistoryRequestModel) : Observable<QueryResult<AdjournmentHistoryDetailModel[]>> {
        return this.get('getAdjournmentDetailHistory', model);
    }

    public async getAdjournmentDetailHistoryAsync(model: AdjournmentHistoryRequestModel) : Promise<QueryResult<AdjournmentHistoryDetailModel[]>> {
        return await this.get<QueryResult<AdjournmentHistoryDetailModel[]>, AdjournmentHistoryRequestModel>('getAdjournmentDetailHistory', model).toPromise();
    }

    
    public getAdjournmentDetail(model: UidModel) : Observable<QueryResult<AdjournmentModel>> {
        return this.get('getAdjournmentDetail', model);
    }

    public async getAdjournmentDetailAsync(model: UidModel) : Promise<QueryResult<AdjournmentModel>> {
        return await this.get<QueryResult<AdjournmentModel>, UidModel>('getAdjournmentDetail', model).toPromise();
    }

    
    public getEndorsementSheetDetails(model: UidModel) : Observable<QueryResult<EndorsementSheetModel>> {
        return this.get('getEndorsementSheetDetails', model);
    }

    public async getEndorsementSheetDetailsAsync(model: UidModel) : Promise<QueryResult<EndorsementSheetModel>> {
        return await this.get<QueryResult<EndorsementSheetModel>, UidModel>('getEndorsementSheetDetails', model).toPromise();
    }

    
    public getAdjournmentDetailAndLockIt(model: UidModel) : Observable<CommandResult<AdjournmentModel>> {
        return this.post('getAdjournmentDetailAndLockIt', model);
    }

    public async getAdjournmentDetailAndLockItAsync(model: UidModel) : Promise<CommandResult<AdjournmentModel>> {
        return await this.post<CommandResult<AdjournmentModel>, UidModel>('getAdjournmentDetailAndLockIt', model).toPromise();
    }

    
    public unlockAdjournment(model: UidModel) : Observable<CommandResult<AdjournmentModel>> {
        return this.post('unlockAdjournment', model);
    }

    public async unlockAdjournmentAsync(model: UidModel) : Promise<CommandResult<AdjournmentModel>> {
        return await this.post<CommandResult<AdjournmentModel>, UidModel>('unlockAdjournment', model).toPromise();
    }

    
    public submitAdjournment(model: SubmitAdjournmentModel) : Observable<CommandResult<SubmitAdjournmentRequestModel>> {
        return this.post('submitAdjournment', model);
    }

    public async submitAdjournmentAsync(model: SubmitAdjournmentModel) : Promise<CommandResult<SubmitAdjournmentRequestModel>> {
        return await this.post<CommandResult<SubmitAdjournmentRequestModel>, SubmitAdjournmentModel>('submitAdjournment', model).toPromise();
    }

    
    public confirm(model: UidModel) : Observable<CommandResult<AdjournmentConfirmationModel>> {
        return this.post('confirm', model);
    }

    public async confirmAsync(model: UidModel) : Promise<CommandResult<AdjournmentConfirmationModel>> {
        return await this.post<CommandResult<AdjournmentConfirmationModel>, UidModel>('confirm', model).toPromise();
    }

    
    public grant(model: AdjournmentActionInputModel) : Observable<CommandResult<AdjournmentMatterResponseModel>> {
        return this.post('grant', model);
    }

    public async grantAsync(model: AdjournmentActionInputModel) : Promise<CommandResult<AdjournmentMatterResponseModel>> {
        return await this.post<CommandResult<AdjournmentMatterResponseModel>, AdjournmentActionInputModel>('grant', model).toPromise();
    }

    
    public reject(model: AdjournmentActionInputModel) : Observable<CommandResult<AdjournmentActionModel>> {
        return this.post('reject', model);
    }

    public async rejectAsync(model: AdjournmentActionInputModel) : Promise<CommandResult<AdjournmentActionModel>> {
        return await this.post<CommandResult<AdjournmentActionModel>, AdjournmentActionInputModel>('reject', model).toPromise();
    }

    
    public acknowledgeExpire(model: AdjournmentAcknowledgeExpireRequestModel) : Observable<CommandResult<AdjournmentActionModel>> {
        return this.post('acknowledgeExpire', model);
    }

    public async acknowledgeExpireAsync(model: AdjournmentAcknowledgeExpireRequestModel) : Promise<CommandResult<AdjournmentActionModel>> {
        return await this.post<CommandResult<AdjournmentActionModel>, AdjournmentAcknowledgeExpireRequestModel>('acknowledgeExpire', model).toPromise();
    }

    
    public getAdjournmentByRequestID(model: GetAdjournmentByRequestIDRequestModel) : Observable<CommandResult<AdjournmentModel>> {
        return this.post('getAdjournmentByRequestID', model);
    }

    public async getAdjournmentByRequestIDAsync(model: GetAdjournmentByRequestIDRequestModel) : Promise<CommandResult<AdjournmentModel>> {
        return await this.post<CommandResult<AdjournmentModel>, GetAdjournmentByRequestIDRequestModel>('getAdjournmentByRequestID', model).toPromise();
    }

    
    public getDocketJoinHistory(model: DocketJoinHistoryRequestModel) : Observable<QueryResult<DocketJoinHistoryModel>> {
        return this.get('getDocketJoinHistory', model);
    }

    public async getDocketJoinHistoryAsync(model: DocketJoinHistoryRequestModel) : Promise<QueryResult<DocketJoinHistoryModel>> {
        return await this.get<QueryResult<DocketJoinHistoryModel>, DocketJoinHistoryRequestModel>('getDocketJoinHistory', model).toPromise();
    }

    
}
