


export class GetAdjournmentListRequestModel {
    public page: number = 0;
    public pageSize: number = 0;
    public locationID: number;
    public isTest: boolean = false;

    constructor(init? : Partial<GetAdjournmentListRequestModel>) {
        
        Object.assign(this, init);
    }
}
