export class AdjournmentHistory {
   
   	id: number;
    uid: string;
    adjournmentGroupUid: string;
    locationId: number;
	location: string;
	docketNumber: string;
    originalAppearanceDate: Date;
    adjournToDate: Date;
    accusedFullName: string;
    
    adjReasonId: number;
    adjReason: string;
    reasonCode: number;
    reasonExplanation: string;
    accusedMobilePhone:string;
    joinRef: string;
    createDate: Date;
    lastUpdateDate: Date;
    numberOfDays: number;

    statusId: number;
    specialInstructions: string;

}