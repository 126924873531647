

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { QueryResult } from '@adj/common/models/core-api/query-result';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';
import { GetAngularConfigResponseModel } from '@adj/generators/models/get-angular-config-response.model';
import { GetAngularConfigRequestModel } from '@adj/generators/models/get-angular-config-request.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('settings'));
    }

    
    public getAllSettings() : Observable<QueryResult<SettingsModel[]>> {
        return this.get('getAllSettings');
    }

    public async getAllSettingsAsync() : Promise<QueryResult<SettingsModel[]>> {
        return await this.get<QueryResult<SettingsModel[]>, null>('getAllSettings').toPromise();
    }

    
    public addSettings(model: SettingsModel) : Observable<CommandResult<boolean>> {
        return this.post('addSettings', model);
    }

    public async addSettingsAsync(model: SettingsModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, SettingsModel>('addSettings', model).toPromise();
    }

    
    public updateSettings(model: SettingsModel) : Observable<CommandResult<boolean>> {
        return this.post('updateSettings', model);
    }

    public async updateSettingsAsync(model: SettingsModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, SettingsModel>('updateSettings', model).toPromise();
    }

    
    public deleteSetting(model: SettingsModel) : Observable<CommandResult<boolean>> {
        return this.post('deleteSetting', model);
    }

    public async deleteSettingAsync(model: SettingsModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, SettingsModel>('deleteSetting', model).toPromise();
    }

    
    public getSettingByKey(model: GetSettingByKeyRequestModel) : Observable<QueryResult<SettingsModel>> {
        return this.get('getSettingByKey', model);
    }

    public async getSettingByKeyAsync(model: GetSettingByKeyRequestModel) : Promise<QueryResult<SettingsModel>> {
        return await this.get<QueryResult<SettingsModel>, GetSettingByKeyRequestModel>('getSettingByKey', model).toPromise();
    }

    
    public getAngularConfig(model: GetAngularConfigRequestModel) : Observable<QueryResult<GetAngularConfigResponseModel>> {
        return this.get('getAngularConfig', model);
    }

    public async getAngularConfigAsync(model: GetAngularConfigRequestModel) : Promise<QueryResult<GetAngularConfigResponseModel>> {
        return await this.get<QueryResult<GetAngularConfigResponseModel>, GetAngularConfigRequestModel>('getAngularConfig', model).toPromise();
    }

    
}
