

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { CommandResult } from '@adj/common/models/core-api/command-result';
import { AddUpdateUserSettingsModel } from '@adj/generators/models/add-update-user-settings.model';
import { QueryResult } from '@adj/common/models/core-api/query-result';
import { GetUserSettingsResponseModel } from '@adj/generators/models/get-user-settings-response.model';
import { GetUserSettingsRequestModel } from '@adj/generators/models/get-user-settings-request.model';
import { ListUserResponseModel } from '@adj/generators/models/list-user-response.model';
import { UserModel } from '@adj/generators/models/user.model';
import { GetUserByKeycloakUserIdQueryRequestModel } from '@adj/generators/models/get-user-by-keycloak-user-id-query-request.model';
import { AddUserCommandRequestModel } from '@adj/generators/models/add-user-command-request.model';
import { ActivateUserAddClerkRoleRequestModel } from '@adj/generators/models/activate-user-add-clerk-role-request.model';
import { UserLastLoginUpdateModel } from '@adj/generators/models/user-last-login-update.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('user'));
    }

    
    public addUpdateSettings(model: AddUpdateUserSettingsModel) : Observable<CommandResult<boolean>> {
        return this.post('addUpdateSettings', model);
    }

    public async addUpdateSettingsAsync(model: AddUpdateUserSettingsModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AddUpdateUserSettingsModel>('addUpdateSettings', model).toPromise();
    }

    
    public getSettings(model: GetUserSettingsRequestModel) : Observable<QueryResult<GetUserSettingsResponseModel>> {
        return this.get('getSettings', model);
    }

    public async getSettingsAsync(model: GetUserSettingsRequestModel) : Promise<QueryResult<GetUserSettingsResponseModel>> {
        return await this.get<QueryResult<GetUserSettingsResponseModel>, GetUserSettingsRequestModel>('getSettings', model).toPromise();
    }

    
    public getUserList() : Observable<QueryResult<ListUserResponseModel[]>> {
        return this.get('getUserList');
    }

    public async getUserListAsync() : Promise<QueryResult<ListUserResponseModel[]>> {
        return await this.get<QueryResult<ListUserResponseModel[]>, null>('getUserList').toPromise();
    }

    
    public getUserByKeycloakUserId(model: GetUserByKeycloakUserIdQueryRequestModel) : Observable<QueryResult<UserModel>> {
        return this.get('getUserByKeycloakUserId', model);
    }

    public async getUserByKeycloakUserIdAsync(model: GetUserByKeycloakUserIdQueryRequestModel) : Promise<QueryResult<UserModel>> {
        return await this.get<QueryResult<UserModel>, GetUserByKeycloakUserIdQueryRequestModel>('getUserByKeycloakUserId', model).toPromise();
    }

    
    public addUser(model: AddUserCommandRequestModel) : Observable<CommandResult<UserModel>> {
        return this.post('addUser', model);
    }

    public async addUserAsync(model: AddUserCommandRequestModel) : Promise<CommandResult<UserModel>> {
        return await this.post<CommandResult<UserModel>, AddUserCommandRequestModel>('addUser', model).toPromise();
    }

    
    public getUsersWithActiveStatus() : Observable<QueryResult<UserModel[]>> {
        return this.get('getUsersWithActiveStatus');
    }

    public async getUsersWithActiveStatusAsync() : Promise<QueryResult<UserModel[]>> {
        return await this.get<QueryResult<UserModel[]>, null>('getUsersWithActiveStatus').toPromise();
    }

    
    public getUsersWithDeactiveStatus() : Observable<QueryResult<UserModel[]>> {
        return this.get('getUsersWithDeactiveStatus');
    }

    public async getUsersWithDeactiveStatusAsync() : Promise<QueryResult<UserModel[]>> {
        return await this.get<QueryResult<UserModel[]>, null>('getUsersWithDeactiveStatus').toPromise();
    }

    
    public getUsersWithAccessRequestedStatus() : Observable<QueryResult<UserModel[]>> {
        return this.get('getUsersWithAccessRequestedStatus');
    }

    public async getUsersWithAccessRequestedStatusAsync() : Promise<QueryResult<UserModel[]>> {
        return await this.get<QueryResult<UserModel[]>, null>('getUsersWithAccessRequestedStatus').toPromise();
    }

    
    public getUsersWithMigrationStatus() : Observable<QueryResult<UserModel[]>> {
        return this.get('getUsersWithMigrationStatus');
    }

    public async getUsersWithMigrationStatusAsync() : Promise<QueryResult<UserModel[]>> {
        return await this.get<QueryResult<UserModel[]>, null>('getUsersWithMigrationStatus').toPromise();
    }

    
    public activateUserAndAddClerkRole(model: ActivateUserAddClerkRoleRequestModel) : Observable<CommandResult<UserModel>> {
        return this.post('activateUserAndAddClerkRole', model);
    }

    public async activateUserAndAddClerkRoleAsync(model: ActivateUserAddClerkRoleRequestModel) : Promise<CommandResult<UserModel>> {
        return await this.post<CommandResult<UserModel>, ActivateUserAddClerkRoleRequestModel>('activateUserAndAddClerkRole', model).toPromise();
    }

    
    public updateUserLastLoginDate(model: UserLastLoginUpdateModel) : Observable<CommandResult<boolean>> {
        return this.post('updateUserLastLoginDate', model);
    }

    public async updateUserLastLoginDateAsync(model: UserLastLoginUpdateModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, UserLastLoginUpdateModel>('updateUserLastLoginDate', model).toPromise();
    }

    
}
