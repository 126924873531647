import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AdjournmentSummary, AdjournmentSearchResult } from '@adj/common/models';
import { MatTableDataSource } from '@angular/material/table';
import { AdjSearchResultModel } from '@adj/generators/models/adj-search-result.model';
import { ConfirmationPendingAdjournmentModel } from '@adj/generators/models/confirmation-pending-adjournment.model';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';

@Component({
	selector: 'app-request-search-results',
	templateUrl: './request-search-results.component.html',
	styleUrls: ['./request-search-results.component.scss'],
})
export class RequestSearchResultsComponent implements OnInit {
	displayedColumns: string[] = [
		'daysUntilAppearance',
		'dateRequested',
		'docketNumber',
		'accusedFullName',
		'status',
		'requestId',
		'details',
	];

	@Input() searchTerm: string = '';
	@Input() hasNoSecurityRoles:Boolean = true;
	@Input() hasNoSecurityRoleForLocation:Boolean=true;
	@Input() currentUser: AuthenticatedUserProfileKeycloakModel;
	_searchResults: AdjSearchResultModel[] = [];
	searchResultsDataSource: MatTableDataSource<AdjSearchResultModel>;
	@Input() set searchResults(value: AdjSearchResultModel[]) {
		this._searchResults = value;
		this.searchResultsDataSource = new MatTableDataSource(value || []);
	}
	
	@Output() openQuickView: EventEmitter<string> = new EventEmitter<string>();
	
	ngOnInit() {

		
	}

	onOpenQuickView(row: ConfirmationPendingAdjournmentModel){
		this.openQuickView.emit(row.uid);
	}
}
