import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
	providedIn: 'root',
})
export class EnvConfigService {
	private config: any;

	constructor(private http: HttpClient) {
		var configCached= localStorage.getItem('config-cached-adj-clerk-app');
		if (configCached !=null)
			this.config= JSON.parse(configCached);
		else
			this.load();
	}

	get(key: string) {
		return this.config[key];
	}

	load() {
		const envConfigFilePath = `./assets/config/configMap/config.json`;
		return this.http
		.get<any>(envConfigFilePath)
		.toPromise()
		.then(response => {
			localStorage.removeItem('config-cached-adj-clerk-app');
			localStorage.setItem('config-cached-adj-clerk-app', JSON.stringify(response));
			this.config = response;
		});
		
	}
}

export function loadConfiguration(config: EnvConfigService) {
	return () => config.load();
}
