export class AdjournmentRequest {
	id: number;
	uid: string;
	location: number;
	docketNumber: string;
	setCourtDate: Date;
	reasonCode: number;
	reasonExplanation: string;
	adjournToDate: Date;
	specialInstructions: string;
	confimAllCharges: boolean;

	counselFirstName: string;
	counselMiddleName: string;
	counselLastName: string;
	lawFirm: string;
	counselEmailAddress: string;
	counselMobileNumber: string;
	counselReceiveSMS: boolean;

	accusedFirstName: string;
	accusedMiddleName: string;
	accusedLastName: string;
	accusedDOB: Date;
	accusedEmail: string;
	accusedMobileNumber: string;
	accusedReceiveSMS: boolean
}
