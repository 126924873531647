import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diversion'
})
export class DiversionPipe implements PipeTransform {

  transform(value: string): string {
    return this.diversionEligibilityDescription(value);
  }


  diversionEligibilityDescription(code){
	
	if (code === null)
		return "(Not indicated)";;
	let retVal = code;
	switch(code.toUpperCase()){

		case "A" : 
			retVal = "AMP";
			break;
		case "M": 
			retVal = "Mental health";
			break;
		case "N": 
			retVal = "No";
			break;
		case "NOT AVAILABLE": 
			retVal = "Not available";
			break;
	}

	return retVal;
}

}
