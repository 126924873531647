import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvConfigService } from '@adj/common/services/env-config.service';
import { AuthenticatedUser } from '@adj/common/models/authenticated-user.model';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { Headers } from '@adj/shared/models/headers.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService extends EndPointService {
	private adjApiBaseUrl: string;
	currentUserValue: any;

	constructor(http: HttpClient, envConfig: EnvConfigService) {
		super(http, envConfig.get('user'));
	}

	authenticate(encodedCredentials: string): Observable<AuthenticatedUserModel> {
		var headers = new Headers();
		headers.header["Authorization"] = encodedCredentials;

		return this.get('Authenticate', null, headers.header);
	}

	authenticateAsync(encodedCredentials: string): Promise<CommandResult<AuthenticatedUserModel>> {
		var headers = new Headers();
		headers.header["Authorization"] = encodedCredentials;
		
		return this.get<CommandResult<AuthenticatedUserModel>, null>('authenticate', null, headers.header).toPromise();
	}
}
