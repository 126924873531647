import { VersionService } from '@adj/generators/services/version.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {

	version$: Observable<string>;
	environment$: Observable<string>;


	constructor(private titleService: Title, private _versionService: VersionService) {
		this.version$ = this._versionService.getVersion();
		this.environment$ = this._versionService.getEnvironment();			
	}

	public setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	}

}
