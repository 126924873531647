<!-- Popup modal -->
<div id="deny-appearance-type-prompt" uk-modal class="uk-padding-remove">
	<div class="uk-modal-dialog uk-margin-auto-vertical expire-modal">
		<div class="uk-modal-body">
			<table class="uk-table uk-table-divider uk-table-custom">
				<tbody>
				<tr> 
					<td class="uk-table-shrink header-primary morePadding">
					<mat-icon>info_outlined</mat-icon>
					</td>
					<td class="denyAppearanceType">
						<button class="uk-modal-close-default" type="button" uk-close></button>
						<h3>No changes to appearance type(s) upon deny</h3>
						<p>
							You have changed the appearance type(s) on one or multiple charges within this request, and made a decision to deny the adjournment request. Please be aware that ADS does not update the appearance type in JOIN when you deny an adjournment request.
						</p>
					</td>
				</tr>
				</tbody>
			</table>
			
		</div>
	</div>
</div>




