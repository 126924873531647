import { Component } from '@angular/core';
import { OverlayService } from '@adj/services/overlay.service';
import { HttpRequestListenerService } from '@adj/services/http-request-listener.service';
import { AfterViewInit } from '@angular/core';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';

@Component({
	selector: 'app-spinner-overlay',
	template: ``,
	styles: [],
})
export class SpinnerOverlayComponent implements AfterViewInit {
	requestPending: boolean;

	constructor(
		private previewProgressSpinner: OverlayService,
		private httpRequestService: HttpRequestListenerService
	) {}

	ngAfterViewInit() {
		this.httpRequestService.httpProgress().subscribe((status: boolean) => {
			if (status) {
				let overlayRef = this.previewProgressSpinner.open(
					{ hasBackdrop: true },
					ProgressSpinnerComponent
				);
			} else {
				this.previewProgressSpinner.hide();
			}
		});
	}
}
