<div *ngIf="adjournment else loadingList">
	<div *ngFor="let docket of adjournment.docketDecisions; let idx = index; let isFirst = first;" 
		class="uk-width-1-1"
		[ngClass]="(docket.docketNumber == activeDocket)?'smallCardSectionActive':'smallCardSection'">
		<div [ngClass]="(docket.docketNumber == activeDocket)?'smallCardSectionThinTopActive':'smallCardSectionThinTop'">
		</div>
		<div class="smallCardSectionContent">
			<!-- <div class="docketCounter">{{ idx+1 }} </div> -->
			<div class="uk-card uk-padding-small">
				<p class="noMarginBottom" style="margin-top:-5px;">{{ idx+1 + ") "}}{{ docket.docketNumber }}</p>
				<div *ngFor="let charge of docket.charges;" class="charge-block">
					{{charge.chargeActCode}} {{ charge.chargeCode }} - {{ charge.description }} ({{ charge.crownElectionDisplay }})
				</div>
			</div>
		</div>
	</div>	
</div>

<ng-template #loadingList>
	<p>Loading docket details...</p>
</ng-template>
