/**
 * The PDF exporter doesn't like our typography settings, so we have to redefine them here.
 *
 * @see typography.scss
 */
export const pdfFonts: { [fontName: string]: string } = {
	'acumin-pro-semi-condensed': 'https://use.typekit.net/af/3f7b4d/00000000000000003b9acb2d/27/a?subset_id=1&fvd=n4&v=3',
	'acumin-pro-semi-condensed|Italic':
		'https://use.typekit.net/af/761912/00000000000000003b9acb2e/27/a?subset_id=1&fvd=i4&v=3',
	'acumin-pro-semi-condensed|Bold': 'https://use.typekit.net/af/ee7f3d/00000000000000003b9acb33/27/a?subset_id=1&fvd=n7&v=3',
	'acumin-pro-semi-condensed|Bold|Italic':
		'https://use.typekit.net/af/503f80/00000000000000003b9acb34/27/a?subset_id=1&fvd=i7&v=3',
};
