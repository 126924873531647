


export class AddUserCommandRequestModel {
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';

    constructor(init? : Partial<AddUserCommandRequestModel>) {
        
        Object.assign(this, init);
    }
}
