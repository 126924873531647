

import { DocketDecisionModel } from './docket-decision.model';

export class AdjournmentActionInputModel {
    public uid: string = '';
    public decisionNotes: string = '';
    public updatedAdjournToDate: Date;
    public docketDecisions: DocketDecisionModel[] = [];
    public manualGrant: boolean;

    constructor(init? : Partial<AdjournmentActionInputModel>) {
        
        Object.assign(this, init);
    }
}
