


export class GetAdjSearchResultsRequestModel {
    public searchTerm: string = '';
    public page: number = 0;
    public pageSize: number = 0;

    constructor(init? : Partial<GetAdjSearchResultsRequestModel>) {
        
        Object.assign(this, init);
    }
}
