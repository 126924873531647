import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		if (value && value.length === 10) {
			var areaCode = value.slice(0, 3);
			var first3 = value.slice(3, 6);
			var last4 = value.slice(6);

			return '1' + ' (' + areaCode + ') ' + first3 + '-' + last4;
		}
    else if (value && value.length === 11) {
			var areaCode = value.slice(1, 4);
			var first3 = value.slice(4, 7);
			var last4 = value.slice(7);

			return '1' + ' (' + areaCode + ') ' + first3 + '-' + last4;
		}

		return value;
	}
}
