<app-layout-header headerType="admin"></app-layout-header>
<section class="uk-section uk-padding-remove">
	<div
		class="uk-section uk-container uk-container-medium uk-padding-remove uk-margin-medium-top"
	>
		<h2>Help and Contact</h2>
	</div>
	<div
		class="uk-section uk-container uk-container-medium uk-padding-remove uk-margin-top"
	>
		<p>
			For more information on how to use Adjournment Digital Service,
			please refer to the Clerk Guide below
		</p>
	</div>
	<div
		class="uk-section uk-container uk-container-medium uk-padding-remove uk-margin-top"
	>
		<div class="uk-float-left" uk-grid>
			<div>
				<button
					class="uk-button uk-border-rounded button-secondary uk-modal-close uk-text-bold"
					style="border: 2px solid !important"
					type="button"
					(click)="showClerkGuide()"
				>
					ADS Clerk Guide (PDF)
				</button>
			</div>
		</div>
	</div>
	<div
		class="uk-section uk-container uk-container-medium uk-padding-remove uk-margin-medium-top"
	>
		<hr />
	</div>
	<div
		class="uk-section uk-container uk-container-medium uk-padding-remove uk-margin-medium-top"
	>
		<p>
			If you have any issues or questions regarding the Adjournments
			Digital Service, please contact
			<a
				href="mailto:JSG.AdjournmentSupport@gov.ab.ca"
				style="text-decoration: underline"
				>JSG.AdjournmentSupport@gov.ab.ca</a
			>
		</p>
	</div>
</section>
