import { Component, OnInit } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-court-date',
  templateUrl: './change-court-date.component.html',
  styleUrls: ['./change-court-date.component.scss']
})
export class ChangeCourtDateComponent implements OnInit {
  adjReasons;
  adjBy;
  location: string;
  actionNumber: string;
  originalDate: Date;
  originalDateString: string;
  adjournedDate: Date;
  reason: string;
  adjournedBy: string; 
  smsNumber:string;
  adjournedDateString: any;

  constructor(_icon: MatIconModule,private titleSvc: Title) { }

  


  ngOnInit(): void { 
    this.location = "";
    this.actionNumber = "";
    this.originalDate = new Date();
    this.originalDateString = "";
    this.adjournedDate = new Date();
    this.reason= "";
    this.adjournedBy= "";
    this.smsNumber= "";
    this.setTitle("Justice Digital - Adjournment Demo")
  }

  public setTitle( newTitle: string) {
    this.titleSvc.setTitle( newTitle );
  }
}
