import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AdjournmentDecision } from '@adj/common/models/adjournment-decision.model';
import { CookieService } from 'ngx-cookie-service';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { AdjournmentAcknowledgeExpireRequestModel } from '@adj/generators/models/adjournment-acknowledge-expire-request.model';
import { AdjournmentActionModel } from '@adj/generators/models/adjournment-action.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';

import { NotificationComponent } from '@adj/components/notification/notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CodeType } from '@adj/common/models';
import { CodesService } from '@adj/services/codes.service';
import * as UIkit from 'uikit';
import { AdjournmentDetailComponent } from '@adj/pages/adjournment-detail/adjournment-detail.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';

@Component({
	selector: 'app-expire-confirmation',
	templateUrl: './expire-confirmation.component.html',
	styleUrls: ['./expire-confirmation.component.scss'],
})
export class ExpireConfirmationComponent implements OnInit {
	decision: AdjournmentDecision;
	
	//used to display current user name on Modal confirm page
	public currentUser:Promise<AuthenticatedUserProfileKeycloakModel>;
  	submitResult: CommandResult<AdjournmentActionModel> = null;
	reasonCodes: CodeType[];
	reasonText: string;

	constructor(
		private _codesService: CodesService,		
		private adjSvc: AdjournmentsService,
		private authenticationService:AuthenticationService,
		private _snackBar: MatSnackBar,
		@Inject(AdjournmentDetailComponent) private parent: AdjournmentDetailComponent,
	) {
	}

	async ngOnInit(){ 
		this.currentUser = this.authenticationService.getCurrentUser();
	}


	async showModal(adjDecision: AdjournmentDecision){
		this.decision = adjDecision;

		//get reason text
		let reasons: any = await this._codesService.getAdjournmentReasons();
		if (reasons) {
			this.reasonCodes = reasons.payload;
			var reasonCodeType = this.reasonCodes.find(
				(r) => r.code === this.decision.reasonCode
			);

			if (reasonCodeType) {
				this.reasonText = reasonCodeType.value;
			}
		}

		UIkit.modal("#expire-prompt").show();
	}

	async submit() {

		let decisionRequest = new AdjournmentAcknowledgeExpireRequestModel({
			uid: this.decision.uid,
		});

		this.acknowledgeExpiredAdjournment(decisionRequest);
	}

	async acknowledgeExpiredAdjournment(decisionModel: AdjournmentAcknowledgeExpireRequestModel) {
		try {
			this.submitResult = await this.adjSvc.acknowledgeExpireAsync(decisionModel);
			//this.hideModal(); // this does not work for subsequent expire requests;  use uk-modal-close attribute instead
			if (this.submitResult.isSuccess)
			{
				this.parent.expireAdjournmentAcknowledged();
			}
			else{
				this.showErrorNotification();
			}
		} catch (ex) {
			console.log(ex);
			this.hideModal();
			this.showErrorNotification();
		}
	}

	showErrorNotification() {
		this._snackBar.openFromComponent(NotificationComponent, {
			data: {
				message: 'There was an error while acknowleding the expired Adjournment.',
			},
			horizontalPosition: 'center',
			verticalPosition: 'top',
		});
	}
		
	hideModal(){
		UIkit.modal("#expire-prompt").hide();
	}
	
}
