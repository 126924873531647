


export class GetEventActionsCommandRequestModel {
    public docketType: string = '';

    constructor(init? : Partial<GetEventActionsCommandRequestModel>) {
        
        Object.assign(this, init);
    }
}
