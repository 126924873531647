import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';

import { debounceTime, filter, map } from 'rxjs/operators';
import { Observable, fromEvent, timer, Subscription, BehaviorSubject } from 'rxjs';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { AdjournmentSummaryModel } from '@adj/generators/models/adjournment-summary.model';
import { ConfirmationPendingAdjournmentModel } from '@adj/generators/models/confirmation-pending-adjournment.model';
import { CompletedAdjournmentModel } from '@adj/generators/models/completed-adjournment.model';
import { AdjSearchResultModel } from '@adj/generators/models/adj-search-result.model';
import { GetAdjournmentListRequestModel } from '@adj/generators/models/get-adjournment-list-request.model';
import { GetAdjSearchResultsRequestModel } from '@adj/generators/models/get-adj-search-results-request.model';
import { MatDrawer } from '@angular/material/sidenav';

import { Title } from '@angular/platform-browser';
import moment from 'moment';
import * as UIkit from 'uikit';
import { GetUserSettingsRequestModel } from '@adj/generators/models/get-user-settings-request.model';
import { UserService } from '@adj/generators/services/user.service';
import { GetUserSettingsResponseModel } from '@adj/generators/models/get-user-settings-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AdjListComponent } from '@adj/components/adj-list/adj-list.component';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetUserByKeycloakUserIdQueryRequestModel } from '@adj/generators/models/get-user-by-keycloak-user-id-query-request.model';
import { NotificationComponent } from '@adj/components/notification/notification.component';
import { JoinGetUserSecurityRequestModel } from '@adj/generators/models/join-get-user-security-request.model';
import { Join2Service } from '@adj/generators/services/join2.service';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

	displayedColumns: string[] = [
		'daysUntilAppearance',
		'dateRequested',
		'docketNumber',
		'accusedFullName',
		'chargeNumber',
		'requestId',
		'details',
	];

	
	@ViewChild(AdjListComponent) reviewList: AdjListComponent;

	@ViewChild(MatDrawer) matDrawer: MatDrawer;
	@ViewChild('searchField', { static: false }) searchFieldElRef: ElementRef;
	searchKeyUpEvents$: Observable<any>;
	searchTerm: string;
	searchResults: AdjSearchResultModel[] = [];
	readonly minSearchTermLength: number = 3;
	adjournmentsForReview: AdjournmentSummaryModel[] = [];
	confirmationPendingAdjournments: ConfirmationPendingAdjournmentModel[] = [];
	completedAdjournments: CompletedAdjournmentModel[] = [];
	searching: boolean = false;
	timerSubscription$: Subscription;
	userSettings$: GetUserSettingsResponseModel;
	selectedAdjournmentUid: string;
	userLocation: string;
	userLocationId: number = -1;
	activeTabIndex: number = 1; //default to Review Pending 
	//keep track of new adjournments to notify the user about
	activeNotificationAdjournments: AdjournmentSummaryModel[] = [];
	isUserLocationLoaded: boolean = false;
	currentUser: AuthenticatedUserProfileKeycloakModel;
	clerkSecurityRoles: string = "None";
	hasNoSecurityRoles:Boolean = false;
	hasNoSecurityRoleForLocation:Boolean=false;
	constructor(
		private titleSvc: Title,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private adjournmentsService: AdjournmentsService,
		private route: ActivatedRoute,
		private location: Location,
		private cookieService: CookieService,
		private snackBar:MatSnackBar,
		private router: Router,		
		private joinService: Join2Service,
	) { }

	async ngOnInit() {
		this.titleSvc.setTitle("Adjournment Digital Service");

		this.currentUser = await this.authenticationService.getCurrentUser();

		//do a quick check to see if a keycloak user registration exists but the user doesn't exist in ADS
		//this only happens to our team because we have 3 environments DEV,TEST,UAT using one keycloak UAT user
		await this.checkForBadUserRoleStateWhereKeycloakExistsButNotUser();
		
		var settingsResult = await this.userService.getSettingsAsync(new GetUserSettingsRequestModel({keycloakUserId:this.currentUser.keycloakUserId}));
		this.userSettings$ = settingsResult.payload;
		this.userLocation = this.userSettings$.locationName;
		this.userLocationId = this.userSettings$.courtLocationId;
		this.isUserLocationLoaded = true;

		this.initSelectedTab();

		this.initTimer();

		this.initNotifications();

		this.initPopupPrompt();

		var courtCode = this.userSettings$.locationCode;
		var joinPin = this.userSettings$.joinPin;
		//console.log("courtCode:" + courtCode);
		//console.log("joinPin:" + joinPin );
		this.clerkSecurityRoles = await this.getSecurityRoles(joinPin);
		if (this.clerkSecurityRoles != "None") 
			this.hasNoSecurityRoles = false; //flag so we can display call out
		else
			this.hasNoSecurityRoles = true;

		if (courtCode !=null && !this.clerkSecurityRoles.includes(courtCode))
			this.hasNoSecurityRoleForLocation=true;

		//console.log("hasNoRoles:" +this.hasNoSecurityRoles);
		//console.log("hasNoSecurityRoleForLocation:"+ this.hasNoSecurityRoleForLocation)
	}

	///<summary>
	///Quick check to see if a keycloak user registration exists but the user doesn't exist in ADS
	///this only happens to our team because we have 3 environments DEV,TEST,UAT using one keycloak UAT user
	///<summary>
	private async checkForBadUserRoleStateWhereKeycloakExistsButNotUser(){
		//check to see that user exists in ads and not just in keycloak
		var userQuickCheck = await this.userService.getUserByKeycloakUserIdAsync(new GetUserByKeycloakUserIdQueryRequestModel({keycloakUserId: this.currentUser.keycloakUserId}));
		if (userQuickCheck.isSuccess && userQuickCheck.payload.keyCloakUserId ==null) {
			this.snackBarBadUserRoleStateMessage();
			this.router.navigate(['/users/my-account']);
		}
	}

	ngAfterViewInit() {

		const searchBox = this.searchFieldElRef.nativeElement;
		const keyup$ = fromEvent(searchBox, 'keyup');
		keyup$
			.pipe(
				map((i: any) => i.currentTarget.value),
				debounceTime(500)
			)
			// This is crazy, but this call implementation uses the scope of the subscriber, rather than the component!
			// So "this" gets messed up.
			// .subscribe(this.searchForResults);
			//This version works fine and "this" references this component
			.subscribe((searchTerm) => this.searchForResults(searchTerm));
	}

	ngOnDestroy(): void {
		this.timerSubscription$?.unsubscribe();
		
	}
	

	changeTab(event: any) {
		this.activeTabIndex = event;
		this.reWriteUrl();
	}

	initSelectedTab() {
		var fragment = this.route.snapshot.fragment;
		var tab = new URLSearchParams(fragment).get('view');

		switch (tab) {
			case "reviewpending":
				this.activeTabIndex = 0;
				break;
			case "confirmationpending":
				this.activeTabIndex = 1;
				break;
			case "completed":
				this.activeTabIndex = 2;
				break;
			default:
				this.activeTabIndex = 0;
		}

	}

	reWriteUrl() {
		var url = "reviewpending";

		switch (this.activeTabIndex) {
			case 0:
				url = "reviewpending";
				break;
			case 1:
				url = "confirmationpending";
				break;
			case 2: 
				url = "completed"
				break;
			default:
				url = "reviewpending";
		}
		let newUrl = "/adjournment-list";


		if (this.route.snapshot.queryParamMap.get('test') === "true") {
			newUrl = newUrl+"?test=true#view=" + url;
		}
		else{
			newUrl = newUrl+"#view=" + url;
		}

		this.location.replaceState(newUrl);
	}

	notificationPrompt(accept: boolean) {
		if (accept === true) {
			Notification.requestPermission();
		}
	}


	initNotifications() {
		console.log(Notification.permission);

		if (Notification.permission === "default") {
			UIkit.modal("#notification-prompt").show();
		}
	}

	initPopupPrompt() {
		var failedWindowOpenCookieExists = this.cookieService.check('failedWindowOpen');
		if (failedWindowOpenCookieExists) {
			var hasDisplayedPopupSettingDialogCookieExists = this.cookieService.check('hasDisplayedPopupSettingDialog');
			if (!hasDisplayedPopupSettingDialogCookieExists)
			{
				this.cookieService.set('hasDisplayedPopupSettingDialog', 'true');
				UIkit.modal("#popup-prompt").show();
			}
		}
	}

	//I don't like this, could maybe listen to changes on the model instead.
	clearSearchTerm(event) {
		this.searchTerm = '';
		this.searchForResults('');
	}

	searchForResults(searchValue: string): void {
		console.log(searchValue);

		this.searching = searchValue.length >= this.minSearchTermLength;

		//Call search API
		// return of(searchValue);
		if (searchValue) {
			// var requestModel = new GetAdjSearchResultsRequestModel();
			// requestModel.searchTerm = searchValue;
			// requestModel.page = 1,
			// requestModel.pageSize = 20;

			this.adjournmentsService
				.search(
					new GetAdjSearchResultsRequestModel({
						searchTerm: searchValue,
						page: 1,
						pageSize: 50,
					})
				)
				.subscribe((searchResults) => {
					this.searchResults = searchResults.payload;
					this.searchTerm = searchValue;
				});
		}
	}

	initTimer() {

		var locationFilter: number = this.userSettings$.courtLocationId; //get the location from the userService instead of the logged in user
		var request = new GetAdjournmentListRequestModel({
			page: 1,
			pageSize: 50
		});

		
		if (locationFilter != 0) {
			request.locationID = locationFilter;
		}


		this.timerSubscription$ = timer(0, 10000).subscribe(async () => {
			try {
				if (this.route.snapshot.queryParamMap.get('test') === "true") {
					request.isTest = true;
				}
				else{
					request.isTest = false;
				}

				var reviewResults = await this.adjournmentsService.getAdjournmentsForReviewAsync(request);
				this.adjournmentsForReview = reviewResults.payload;
				
				if(this.adjournmentsForReview.length >0)
					this.reviewList?.refresh(this.adjournmentsForReview);

				var completedResults = await this.adjournmentsService.getCompletedAdjournmentsAsync(request);
				this.completedAdjournments = completedResults.payload;

				if (this.adjournmentsForReview.length == 0) {
					this.titleSvc.setTitle(`ADS - No new requests`);
				}
				else {
					var suffix = this.adjournmentsForReview.length == 1 ? "request" : "requests"
					this.titleSvc.setTitle(`ADS - (${this.adjournmentsForReview.length}) new ${suffix}`);

					if (document.hasFocus() === false) {
						//only send notifications if the user is NOT on the dashboard already

						//check to see if there are any new requests that we don't know about yet
						//this will get refreshed again once the list of adjournments awaiting review gets updated again
						const newRequests = this.adjournmentsForReview.filter(request => !this.activeNotificationAdjournments.some(filter => filter.id === request.id));
						let link: string = "/"; //default to the dashboard																						
						if (newRequests.length > 0) {
							//provide specific link to the request if there is only 1
							if (newRequests.length == 1) {
								//go directly to the request
								link = `/adjournment-detail/${newRequests[0].uid}`;
							}

							let titleSuffix = newRequests.length == 1 ? "request" : "requests"
							let notificationTitle: string = `ADS - (${newRequests.length}) new ${titleSuffix}`;

							newRequests.forEach(adj => {
								//show a notification for each new adjournment
								this.showNotification(notificationTitle, adj, link);
							});
						}
					}
				}

				//keep track of the current list of adjournments
				this.activeNotificationAdjournments = this.adjournmentsForReview;								
			} catch (error) {
				console.log(error);
			}
		});
	}


	showNotification(title: string, adj: AdjournmentSummaryModel, link: string) {

		let notificationMessage: string = "";
		let varDate = moment(adj.originalDate).format("MMM DD, YYYY");
		let today = moment();
		if (today.isSame(adj.newDate, 'day')) {
			varDate = "TODAY"
		}

		notificationMessage = `Appearance Date: ${varDate} Accused: ${adj.accusedFullName}`;
		let notification = new Notification(title, {
			body: notificationMessage,
			icon: "/favicon.ico"
		});

		notification.onclick = function (event) {
			//event.preventDefault(); // prevent the browser from focusing the Notification's tab
			//window.location.href = link;
			window.open("/");//window.focus();
		}

	}



	onOpenQuickView(uid: string) {
		this.selectedAdjournmentUid = uid;
		this.matDrawer.open();
	}

	onCloseQuickView() {
		this.matDrawer.close()
	}

	

	onClosePopupMessage() {
		UIkit.modal("#popup-prompt").hide();
	}

	snackBarBadUserRoleStateMessage(){
		this.snackBar.openFromComponent(NotificationComponent, {
			duration: 5000,
			data: {
				message: 'Environment/role mismatch: User role ads-clerk exists but no user exists in ads. User has now been added but approval in console will be required.',
			},
			horizontalPosition: 'center',
			verticalPosition: 'top',
		});

		
	}

	///<summary>
	///Get security roles from JaaS / Join for a particular join User id
	///</summary>
	private async getSecurityRoles(joinUserId: string):Promise<string>
	{
		return new Promise(async(resolve, reject) => {
			await this.joinService.getUserSecurityAsync(new JoinGetUserSecurityRequestModel({joinUserId: joinUserId}))
			.then(response=>{
				if (response.isSuccess){
					var locations = response.payload.locations.join(", ");
					if (locations != "") resolve(locations); //only return locations if it actually has a populated array
				}

				resolve("None");
			})
			.catch(error=>{
				console.log(error);
				resolve("None");
			});
		});
	}
}


export function isNonNull<T>(value: T): value is NonNullable<T> {
	return value != null;
  }