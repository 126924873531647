<div class="uk-width-auto uk-child-width-1-1 uk-text-center uk-grid-small" uk-grid>
  <div class="uk-margin-large-top">
    <div class="uk-flex uk-flex-center">
      <div class="no-result-icon">
        <mat-icon>insert_drive_file</mat-icon>
      </div>
    </div>

  </div>
  <div>
    <div class="no-result-bar">&nbsp;</div>
  </div>
  <div class="uk-flex uk-flex-center">
    <div class="uk-width-medium">
      <h2>No new requests</h2>
      <p>You are up-to-date! As soon as a new request is submitted it will show up here</p>
    </div>

  </div>
</div>
