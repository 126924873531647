export class AdjournmentSearchResult {
	id: number;
	uid: string;
	accusedFullName: string;
	docketNumber: string;
	originalDate: Date;
	newDate: Date;
	statusId: number;
	status: string;
	locked: boolean;
}
