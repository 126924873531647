import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { LocationsService } from '@adj/generators/services/locations.service';
import { AdjournmentsService } from '@adj/generators/services/adjournments.service';
import { UidModel } from '@adj/generators/models/uid.model';
import { IdModel } from '@adj/generators/models/id.model';
import { AdjournmentModel } from '@adj/generators/models/adjournment.model';
import { AdjournmentHistoryDetailModel } from '@adj/generators/models/adjournment-history-detail.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { GetLocationWithScheduleModel } from '@adj/generators/models/get-location-with-schedule.model';
import { LocationWithScheduleModel } from '@adj/generators/models/location-with-schedule.model';
import { AdjournmentDetailComponent } from '@adj/pages/adjournment-detail/adjournment-detail.component';
import { AuthenticatedUserModel } from '@adj/generators/models/authenticated-user.model';
import { AdjournmentHistoryRequestModel } from '@adj/generators/models/adjournment-history-request.model';
import { DocketJoinHistoryRequestModel } from '@adj/generators/models/docket-join-history-request.model';
import { DocketJoinHistoryModel } from '@adj/generators/models/docket-join-history.model';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { CourtsService } from '@adj/generators/services/courts.service';
import { LocationCourtRoomModel } from '@adj/common/models/locations-api/shared-court-location.model';
import { MessageNotificationService } from '@adj/services/message-notification.service';
import { GetCourtRoomsRequestModel } from '@adj/generators/models/get-court-rooms-request.model';

@Component({
	selector: 'app-adjournment-details-container',
	template: `<app-adjournment-detail
		[adjournmentDetails]="adjournmentDetails"
		[adjournmentDetailHistory]="adjournmentHistory"
		[docketJoinHistory]="docketJoinHistory"
		[loggedInUser]="loggedInUser"
		[location]="location"
		[courtRooms]="courtRooms"
		[locationSchedule]="locationSchedule"
	>
	</app-adjournment-detail>`,
	styles: [],
})
export class AdjournmentDetailsContainerComponent implements OnInit, OnDestroy {
	adjournmentDetails: AdjournmentModel = new AdjournmentModel();
	adjournmentHistory: AdjournmentHistoryDetailModel[] = [];
	docketJoinHistory: 	DocketJoinHistoryModel;
	loggedInUser: AuthenticatedUserModel;
	location: LocationModel;
	courtRooms: LocationCourtRoomModel[] = []; //get courtrooms from JOIN
	locationSchedule: LocationWithScheduleModel;
	upcomingAppearanceDate: Date;
	@ViewChild(AdjournmentDetailComponent) detailsChild : AdjournmentDetailComponent;


	private routeSub: any; // subscription to route observer

	uid: string;
	settings: SettingsModel[];
	

	constructor(
		private adjournmentService: AdjournmentsService,		
		private locationService: LocationsService,
		private courtsService: CourtsService,
		private route: ActivatedRoute,
		private router: Router,		
		private messageService: MessageNotificationService,
	) {
		
	}

	ngOnInit(): void {				
		this.route.paramMap.subscribe((params) => {
			//setup page
			this.setupPage(params);
		});

		this.handleNavAway();
	}

	
	async setupPage(params) {
		console.log('adjournment details container setup');
		this.uid = params.get('uid');
		let details = await this.adjournmentService.getAdjournmentDetailAndLockItAsync(
			new UidModel({ uid: this.uid })
		);

		if (details.isSuccess) {
			this.adjournmentDetails = details.output;

			let dockets: string[] = [];
			//get appearance types based on each docket type
			for (let docket of this.adjournmentDetails.docketDecisions)
			{
				var docketNumber = docket.docketNumber;
				dockets.push(docketNumber);
			}	

			let adjHistory = await this.adjournmentService.getAdjournmentDetailHistoryAsync(
				new AdjournmentHistoryRequestModel({
					docketNumber: this.adjournmentDetails.docketDecisions[0].docketNumber,
					accusedKey: this.adjournmentDetails.accusedKey
				})
			);
			this.adjournmentHistory = adjHistory.payload;

			let joinHistory = await this.adjournmentService.getDocketJoinHistoryAsync(
				new DocketJoinHistoryRequestModel({
					docketNumber: this.adjournmentDetails.docketDecisions[0].docketNumber,
					accusedKey: this.adjournmentDetails.accusedKey
				})
			);
			this.docketJoinHistory = joinHistory.payload;

			this.mergeJoinDataToAdsCard(this.adjournmentHistory, this.docketJoinHistory);

			let location = await this.locationService.getLocationAsync(new IdModel({ id: this.adjournmentDetails.locationId }));
			this.adjournmentDetails.locationId = location.payload.id;
			this.location = location.payload;
			
			//get courtroom list from Location Services
			let requestModel = new GetCourtRoomsRequestModel();
			requestModel.locationId = this.adjournmentDetails.locationId;
			let courtLocation = await this.courtsService.getCourtRoomsAsync(requestModel);			
			this.courtRooms = courtLocation.courtRooms;

			this.upcomingAppearanceDate = this.adjournmentDetails.docketDecisions[0].requestedAppearanceDate;
			let schedule = await this.locationService.getNextAppearanceDatesAsync(
				new GetLocationWithScheduleModel({  locationId: this.adjournmentDetails.locationId, 
					courtDate: this.upcomingAppearanceDate, overrideOffset: 1, maxAdjPeriod: 52 })
			);
			this.locationSchedule = schedule.payload;

			this.detailsChild.refreshAdjournToDate();
			
		} else {
			let errorMessage = details.errors[0];
				this.messageService.showErrorMessage("An error occured getting adjournment details", errorMessage, false);
			// this._snackBar.openFromComponent(NotificationComponent, {
			// 	duration: 3000,
			// 	data: {
			// 		message: 'TODO: Show error message here',
			// 	},
			// 	horizontalPosition: 'center',
			// 	verticalPosition: 'top',
			// 	//panelClass: 'notification-warning',
			// });

			this.router.navigate(['adjournment-list']);
		}
	}

	// currently used to show on ADS card the crown elections from JOIN data
	mergeJoinDataToAdsCard(adjournmentHistory: AdjournmentHistoryDetailModel[], docketJoinHistory: DocketJoinHistoryModel)
	{
		for (let history of adjournmentHistory)
		{
			// if granted, stitch JOIN appearance to ADS appearance based on decision date
			if (history.decisionID === 12002 && history.decisionDate)
			{
				let decisionDate = history.decisionDate;
				let decisionDatePartOnly = new Date(decisionDate.getFullYear(), decisionDate.getMonth(), decisionDate.getDate());
				let cards = docketJoinHistory?.eventCards.filter(e => e.eventDate?.toString() === decisionDatePartOnly.toString());
				if (cards && cards.length > 0)
					history.eventCard = cards[0];
			}
			// else if (history.decisionID !== 12002 && history.originalAppearanceDate)
			// {
			// 	let cards = docketJoinHistory?.eventCards.filter(e => e.eventDate?.toString() === history.originalAppearanceDate?.toString());
			// 	if (cards && cards.length > 0)
			// 		history.eventCard = cards[0];
			// }
		}	
	}

	handleNavAway() {
		//Register to Angular navigation events to detect navigating away (so we can save changed settings for example)
		this.routeSub = this.router.events.subscribe(async (event) => {
			if (event instanceof NavigationStart) {
				await this.adjournmentService.unlockAdjournmentAsync(
					new UidModel({ uid: this.adjournmentDetails.uid })
				);
			}
		});
	}

	//This should handle the case where a new URL is typed into the address bar
	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event) {
		(async () => {
			await this.adjournmentService.unlockAdjournmentAsync(
				new UidModel({ uid: this.adjournmentDetails.uid })
			);
		})();

		return true;
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}
}
