import { Component, OnInit, Inject } from '@angular/core';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
	selector: 'app-decision-notification',
	templateUrl: './decision-notification.component.html',
	styleUrls: ['./decision-notification.component.scss'],
})
export class DecisionNotificationComponent implements OnInit {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data,
		private _snackRef: MatSnackBarRef<DecisionNotificationComponent>
	) {}

	ngOnInit(): void {}

	dismissMessage() {
		this._snackRef.dismiss();
	}
}
