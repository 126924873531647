<div
	*ngIf="hasMatch"
	matTooltip="{{ docketNumbers | joinList }}"
	matTooltipPosition="above"
>
	<div class="container">
		<div style="margin-right: 5px">
			<div *ngIf="docketNumbers.length > 1" class="badge">
				{{ docketNumbers.length }}
			</div>
		</div>
		<div>
			<span
				[innerHTML]="matchingDocket | matchHighlight: searchTerm"
			></span>
		</div>
	</div>
</div>

<app-docket-numbers
	*ngIf="!hasMatch"
	[docketNumbers]="docketNumbers"
></app-docket-numbers>

