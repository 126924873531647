<table mat-table [dataSource]="completedDataSource" class="w-100">
	<!-- Name Column -->
	<ng-container matColumnDef="confirmedDate">
		<th mat-header-cell *matHeaderCellDef>
			<b>Date Confirmed</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.confirmedDate | adjDateFormat }}
		</td>
	</ng-container>

	<ng-container matColumnDef="confirmedBy">
		<th mat-header-cell *matHeaderCellDef>
			<b>Confirmed By</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.confirmedBy }}
		</td>
	</ng-container>

	<ng-container matColumnDef="docketNumber">
		<th mat-header-cell *matHeaderCellDef>
			<b>Docket Number(s)</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-docket-numbers [docketNumbers]="element.docketNumbers"></app-docket-numbers>
		</td>
	</ng-container>

	<ng-container matColumnDef="accusedFullName">
		<th mat-header-cell *matHeaderCellDef>
			<b>Accused name</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.accusedFullName }}
		</td>
	</ng-container>

	<ng-container matColumnDef="jpDecisionDate">
		<th mat-header-cell *matHeaderCellDef>
			<b>Decision date</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.jpDecisionDate | adjDatetimeFormat }}
		</td>
	</ng-container>

	<ng-container matColumnDef="decidingJp">
		<th mat-header-cell *matHeaderCellDef>
			<b>Decision by</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.decidingJp != null ? element.decidingJp : "-" }}
		</td>
	</ng-container>

	<ng-container matColumnDef="jpDecision">
		<th mat-header-cell *matHeaderCellDef>
			<b>Decision</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-status-badge
				[adjournmentStatusId]="element.statusId"
				[adjournmentDecisionId]="element.decisionId"
			></app-status-badge>
		</td>
	</ng-container>
	<ng-container matColumnDef="location">
		<th mat-header-cell *matHeaderCellDef>
			<b>Court location</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.location }}
		</td>
	</ng-container>

	<ng-container matColumnDef="requestId">
		<th mat-header-cell *matHeaderCellDef>
			<b>Request ID</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.uid | shortAdjUid }}
		</td>
	</ng-container>
	
	<ng-container matColumnDef="details">
		<th mat-header-cell *matHeaderCellDef></th>
		<td
			mat-cell
			*matCellDef="let element"
			[attr.data-cy]="'detail-' + element.uid"
		>
			<app-table-details-button
				[currentUser]="currentUser"
				[hasNoSecurityRoleForLocation]="hasNoSecurityRoleForLocation"
				[hasNoSecurityRoles]="hasNoSecurityRoles"
				[details]="element"
			></app-table-details-button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onOpenQuickView(row)" class="clickable"></tr>
</table>
