

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { CommandResult } from '@adj/common/models/core-api/command-result';
import { AgentAccessRequestModel } from '@adj/generators/models/agent-access-request.model';
import { AgentUpdateAccessRequestModel } from '@adj/generators/models/agent-update-access-request.model';
import { DeactivateAgentAccountModel } from '@adj/generators/models/deactivate-agent-account.model';
import { ResetAgentAccountModel } from '@adj/generators/models/reset-agent-account.model';
import { QueryResult } from '@adj/common/models/core-api/query-result';
import { AgentOrganizationModel } from '@adj/generators/models/agent-organization.model';
import { GetAgentOrganizationbyFirmUniqueCodeModel } from '@adj/generators/models/get-agent-organizationby-firm-unique-code.model';
import { AgentOrganizationRequestModel } from '@adj/generators/models/agent-organization-request.model';
import { AgentContactCardModel } from '@adj/generators/models/agent-contact-card.model';
import { AgentContactCardRequestModel } from '@adj/generators/models/agent-contact-card-request.model';
import { AgentModel } from '@adj/generators/models/agent.model';
import { AgentByKeycloakUserIdRequestModel } from '@adj/generators/models/agent-by-keycloak-user-id-request.model';
import { ListAgentResponseModel } from '@adj/generators/models/list-agent-response.model';
import { AgentListRequestModel } from '@adj/generators/models/agent-list-request.model';
import { AgentAdminRequestModel } from '@adj/generators/models/agent-admin-request.model';
import { AgentByOrganizationRequestModel } from '@adj/generators/models/agent-by-organization-request.model';
import { AgentAccessConfirmationModel } from '@adj/generators/models/agent-access-confirmation.model';
import { AgentDelegateRequestModel } from '@adj/generators/models/agent-delegate-request.model';
import { AgentLastLoginUpdateModel } from '@adj/generators/models/agent-last-login-update.model';

@Injectable({
    providedIn: 'root'
})
export class AgentsService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('agents'));
    }

    
    public addAgentRequestAccess(model: AgentAccessRequestModel) : Observable<CommandResult<boolean>> {
        return this.post('addAgentRequestAccess', model);
    }

    public async addAgentRequestAccessAsync(model: AgentAccessRequestModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentAccessRequestModel>('addAgentRequestAccess', model).toPromise();
    }

    
    public updateAgentRequestAccess(model: AgentUpdateAccessRequestModel) : Observable<CommandResult<boolean>> {
        return this.post('updateAgentRequestAccess', model);
    }

    public async updateAgentRequestAccessAsync(model: AgentUpdateAccessRequestModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentUpdateAccessRequestModel>('updateAgentRequestAccess', model).toPromise();
    }

    
    public deactivateAccount(model: DeactivateAgentAccountModel) : Observable<CommandResult<boolean>> {
        return this.post('deactivateAccount', model);
    }

    public async deactivateAccountAsync(model: DeactivateAgentAccountModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, DeactivateAgentAccountModel>('deactivateAccount', model).toPromise();
    }

    
    public resetAccount(model: ResetAgentAccountModel) : Observable<CommandResult<boolean>> {
        return this.post('resetAccount', model);
    }

    public async resetAccountAsync(model: ResetAgentAccountModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, ResetAgentAccountModel>('resetAccount', model).toPromise();
    }

    
    public getOrganizationByLawSocietyFirmUniqueCode(model: GetAgentOrganizationbyFirmUniqueCodeModel) : Observable<QueryResult<AgentOrganizationModel>> {
        return this.get('getOrganizationByLawSocietyFirmUniqueCode', model);
    }

    public async getOrganizationByLawSocietyFirmUniqueCodeAsync(model: GetAgentOrganizationbyFirmUniqueCodeModel) : Promise<QueryResult<AgentOrganizationModel>> {
        return await this.get<QueryResult<AgentOrganizationModel>, GetAgentOrganizationbyFirmUniqueCodeModel>('getOrganizationByLawSocietyFirmUniqueCode', model).toPromise();
    }

    
    public getAgentOrganizationsByType(model: AgentOrganizationRequestModel) : Observable<QueryResult<AgentOrganizationModel[]>> {
        return this.get('getAgentOrganizationsByType', model);
    }

    public async getAgentOrganizationsByTypeAsync(model: AgentOrganizationRequestModel) : Promise<QueryResult<AgentOrganizationModel[]>> {
        return await this.get<QueryResult<AgentOrganizationModel[]>, AgentOrganizationRequestModel>('getAgentOrganizationsByType', model).toPromise();
    }

    
    public addOrganization(model: AgentOrganizationModel) : Observable<CommandResult<AgentOrganizationModel>> {
        return this.post('addOrganization', model);
    }

    public async addOrganizationAsync(model: AgentOrganizationModel) : Promise<CommandResult<AgentOrganizationModel>> {
        return await this.post<CommandResult<AgentOrganizationModel>, AgentOrganizationModel>('addOrganization', model).toPromise();
    }

    
    public getOrganization(model: AgentOrganizationRequestModel) : Observable<CommandResult<AgentOrganizationModel>> {
        return this.get('getOrganization', model);
    }

    public async getOrganizationAsync(model: AgentOrganizationRequestModel) : Promise<CommandResult<AgentOrganizationModel>> {
        return await this.get<CommandResult<AgentOrganizationModel>, AgentOrganizationRequestModel>('getOrganization', model).toPromise();
    }

    
    public editOrganizationInfo(model: AgentOrganizationModel) : Observable<CommandResult<boolean>> {
        return this.post('editOrganizationInfo', model);
    }

    public async editOrganizationInfoAsync(model: AgentOrganizationModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentOrganizationModel>('editOrganizationInfo', model).toPromise();
    }

    
    public getContactCard(model: AgentContactCardRequestModel) : Observable<QueryResult<AgentContactCardModel>> {
        return this.get('getContactCard', model);
    }

    public async getContactCardAsync(model: AgentContactCardRequestModel) : Promise<QueryResult<AgentContactCardModel>> {
        return await this.get<QueryResult<AgentContactCardModel>, AgentContactCardRequestModel>('getContactCard', model).toPromise();
    }

    
    public getAgentByKeycloakUserId(model: AgentByKeycloakUserIdRequestModel) : Observable<QueryResult<AgentModel>> {
        return this.get('getAgentByKeycloakUserId', model);
    }

    public async getAgentByKeycloakUserIdAsync(model: AgentByKeycloakUserIdRequestModel) : Promise<QueryResult<AgentModel>> {
        return await this.get<QueryResult<AgentModel>, AgentByKeycloakUserIdRequestModel>('getAgentByKeycloakUserId', model).toPromise();
    }

    
    public getAgentList() : Observable<QueryResult<ListAgentResponseModel[]>> {
        return this.get('getAgentList');
    }

    public async getAgentListAsync() : Promise<QueryResult<ListAgentResponseModel[]>> {
        return await this.get<QueryResult<ListAgentResponseModel[]>, null>('getAgentList').toPromise();
    }

    
    public getAgentListByType(model: AgentListRequestModel) : Observable<QueryResult<ListAgentResponseModel[]>> {
        return this.get('getAgentListByType', model);
    }

    public async getAgentListByTypeAsync(model: AgentListRequestModel) : Promise<QueryResult<ListAgentResponseModel[]>> {
        return await this.get<QueryResult<ListAgentResponseModel[]>, AgentListRequestModel>('getAgentListByType', model).toPromise();
    }

    
    public getAgentListByTypeAndWildcardSearch(model: AgentListRequestModel) : Observable<ListAgentResponseModel[]> {
        return this.get('getAgentListByTypeAndWildcardSearch', model);
    }

    public async getAgentListByTypeAndWildcardSearchAsync(model: AgentListRequestModel) : Promise<ListAgentResponseModel[]> {
        return await this.get<ListAgentResponseModel[], AgentListRequestModel>('getAgentListByTypeAndWildcardSearch', model).toPromise();
    }

    
    public setAgentAdmin(model: AgentAdminRequestModel) : Observable<CommandResult<boolean>> {
        return this.post('setAgentAdmin', model);
    }

    public async setAgentAdminAsync(model: AgentAdminRequestModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentAdminRequestModel>('setAgentAdmin', model).toPromise();
    }

    
    public setAgentNonAdmin(model: AgentAdminRequestModel) : Observable<CommandResult<boolean>> {
        return this.post('setAgentNonAdmin', model);
    }

    public async setAgentNonAdminAsync(model: AgentAdminRequestModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentAdminRequestModel>('setAgentNonAdmin', model).toPromise();
    }

    
    public getLawyerListByOrganization(model: AgentByOrganizationRequestModel) : Observable<QueryResult<ListAgentResponseModel[]>> {
        return this.get('getLawyerListByOrganization', model);
    }

    public async getLawyerListByOrganizationAsync(model: AgentByOrganizationRequestModel) : Promise<QueryResult<ListAgentResponseModel[]>> {
        return await this.get<QueryResult<ListAgentResponseModel[]>, AgentByOrganizationRequestModel>('getLawyerListByOrganization', model).toPromise();
    }

    
    public confirm(model: AgentDelegateRequestModel) : Observable<CommandResult<AgentAccessConfirmationModel>> {
        return this.post('confirm', model);
    }

    public async confirmAsync(model: AgentDelegateRequestModel) : Promise<CommandResult<AgentAccessConfirmationModel>> {
        return await this.post<CommandResult<AgentAccessConfirmationModel>, AgentDelegateRequestModel>('confirm', model).toPromise();
    }

    
    public editPersonalInfo(model: AgentModel) : Observable<CommandResult<boolean>> {
        return this.post('editPersonalInfo', model);
    }

    public async editPersonalInfoAsync(model: AgentModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentModel>('editPersonalInfo', model).toPromise();
    }

    
    public updateAgentLastLoginDate(model: AgentLastLoginUpdateModel) : Observable<CommandResult<boolean>> {
        return this.post('updateAgentLastLoginDate', model);
    }

    public async updateAgentLastLoginDateAsync(model: AgentLastLoginUpdateModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, AgentLastLoginUpdateModel>('updateAgentLastLoginDate', model).toPromise();
    }

    
}
