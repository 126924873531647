


export class CodeGroupRequestModel {
    public codeGroupId: number = 0;

    constructor(init? : Partial<CodeGroupRequestModel>) {
        
        Object.assign(this, init);
    }
}
