import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpRequestListenerService } from './http-request-listener.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	
	//allow the following requests to run in the background without indiciating the system is busy
	urlFragsToIgnore: string[] = [
		'getAdjournmentsForReview',
		'getAdjournmentsAwaitingConfirmation',
		'getCompletedAdjournments',
		'getAllSettings',
		'getSettingByKey',
		'config.json',
		'getVersion',
		'getEnvironment'
	];

	private count = 0;

	constructor(private httpRequestListener: HttpRequestListenerService) {}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (this.isValidRequestForInterceptor(req.url)) {
			if (this.count === 0) {
				this.httpRequestListener.setHttpProgressStatus(true);
			}
			this.count++;
			return next.handle(req).pipe(
				finalize(() => {
					this.count--;
					if (this.count === 0) {
						this.httpRequestListener.setHttpProgressStatus(false);
					}
				})
			);
		}

		return next.handle(req);
	}

	private isValidRequestForInterceptor(requestUrl: string): boolean {

		let ignore = this.urlFragsToIgnore.find(x=> requestUrl.includes(x));
		return !(ignore?.length > 0);
	}
}
