import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'joinList',
})
export class JoinListPipe implements PipeTransform {
  transform(value: string[], ...args: unknown[]): string {
		if (!value) return null;

		return value.join(',');
	}
}
