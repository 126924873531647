import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-view-more',
	templateUrl: './view-more.component.html',
	styleUrls: ['./view-more.component.scss'],
})
export class ViewMoreComponent implements OnInit {
	
	@Input() items: string[];
	@Input() sliceIndex: number = 3;
	@Input() isDate: boolean = false;

	isOpen: boolean = false;

	constructor() {}

	ngOnInit(): void {}

	onOpen(){
		this.isOpen = true;
	}

	onClose(){
		this.isOpen = false;
	}
}
