<div *ngIf="(userLocationId > 0 && filteredLocations.length > 0 && availableLocations.length > 1)">
	<div class="showFilters">
		<mat-icon class="iconFilter">filter_alt</mat-icon>
		Filters:	
		<div *ngFor="let item of filteredLocations" class="statusPill">	  
			{{ item.courthouse }}
			<a (click)="removeLocation(item)"><mat-icon class="iconCancel">cancel</mat-icon></a>
		</div>
	</div> 
	
	<div class="clearFilters">
		<a (click)="clearAll()">Clear all filters</a>
	</div>
</div>
<div style="clear: both;">
<br />
</div>

<table mat-table [dataSource]="reviewDataSource" class="w-100">
	<!-- Name Column -->
	<ng-container matColumnDef="daysUntilAppearance">
		<th mat-header-cell *matHeaderCellDef>
			<b>Days until appearance</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<!-- first, show duty counsel icon or empty space -->
			<mat-icon *ngIf="element.statusId != 5 && element.counselTypeId === 10000" 
				class="circle-duty-counsel" matTooltip="Duty counsel request">
				circle</mat-icon>
			<ng-container *ngIf="element.statusId != 5 && element.counselTypeId != 10000" 
				>
				&nbsp;&nbsp;&nbsp;&nbsp;</ng-container>
			<!-- second, show days until appearance or expired label -->
			<ng-container *ngIf="element.statusId != 5">
			{{ element.originalDate | daysUntil }}
			</ng-container>
			<app-status-badge *ngIf="element.statusId == 5"
				[adjournmentStatusId]="element.statusId"
				[adjournmentDecisionId]="element.decisionId"
			></app-status-badge>
		</td>
	</ng-container>

	<ng-container matColumnDef="dateRequested">
		<th mat-header-cell *matHeaderCellDef>
			<b>Request received</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.createdDate | adjDatetimeFormat }}
		</td>
	</ng-container>

	<ng-container matColumnDef="docketNumber">
		<th mat-header-cell *matHeaderCellDef>
			<b>Docket number(s)</b>
		</th>
		<td mat-cell *matCellDef="let element">
			<app-docket-numbers [docketNumbers]="element.docketNumbers"></app-docket-numbers>
		</td>
	</ng-container>

	<ng-container matColumnDef="accusedFullName">
		<th mat-header-cell *matHeaderCellDef>
			<b>Accused name</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.accusedFullName }}
		</td>
	</ng-container>

	<ng-container matColumnDef="requestId">
		<th mat-header-cell *matHeaderCellDef>
			<b>Request ID</b>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.uid | shortAdjUid }}
		</td>
	</ng-container>

	<ng-container matColumnDef="location">
		<th mat-header-cell *matHeaderCellDef>
			<div class="uk-inline">
				<b>Court location</b>
				<ng-container *ngIf="availableLocations.length > 1">
					<a><mat-icon [ngClass]="(filteredLocations.length === 0)?'material-icons-outlined':'material-icons'">filter_alt</mat-icon></a>
				
					<div uk-dropdown="mode: click; pos: bottom-center;" class="checkboxPopup" style="left: 0px;">
						<div>
							<div uk-grid>
								<div class="uk-width-expand">
									<div style="padding-bottom: 5px;">
									<strong>Locations</strong>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<a (click)="clearAll()">Clear all</a>
									</div>
									<table class="tableCheckboxes">
										<tbody *ngFor="let item of availableLocations">
											<tr>
											<td class="checkboxColumn">
												<mat-checkbox id="{{ item.courtId }}" #locationCheckbox (click)="selectLocation(locationCheckbox.checked, $event, item)"
												></mat-checkbox>
											</td>
											<td>
												{{ item.courthouse }}
											</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</ng-container>	
			</div>
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.location }}
		</td>
	</ng-container>

	<ng-container matColumnDef="details">
		<th mat-header-cell *matHeaderCellDef></th>
		<td
			mat-cell
			*matCellDef="let element"
			[attr.data-cy]="'detail-' + element.uid"
		>
			<app-table-details-button
				[currentUser]="currentUser"
				[hasNoSecurityRoleForLocation]="hasNoSecurityRoleForLocation"
				[hasNoSecurityRoles]="hasNoSecurityRoles"
				[details]="element"
			></app-table-details-button>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onOpenQuickView(row)" class="clickable"></tr>
</table>
