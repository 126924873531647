import { Component, OnInit } from '@angular/core';
import { MaintenanceModel } from '@adj/shared/models/maintenance.model';
import { SettingsService } from '@adj/generators/services/settings.service';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';
import { Constants } from '@adj/common/models/helpers/ui-constants';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	title = 'This is Home';
	maintenance: MaintenanceModel = new MaintenanceModel();
	isMaintenanceMode = false;

	constructor(private settingsService: SettingsService) { }

	async ngOnInit() {
		const settingsResults = await this.settingsService.getSettingByKeyAsync(new GetSettingByKeyRequestModel({ key: Constants.Settings.MaintenanceMessageClerkApp })); 

		this.maintenance.description = settingsResults.isSuccess ? settingsResults.payload.value.trim() : '';
		this.isMaintenanceMode = this.maintenance.description.length != 0;
	}

}
