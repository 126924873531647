import { EndorsementSheetModel } from '@adj/generators/models/endorsement-sheet.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LocationCourtRoomModel } from '@adj/common/models/locations-api/shared-court-location.model';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { ChargeModel } from '@adj/generators/models/charge.model';

@Component({
	selector: 'app-endorsement-sheet',
	templateUrl: './endorsement-sheet.component.html',
	styleUrls: ['./endorsement-sheet.component.scss'],
})
export class EndorsementSheetComponent implements OnInit {
	@Input() adjDetails: EndorsementSheetModel;
	@Input() location: LocationModel;
	@Input() courtRooms: LocationCourtRoomModel[];
	@Input() set finishedLoading(value: boolean) {
		// this.generatePdf();
	}

	@ViewChild('pdfComponent') pdfComponent: ElementRef;

	today = new Date();
	courtRoomTime: string;

	constructor() {}

	ngOnInit(): void {

	}

	docketOriginalCourtRoom(docketNumber: string) {
		var matterCourtRoom = this.adjDetails.docketDecisions.find(
			(mcr) => mcr.docketNumber == docketNumber
		);

		if (matterCourtRoom) {
			return matterCourtRoom.originalCourtRoomId;
		}

		return '';
	}

	getCourtroomTime():string  {

		var courtRoomId = this.adjDetails?.docketDecisions[0]?.requestedCourtRoomId.trim();
		var adjournDate = this.adjDetails?.decisionAdjournToDate;
		var dayOfWeek = this.getDayOfWeek(adjournDate);
		var amStartTime = "AmStartTime";
		var pmStartTime = "PmStartTime";
		var keyAmStartTime = dayOfWeek+amStartTime;
		var keyPmStartTime = dayOfWeek+pmStartTime;


		for(var i = 0; i < this.courtRooms.length; i++){
			if (courtRoomId === this.courtRooms[i].courtRoomId.trim())
			{
				let courtRoom = this.courtRooms[i];

				let openTime = "";
				let closeTime = "";
				openTime = courtRoom[keyAmStartTime].trim();
				closeTime = courtRoom[keyPmStartTime].trim();

				if (openTime === "")
					return "";

				//format the time
				//	0930 and 1330
				// to
				// 	8:15 AM - 2:00 PM
				let openTimeWithColon = openTime.substr(0,2) + ":" + openTime.substr(2,2);
				let closeTimeWithColon = closeTime.substr(0,2) + ":" + closeTime.substr(2,2);

				let openTimeWithAm = this.formatAmPm(openTimeWithColon);
				let closeTimeWithPm = this.formatAmPm(closeTimeWithColon);

				if (courtRoomId === "CMO")
					return " - " + openTimeWithAm + " - " + closeTimeWithPm;
				else
					return " - " + openTimeWithAm;
			}
		}

		return "";
	}

	getDayOfWeek(currentDate: Date): string {
		let day = currentDate?.getDay();
		let dayString  = "";
		if (day == 1)
			dayString = "monday";
		else if (day == 2)
			dayString = "tuesday";
		else if (day == 3)
			dayString = "wednesday";
		else if (day == 4)
			dayString = "thursday";
		else if (day == 5)
			dayString = "friday";
		return dayString;
	}

	//var timeString = "18:00";
	formatAmPm(timeString: string): string{

		var H = +timeString.substr(0, 2);
		var h = H % 12 || 12;
		var ampm = (H < 12 || H === 24) ? " AM" : " PM";
		var formatTimeString = h + timeString.substr(2, 3) + ampm;
		return formatTimeString;
	}

	getChargesAppearanceTypes(charges: ChargeModel[]){
		let appearanceTypes = "";

		let list = charges.map(c => c.appearanceType);
		list.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));

		var unique = list.filter(this.onlyUnique);

		let i = 0;
		for(let appearanceType of unique)
		{
			if (i !== 0)
				appearanceTypes += ', ';
			appearanceTypes += appearanceType;
			i++;
		}
		return appearanceTypes;
	}
	//use with filter function to return unique values
	onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}


}
