import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MessageNotificationService } from '@adj/services/message-notification.service';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data,
		private _snackRef: MatSnackBarRef<NotificationComponent>
	) {}

	ngOnInit(): void {}

	dismissMessage() {
		this._snackRef.dismiss();
	}
}
