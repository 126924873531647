export class AdjournmentSummary {
	id: number;
	uid: string;
	accusedFullName: string;
	docketNumber: string;
	originalDate: Date;
	newDate: Date;
	confirmedDate: Date;
	jpDecisionDate: Date;
	decidingJp: string;
	confirmedBy: string;
	statusId: number;
	locked: boolean;
	location: string;
}
