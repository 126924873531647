import { Constants } from '@adj/common/models/helpers/ui-constants';
import { AddUpdateUserSettingsModel } from '@adj/generators/models/add-update-user-settings.model';
import { AddUserCommandRequestModel } from '@adj/generators/models/add-user-command-request.model';
import { GetSettingByKeyRequestModel } from '@adj/generators/models/get-setting-by-key-request.model';
import { GetUserByKeycloakUserIdQueryRequestModel } from '@adj/generators/models/get-user-by-keycloak-user-id-query-request.model';
import { GetUserSettingsRequestModel } from '@adj/generators/models/get-user-settings-request.model';
import { JoinGetUserSecurityRequestModel } from '@adj/generators/models/join-get-user-security-request.model';
import { LocationModel } from '@adj/generators/models/location.model';
import { LogRequestModel } from '@adj/generators/models/log-request.model';
import { SettingsModel } from '@adj/generators/models/settings.model';
import { UserModel } from '@adj/generators/models/user.model';
import { Join2Service } from '@adj/generators/services/join2.service';
import { LocationsService } from '@adj/generators/services/locations.service';
import { LogsService } from '@adj/generators/services/logs.service';
import { SettingsService } from '@adj/generators/services/settings.service';
import { UserService } from '@adj/generators/services/user.service';
import { MessageNotificationService } from '@adj/services/message-notification.service';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { ElementSchemaRegistry } from '@angular/compiler';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as UIkit from 'uikit';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit,AfterViewInit{

	public currentUser:AuthenticatedUserProfileKeycloakModel;
	public clerk:UserModel = new UserModel();
	clerkLocation: string = "All locations";
	invalidEmail: boolean = false;
	isPending:Boolean = false;
	clerkSecurityRoles: string = "None";
	hasNoSecurityRoles:Boolean = false;
	hasNoSecurityRoleForLocation:Boolean=false;
	//build default form ... modify after determining user role
	userSettingsForm: FormGroup = this.formBuilder.group({
		courtLocationId: [0], // allow no location selected to allow monitoring of multiple locations
		joinPin: [""]});

	errorObj = {
		user: "",
		page: window.location.href,
		params: {},
		action: "",
		error: ""
	};
	_USER_FILTERED_LOCATIONS = Constants.Cookies.UserFilteredLocations;
	locations: LocationModel[];
	public location$: LocationModel[];
	settings: SettingsModel[];

	//Regular Expression for CassID field
	joinPinRegEx = /^\*[a-zA-Z]{0,8}$/;

	constructor(
		private authenticationService:AuthenticationService,
		private userService:UserService,
		private loggingSvc: LogsService,
		private notificationSvc: MessageNotificationService,
		private cookieService: CookieService,
		private locationService: LocationsService,
		private settingsService: SettingsService,
		private joinService: Join2Service,
		private formBuilder: FormBuilder
		) {
			
			
		this.init();
	}

	async init(){
		//get authenticated keycloak user
		this.currentUser = await this.authenticationService.getCurrentUser();
		this.refresh();

		
	}

	async ngAfterViewInit() {
		
	}

	async ngOnInit() {
		
	}

	
	showEditDialog(){
		UIkit.modal("#userSettingsModal").show();
	}
	
	
	async refresh(){

		// await this.checkInvalidKeycloakEmail();

		// if(this.invalidEmail)
		//  return;

		//if authenticated user doesn't exist in ads then create them
		this.clerk = await this.addOrGetExistingUser();

		//now that we have the clerk user do rule check to see if their access is pending
		this.getIsPendingAccess();


		//get All locations
		var locResults = await this.locationService.getAllLocationsAsync();
		this.locations = locResults.payload;
		
		let locationSettings:SettingsModel;
		var settingsResults = await this.settingsService.getSettingByKeyAsync(new GetSettingByKeyRequestModel({key:Constants.Settings.ClerkAvailableLocations})).then(data=>{
			
			if (data.isSuccess)
				locationSettings = data.payload;
			else
				throw new Error('An error occurred while getting location settings.');
		});

		if (locationSettings) {
			let values = locationSettings.value.split('|');
			if (values.length > 0) {
				//filter based on values in the settings
				this.location$ = this.locations.filter(setting => values.includes(setting.courtCode.trim()));
			}
		}

		let locationId = this.clerk?.courtLocationId ?? 0;
		if(locationId !== 0){
			this.clerkLocation = this.location$?.find(x=> x.id == locationId)?.courtHouse;
			console.log("location" + this.location$?.find(x=> x.id == locationId).courtCode);
		}
		else{
			this.clerkLocation = "All locations";
		}

		this.userSettingsForm = this.formBuilder.group({
			courtLocationId: [locationId], // allow no location selected to allow monitoring of multiple locations
			joinPin: [this.clerk.joinpin, [Validators.required, Validators.pattern(this.joinPinRegEx)]]
		});					
		
		console.log("joinpin: " +this.clerk.joinpin);
		if (this.clerk?.joinpin !=null && this.clerk?.joinpin !=""){
			this.clerkSecurityRoles = await this.getSecurityRoles(this.clerk.joinpin);
			if (this.clerkSecurityRoles == "None") 
				this.hasNoSecurityRoles = true; //flag so we can display call out
			else 
				this.hasNoSecurityRoles = false;

			await this.userService.getSettingsAsync(new GetUserSettingsRequestModel({keycloakUserId:this.currentUser.keycloakUserId}))
			.then(result=>{
				var userSettings = result.payload;	
				var courtCode = userSettings.locationCode;
				//console.log(courtCode);
				//console.log(this.clerkSecurityRoles);
				
				if (courtCode !=null && !this.clerkSecurityRoles.includes(courtCode))
					this.hasNoSecurityRoleForLocation=true;
				else
					this.hasNoSecurityRoleForLocation=false;	
			}).catch(error=>{
				this.hasNoSecurityRoleForLocation=true;
			});
		}
		else{//joinPin is only null when a new user workflow happens;we don't want to start them with seeing error states as they haven't done anything
			this.hasNoSecurityRoleForLocation=false;
			this.hasNoSecurityRoles = false;
		}
		//console.log("no security roles for location:" + this.hasNoSecurityRoleForLocation);
		//console.log("no security roles:" + this.hasNoSecurityRoles);
		//Un-comment the following section should the need to apply required valiation based on clerk-roles
		//*/
		//if (this.currentUser$.value.userRoles.includes("Admin")) {										
		// if (this.currentUser$.value.userRoles.includes("Admin")) {
		// 	this.userSettingsForm = this.formBuilder.group({
		// 		courtLocationId: [0], // allow no location selected to allow monitoring of multiple locations
		// 		joinPin: ["", [Validators.required, Validators.pattern(this.joinPinRegEx)]]
		// 	});
		// }
		// else {
		// 	this.userSettingsForm = this.formBuilder.group({
		// 		courtLocationId: [0, Validators.min(1)], //limit NON-Admin users to a single location
		// 		joinPin: ["", [Validators.required, Validators.pattern(this.joinPinRegEx)]]
		// 	});
		// }
		//}		
	}

	///<summary>
	///Get security roles from JaaS / Join for a particular join User id
	///</summary>
	private async getSecurityRoles(joinUserId: string):Promise<string>
	{
		return new Promise(async(resolve, reject) => {
			await this.joinService.getUserSecurityAsync(new JoinGetUserSecurityRequestModel({joinUserId: joinUserId}))
			.then(response=>{
				if (response.isSuccess){
					var locations = response.payload.locations.join(", ");
					if (locations != "") resolve(locations); //only return locations if it actually has a populated array
				}

				resolve("None");
			})
			.catch(error=>{
				console.log(error);
				resolve("None");
			});
		});
	}

	///<summary>
	///Once currentUser is known and available within page we want to check to see if user is a new clerk
	///</summary>
	private async lookupClerkByKeycloakIdCallBack(user:AuthenticatedUserProfileKeycloakModel){

		
		
	}

	private async checkInvalidKeycloakEmail(){		
		var user = await this.authenticationService.getCurrentUser();
		if(user.email.toLowerCase().includes("@just.gov.ab.ca"))
			this.invalidEmail = true;
		else
			this.invalidEmail = false;

	}

	private async addOrGetExistingUser():Promise<UserModel>{

		return new Promise(async(resolve, reject) => {
			//pass keycloakUserId from currentUser context to ads api will return true or false payload
			var lookUpUser = await this.userService.getUserByKeycloakUserIdAsync(new GetUserByKeycloakUserIdQueryRequestModel({keycloakUserId:this.currentUser.keycloakUserId}));
			if (lookUpUser.isSuccess){
				var user = lookUpUser.payload;
				if (this.isNewClerk(user)){	
					
					//Add the call to AddUserCommand to add a user with their keycloakID
					var newClerkResult = await this.userService.addUserAsync(new AddUserCommandRequestModel({
						firstName: this.currentUser.firstName, //use keycloak values
						lastName: this.currentUser.lastName, //use keycloak values
						email: this.currentUser.email //use keycloak values			
					})).then(newClerk=>{
						if (newClerk.isSuccess){
							resolve(newClerk.output);
						}
						else{
							reject('Adding new clerk failed.')
						}
					}).catch(e=>{
						reject(e);
					});
				}
				resolve(lookUpUser.payload);
			}
		});
		
	}
		
	
	
	///<summary>
	///Simple check to see if clerk is new, simply to make code more readable related to business rule check
	///</summary>
	public isNewClerk(user):Boolean{
		return(user.keyCloakUserId == null);
	}
	
	public getIsPendingAccess():Boolean{
		if (this.clerk.activationDate == null || this.clerk.activationDate == undefined) {
			this.isPending=true;
			return true;
		}	
		else{
			this.isPending=false;
			return false;
		}
			
	}

	async saveSettings() {

		if (this.userSettingsForm.valid) {
	
			//build the request model
			let model = new AddUpdateUserSettingsModel();
			model.userUid = this.clerk.uid;
			model.courtLocationId = this.userSettingsForm.controls.courtLocationId.value;
			model.joinPin = this.userSettingsForm.controls.joinPin.value.toUpperCase(); //make sure we're passing the JOIN Cass ID as uppercase
			
			try {
				this.errorObj.action = "addUpdateSettings";
				this.errorObj.params = model;
		
				var result = await this.userService.addUpdateSettingsAsync(model);
	
				if (result.isSuccess) {
					this.notificationSvc.showSnackBar('User settings saved successfully');
	
					//clear cookie for filter location preferences used on dashboard
					this.cookieService.delete(this._USER_FILTERED_LOCATIONS);

					this.refresh();
					UIkit.modal("#userSettingsModal").hide();
				}
				else {
					var objMessage = Object.values(result.errors);
					let errMessage = `${objMessage}`;
					this.handleLogging(errMessage);
					this.notificationSvc.showErrorMessage(result.message, errMessage, true);
				}

		
			}
			catch (err) {
				this.handleLogging(err.message); //log the error
				this.notificationSvc.showErrorMessage("An error occured", err.message, true);				
			};
		}
	
	}
	
	async onJoinPinChange(joinUserId: string): Promise<void> {  
		this.clerkSecurityRoles = await this.getSecurityRoles(joinUserId);
		console.log(this.clerkSecurityRoles);
		if (this.clerkSecurityRoles == "None") 
			this.hasNoSecurityRoles = true; //flag so we can display call out
		else 
			this.hasNoSecurityRoles = false;
	}
	
	public onLocationChange(locationId){

		if (locationId !=0){
			var courtCode = this.location$?.find(x=> x.id == locationId).courtCode;

			if (courtCode !=null && !this.clerkSecurityRoles.includes(courtCode))
				this.hasNoSecurityRoleForLocation=true;
			else
				this.hasNoSecurityRoleForLocation=false;
		}
		else
			this.hasNoSecurityRoleForLocation=false;
	}

	handleLogging(errorMessage: string) {
	
		this.errorObj.error = errorMessage;
		//TODO: Move to a centralized pattern? 
	
		var log = new LogRequestModel({
			serviceName: "Clerk Application",
			msg: JSON.stringify(this.errorObj),
			user: this.clerk.username,
			timestamp: new Date()
		});
		this.loggingSvc.logErrorAsync(log);
	}
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
	return value != null;
  }

  
  