import { HttpRequestInterceptor } from '@adj/services/http-request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakBearerInterceptor } from 'keycloak-angular';

export const interceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: KeycloakBearerInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true,
	},
];
