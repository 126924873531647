import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HttpRequestListenerService {

	private httpLoading$ = new ReplaySubject<boolean>(1);
	constructor() {}

	httpProgress(): Observable<boolean> {
		return this.httpLoading$.asObservable();
	}

	setHttpProgressStatus(inprogess: boolean) {
		this.httpLoading$.next(inprogess);
	}
}
