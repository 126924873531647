

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPointService } from '@adj/common/services/end-point.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';

import { QueryResult } from '@adj/common/models/core-api/query-result';
import { CodeModel } from '@adj/generators/models/code.model';
import { CommandResult } from '@adj/common/models/core-api/command-result';
import { CodeGroupRequestModel } from '@adj/generators/models/code-group-request.model';
import { GetAppearanceTypesCommandRequestModel } from '@adj/generators/models/get-appearance-types-command-request.model';
import { GetEventActionsCommandRequestModel } from '@adj/generators/models/get-event-actions-command-request.model';
import { GetAdjournmentWhoCommandRequestModel } from '@adj/generators/models/get-adjournment-who-command-request.model';
import { GetAdjournmentReasonsCommandRequestModel } from '@adj/generators/models/get-adjournment-reasons-command-request.model';

@Injectable({
    providedIn: 'root'
})
export class CodesService extends EndPointService {
    constructor(http: HttpClient, config: EnvConfigService){
        super(http, config.get('codes'));
    }

    
    public getAll() : Observable<QueryResult<CodeModel[]>> {
        return this.get('getAll');
    }

    public async getAllAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('getAll').toPromise();
    }

    
    public addCode(model: CodeModel) : Observable<CommandResult<CodeModel>> {
        return this.post('addCode', model);
    }

    public async addCodeAsync(model: CodeModel) : Promise<CommandResult<CodeModel>> {
        return await this.post<CommandResult<CodeModel>, CodeModel>('addCode', model).toPromise();
    }

    
    public deleteCode(model: CodeModel) : Observable<CommandResult<boolean>> {
        return this.post('deleteCode', model);
    }

    public async deleteCodeAsync(model: CodeModel) : Promise<CommandResult<boolean>> {
        return await this.post<CommandResult<boolean>, CodeModel>('deleteCode', model).toPromise();
    }

    
    public reasons() : Observable<QueryResult<CodeModel[]>> {
        return this.get('reasons');
    }

    public async reasonsAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('reasons').toPromise();
    }

    
    public by() : Observable<QueryResult<CodeModel[]>> {
        return this.get('by');
    }

    public async byAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('by').toPromise();
    }

    
    public getLanguages() : Observable<QueryResult<CodeModel[]>> {
        return this.get('getLanguages');
    }

    public async getLanguagesAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('getLanguages').toPromise();
    }

    
    public getCapacityOfCounsel() : Observable<QueryResult<CodeModel[]>> {
        return this.get('getCapacityOfCounsel');
    }

    public async getCapacityOfCounselAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('getCapacityOfCounsel').toPromise();
    }

    
    public getCodeTestScenarios() : Observable<QueryResult<CodeModel[]>> {
        return this.get('getCodeTestScenarios');
    }

    public async getCodeTestScenariosAsync() : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, null>('getCodeTestScenarios').toPromise();
    }

    
    public getCode(model: number) : Observable<QueryResult<CodeModel>> {
        return this.get('getCode', model);
    }

    public async getCodeAsync(model: number) : Promise<QueryResult<CodeModel>> {
        return await this.get<QueryResult<CodeModel>, number>('getCode', model).toPromise();
    }

    
    public getCodeGroup(model: number) : Observable<QueryResult<CodeModel[]>> {
        return this.get('getCodeGroup', model);
    }

    public async getCodeGroupAsync(model: number) : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, number>('getCodeGroup', model).toPromise();
    }

    
    public getCodeByGroup(model: CodeGroupRequestModel) : Observable<QueryResult<CodeModel[]>> {
        return this.get('getCodeByGroup', model);
    }

    public async getCodeByGroupAsync(model: CodeGroupRequestModel) : Promise<QueryResult<CodeModel[]>> {
        return await this.get<QueryResult<CodeModel[]>, CodeGroupRequestModel>('getCodeByGroup', model).toPromise();
    }

    
    public getAppearanceTypes(model: GetAppearanceTypesCommandRequestModel) : Observable<CommandResult<CodeModel[]>> {
        return this.post('getAppearanceTypes', model);
    }

    public async getAppearanceTypesAsync(model: GetAppearanceTypesCommandRequestModel) : Promise<CommandResult<CodeModel[]>> {
        return await this.post<CommandResult<CodeModel[]>, GetAppearanceTypesCommandRequestModel>('getAppearanceTypes', model).toPromise();
    }

    
    public getEventActions(model: GetEventActionsCommandRequestModel) : Observable<CommandResult<CodeModel[]>> {
        return this.post('getEventActions', model);
    }

    public async getEventActionsAsync(model: GetEventActionsCommandRequestModel) : Promise<CommandResult<CodeModel[]>> {
        return await this.post<CommandResult<CodeModel[]>, GetEventActionsCommandRequestModel>('getEventActions', model).toPromise();
    }

    
    public getAdjournmentBy(model: GetAdjournmentWhoCommandRequestModel) : Observable<CommandResult<CodeModel[]>> {
        return this.post('getAdjournmentBy', model);
    }

    public async getAdjournmentByAsync(model: GetAdjournmentWhoCommandRequestModel) : Promise<CommandResult<CodeModel[]>> {
        return await this.post<CommandResult<CodeModel[]>, GetAdjournmentWhoCommandRequestModel>('getAdjournmentBy', model).toPromise();
    }

    
    public getAdjournmentReasons(model: GetAdjournmentReasonsCommandRequestModel) : Observable<CommandResult<CodeModel[]>> {
        return this.post('getAdjournmentReasons', model);
    }

    public async getAdjournmentReasonsAsync(model: GetAdjournmentReasonsCommandRequestModel) : Promise<CommandResult<CodeModel[]>> {
        return await this.post<CommandResult<CodeModel[]>, GetAdjournmentReasonsCommandRequestModel>('getAdjournmentReasons', model).toPromise();
    }

    
}
