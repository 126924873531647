


export class GetDocumentByKeyRequestModel {
    public DocumentKey: string = '';

    constructor(init? : Partial<GetDocumentByKeyRequestModel>) {
        
        Object.assign(this, init);
    }
}
