


export class GetAdjournmentWhoCommandRequestModel {
    public docketType: string = '';

    constructor(init? : Partial<GetAdjournmentWhoCommandRequestModel>) {
        
        Object.assign(this, init);
    }
}
